import React, { PureComponent } from "react";
import { Colors } from "../../constants/Styles";

const SenderBubble = ({ text }) => (
  <div className="chat-bubble sender-bubble">
    {text}
    <div className="arrow-right"></div>
  </div>
);

const ReceiverBubble = ({ text }) => (
  <div className="chat-bubble receiver-bubble">
    {text}
    <div className="arrow-left"></div>
  </div>
);

export class Message extends PureComponent {
  formatContainer = () => {
    const { item } = this.props;
    const fromSelf = item.sender === "self";

    const alignSelf = fromSelf ? "flex-end" : "flex-start";
    const innerContainerColor = fromSelf ? Colors.white : Colors.greyishTeal;
    const message = {};
    const container = {
      ...messageStyle.messageContainer,
      alignSelf: alignSelf,
      marginLeft: fromSelf ? "20%" : 20,
      marginRight: !fromSelf ? "20%" : 20
    };
    const fromSelfArrowStyle = {
      position: "absolute",
      bottom: 5 - 15,
      right: 5 - 15,
      transform: [{ rotate: "45deg" }]
    };
    const fromOtherArrowStyle = {
      position: "absolute",
      bottom: 5 - 15
    };
    const color = fromSelf ? Colors.white : Colors.greyishTeal;
    const arrowStyle = fromSelf ? fromSelfArrowStyle : fromOtherArrowStyle;
    return { arrowStyle, color, container, message, fromSelf };
  };

  render() {
    const { item } = this.props;
    const { arrowStyle, color, container, message, fromSelf } =
      this.formatContainer();

    return (
      <div style={container}>
        {fromSelf ? (
          <SenderBubble text={item.text} />
        ) : (
          <ReceiverBubble text={item.text} />
        )}
      </div>
    );
  }
}

export const MessengerTextInput = props => {
  return <></>;
};

export const MessagesContainer = props => {
  return <></>;
};

const messageStyle = {
  messageContainer: {
    borderRadius: 10,
    marginVertical: 5,
    paddingHorizontal: 15,
    paddingVertical: 15
  },
  messagesContainer: {
    backgroundColor: Colors.pale,
    height: "100%",
    width: "100%"
  },
  messageText: {},
  messageTextInputContainer: {
    borderColor: Colors.border,
    borderWidth: 1,
    borderBottomWidth: 0,
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: "100%"
  },
  messageTextInput: {
    padding: 0,
    margin: 0,
    flex: 1
  },
  messageIconTouchable: {
    marginHorizontal: 5,
    paddingHorizontal: 5
  },
  messageIconContainer: {
    alignItems: "center",
    justifyContent: "flex-end"
  }
};
