import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// Actions
import { getUserGroup } from "../../redux/actions/User";
import { logOut } from "../../redux/actions/Account";
// Components
import { NavBarLargeSection } from "../molecules/Link";
import { Button } from "semantic-ui-react";
import { DashWrapper } from "../templates/DashWrapper";
// Constants
import Routes from "../../constants/Routes";
import i18nstring, { strings } from "../../i18n";
// Types
import {
  STOP_IMPERSONATE_USER,
  UPDATE_ROUTES
} from "../../redux/actions/Types";
import { refreshChat, refreshChats } from "../../redux/actions/ChatActions";

function Dashboard({
  attemptStopImpersonating,
  attemptLogOut,
  user,
  nav,
  impersonatedUser,
  shouldUpdateRoutes,
  updateRoutes,
  attemptRefreshChat,
  attemptRefreshChats
}) {
  let isAdmin = false;
  if (user) {
    isAdmin = user.subRoles.ADMIN ? true : false;
  }

  if (!user) return <Redirect to={Routes.HOME} />;

  if (shouldUpdateRoutes) {
    updateRoutes();
    return <Redirect to={Routes.DASHBOARD} />;
  }

  let currentNav = nav;
  if (nav === i18nstring(strings.DASHBOARD)) {
    currentNav = isAdmin
      ? i18nstring(strings.SIDE_NON_PROFIT)
      : i18nstring(strings.SIDE_COURIER);
  }

  const logout = () => {
    attemptLogOut();
  };

  return (
    <div>
      <nav className="nav_bar_container">
        <NavBarLargeSection to="/" label="Fresh Food Connect" />
      </nav>
      <ImpersonatedUserBanner
        currentUser={user}
        stopImpersonating={attemptStopImpersonating}
        impersonatedUser={impersonatedUser}
      />
      <DashWrapper
        impersonatedUser={impersonatedUser}
        isAdmin={isAdmin}
        logOut={logout}
        user={user}
        nav={currentNav}
        attemptRefreshChat={attemptRefreshChat}
        attemptRefreshChats={attemptRefreshChats}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  impersonatedUser: state.account.impersonatedUser,
  shouldUpdateRoutes: state.account.updateRoutes,
});

const mapDispatchToProps = dispatch => {
  return {
    attemptRefreshChats: (refresh) => dispatch(refreshChats()),
    attemptRefreshChat: (id) => dispatch(refreshChat(id)),
    attemptGetUsers: userGroup => dispatch(getUserGroup(userGroup)),
    attemptLogOut: () => dispatch(logOut()),
    attemptStopImpersonating: () => dispatch({ type: STOP_IMPERSONATE_USER }),
    updateRoutes: () => dispatch({ type: UPDATE_ROUTES })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

function ImpersonatedUserBanner({
  impersonatedUser,
  currentUser,
  stopImpersonating
}) {
  return impersonatedUser !== null ? (
    <div className="impersonate_user_container">
      <div className="impersonate_user_section" />
      <div className="impersonate_user_section">
        {i18nstring(strings.VIEWING_AS)}
        {currentUser.userName}
      </div>
      <div className="impersonate_user_section_end">
        <Button
          type="submit"
          onClick={stopImpersonating}
          color="google plus"
          floated="right"
        >
          {i18nstring(strings.STOP)}
        </Button>
      </div>
    </div>
  ) : null;
}
