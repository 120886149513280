import React from "react";
import { connect } from "react-redux";
// Actions
import {
  getLocales,
  saveDeliveryScheduleOnboarding
} from "../../../redux/actions/Onboarding";
// Components
import { DeliveryScheduleFormContent } from "../../molecules/DeliveryScheduleContent";
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";

function DeliveryScheduleForm(props) {
  const setDeliverySchedule = deliverySchedule => {
    props.attemptSetDeliverySchedule(deliverySchedule);
    props.handleNext();
  };
  return (
    <div className="modal_main">
      <div className="modal_header">{i18nstring(strings.ACCOUNT_CREATION)}</div>
      <div className="modal_content">
        <DeliveryScheduleFormContent
          attemptSetDeliverySchedule={setDeliverySchedule}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  locales: state.account.locales,
  deliverySchedule: state.onboarding.deliverySchedule
});

const mapDispatchToProps = dispatch => {
  return {
    attemptSetDeliverySchedule: deliverySchedule =>
      dispatch(saveDeliveryScheduleOnboarding(deliverySchedule)),
    getLocales: () => dispatch(getLocales())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryScheduleForm);
