import React, { Component } from "react";
import moment from "moment";
import AppIcon from "../../images/appIcon.png";

export default class ChatListItem extends Component {
  pressed = () => {
    const { item, selected, index, activeChat } = this.props;
    if (item && selected) {
      selected(item);
    }
  };
  render() {
    const { item, activeChat, index } = this.props;
    let { avatar, name, preview, date } = item;

    date = moment(date);
    const dateFormatted = date.fromNow();

    return (
      <div
        style={{
          display: "flex",
          padding: "12px 10px",
          borderLeft: activeChat?.id == item.id ? "5px solid #5cba9c" : "none",
          alignItems: "flex-start",
          cursor: "pointer",
          borderBottom: "1px solid rgba(0,0,0,0.2)"
        }}
        onClick={this.pressed}
      >
        <img
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            objectFit: "contain"
          }}
          src={avatar ? avatar : AppIcon}
        />
        <div
          style={{
            margin: "0px 10px",
            flex: 1,
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          <div
            style={{
              fontSize: 16,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#333"
            }}
          >
            {name}
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: "400",
              color: "#5f6162",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {preview}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 10, fontWeight: "300", color: "#5f6162" }}>
            {dateFormatted}
          </div>
        </div>
      </div>
    );
  }
}
