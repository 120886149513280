exports.Roles = {
  PRODUCER: 'PRODUCER',
  COURIER: 'COURIER',
  RECIPIENT: 'RECIPIENT',
  ADMIN: 'ADMIN'
}

exports.ProducerSubRoles = {
  LOCAL_FARM: 'LOCAL_FARM',
  COMMUNITY_GARDEN: 'COMMUNITY_GARDEN',
  BACKYARD_GARDENER: 'BACKYARD_GARDENER',
  HOME_GARDEN: 'HOME_GARDEN',
  OTHER: 'OTHER'
}

exports.NonProfitSubRoles = {
  COMMUNITY_CENTER: 'COMMUNITY_CENTER',
  FOOD_BANK: 'FOOD_BANK',
  FOOD_NONPROFIT: 'FOOD_NONPROFIT',
  FOOD_RESCUE: 'FOOD_RESCUE',
  SCHOOL: 'SCHOOL',
  OTHER: 'OTHER'
}
