/* DASH ROUTES */
export const DASHBOARD = "Dashboard";
export const DATA_EXPORT = "Data Export";
export const SELF_SERVICE = "Drop-Off Donations";
export const FOOD_WEIGHT_REVIEW = "Donations";
export const CHATS = "Chats";
export const COURIER_REVIEW = "Courier Review";
export const GARDENER_REVIEW = "Gardener Review";
export const TIME_WINDOWS = "Locations and Times";
export const SETTINGS = "Settings";
export const SURVEY = "Survey";
export const NON_PROFIT_REVIEW = "Nonprofit Review";

export const SIDE_DASHBOARD = "Dashboard";
export const SIDE_COURIER = "Courier Review";
export const SIDE_GARDENER = "Gardener Review";
export const SIDE_TIME_WINDOWS = "Locations and Times";
export const SIDE_SETTINGS = "Settings";
export const SIDE_SURVEY = "Survey";
export const SIDE_DATA_EXPORT = "Data Export";
export const SIDE_NON_PROFIT = "Nonprofit Review";
export const SIDE_SELF_SERVICE = "Drop-Off Donations";
export const SIDE_FOOD_WEIGHT_REVIEW = "Donations";
export const SIDE_HELP = "Help";
export const SIDE_REPORT_BUG = "Report Bug";
export const SIDE_REQUEST_FEATURE = "Request Feature";

/* STATES */

export const POSTED = "Posted";
export const REQUESTED = "Requested";
export const CLAIMED = "Claimed";
export const PICKED_UP = "Picked up";
export const DELIVERED = "Delivered";
export const CANCELED = "Canceled";
export const EXPIRED = "Expired";

/* FOOD WEIGHT REVIEW */
export const EDIT_WEIGHTS_MESSAGE =
  "Edited weights are saved automatically. Once a new weight is entered, no further action is required.";
export const UPDATE_WEIGHTS = "Update Weights";

/* DAY LABELS */
export const DayLabels = {
  SUNDAY: "SU",
  MONDAY: "MO",
  TUESDAY: "TU",
  WEDNESDAY: "WE",
  THURSDAY: "TH",
  FRIDAY: "FR",
  SATURDAY: "SA"
};
export const TIME_RANGE = "TIME RANGE";
export const DAYS_ALLOWED = "Days Allowed";
export const SET_TIME_RANGES = "Set Time Ranges";
export const AM_TIME = "8:00 - 12:00";
export const PM_TIME = "3:00 -  7:00";
export const AM_LABEL = "AM";
export const PM_LABEL = "PM";

/* SCHEDULES LABELS */
export const TIME_SCHEDULES_NOTE1 = "Select the days and times you offer.";
export const TIME_SCHEDULES_NOTE2 =
  "You can offer just one set of days and times by setting, then saving, your Default Times.";
export const TIME_SCHEDULES_NOTE3 =
  "You can offer more Times by Adding and Editing new Times in the drop down menu.";
export const TIME_SCHEDULES_PICKUP_NOTE = "You can edit pickup timeslots for the selected zip code.";
export const DEFAULT_SCHEDULE = "Default Times";
export const SCHEDULE = "Times";
export const SCHEDULE_NAME = "Times Name";
export const PICK_SCHEDULE = "Pick Times";
export const ADD_EDIT_SCHEDULES = "My Times";
export const CREATE_SCHEDULE = "Create New Times";
export const DELETE_SCHEDULE = "Delete Times";
export const DELETE_SCHEDULE_CONFIRM_MESSAGE =
  "The times will be deleted and the zips will be reassigned to the Default Times.";

/* ADDRESSES LABELS */
export const TIME_ADDRESSES_NOTE1 =
  "Enter the addresses of the locations you offer, if any.";
export const TIME_ADDRESSES_NOTE2 =
  "You already offer one location with your Main Location.";
export const TIME_ADDRESSES_NOTE3 =
  "You can offer more Locations by Adding and Editing new Locations in the drop down menu.";
export const CONTACT_ADDRESS = "Main Location";
export const ADDRESS = "Location";
export const ADDRESS_NAME = "Location Name";
export const ADD_EDIT_ADDRESSES = "My Locations";
export const MANAGE_PICKUPS = "Manage Pickups";
export const CREATE_ADDRESS = "Create New Location";
export const DELETE_ADDRESS = "Delete Location";
export const PICK_ADDRESS = "Pick Location";
export const PICK_ZIP_CODE = "Zip Code";
export const DELETE_ADDRESS_CONFIRM_MESSAGE =
  "The location will be deleted and the zips will be reassigned to the Main Location.";

/* ZIPS LABELS */
export const MANAGE_ZIPS_INFO_NOTE =
  "You cannot remove Zips from your Default Times or Main Location. You must first create new Times and Locations to assign Zips to.";
export const MANAGE_ZIPS_INFO =
  "Assign Zips to your Times and Locations. Click the green edit icons to toggle.";
export const MANAGE_ZIPS_TABLE_HEADER_SCHEDULE = "Times";
export const MANAGE_ZIPS_TABLE_HEADER_ADDRESS = "Location";
export const MANAGE_ZIPS_TABLE_HEADER_ZIPS = "Zips";
export const MANAGE_ZIPS_TABLE_HEADER_EDIT_ZIPS = "";
export const MANAGE_ZIPS = "My Zips";
export const MANAGE_SCHEDULES_ZIPS_MODAL_INFO =
  "Assigning a zip to these times will remove it from its previous times.";
export const MANAGE_SCHEDULES_ZIPS_MODAL_DISABLE_INFO =
  "Disabling it will reassign it to the Default Times.";
export const MANAGE_SCHEDULES_ZIPS = "Manage Zip Times";
export const MANAGE_ADDRESSES_ZIPS_MODAL_INFO =
  "Assigning a zip to this location will remove it from its previous location.";
export const MANAGE_ADDRESSES_ZIPS_MODAL_DISABLE_INFO =
  "Change one or more zip codes to assign them to the Main Location.";
export const MANAGE_ADDRESSES_ZIPS = "Manage Zips Locations";
export const MANAGE_ADDRESSES_NO_ZIPS = "No zips assigned";

/* DATA EXPORTS */
export const EXPORT = "Export";
export const NON_PROFIT_FILTER_LABELS = [
  "Pending Gardeners",
  "Approved Gardeners",
  "Pending Couriers",
  "Approved Couriers"
];
export const DEFAULT_ALL_USERS_FILTER_LABELS = ["Email", "Sign-Up Date"];
export const ALL_USERS_FILTER_LABELS = [
  "Name",
  "Sub Role (Community Garden, Backyard Gardener, etc)",
  "Approved",
  "Active",
  "ZIP Code"
];
export const ALL_ITEMS_FILTER_LABELS = [
  "Name",
  "Category",
  "Posted Weight (producer)",
  "Pick-Up Weight (courier or nonprofit)",
  "Delivery Distance",
  "Date Delivered",
  "Nonprofit Name",
  "Nonprofit ZIP Code",
  "Gardener Name",
  "Gardener Email",
  "Gardener ZIP Code",
  "Partner",
  "Zip Code",
  "DropOff Location"
];
export const DEFAULT_WAITING_USERS_LABELS = [
  "Email",
  "ZIP Code",
  "Register Date",
  "Is Nonprofit?"
];
export const ITEM_FILTER_LABELS = [
  "Name",
  "Category",
  "Posted Weight (producer)",
  "Pick-Up Weight (courier or nonprofit)",
  "Delivery Distance",
  "Date Delivered",
  "Gardener Name",
  "Courier Name",
  "Zip Code",
  "DropOff Location"
];
export const DEFAULT_USER_FILTER_LABELS = ["Email", "Sign-Up Date"];
export const USER_FILTER_LABELS = [
  "Name",
  "Sub Role (Community Garden, Backyard Gardener, etc)",
  "Phone Number",
  "Street Address",
  "City",
  "State",
  "ZIP Code"
];

export const DEFAULT_NOT_SUBSCRIBED_PRODUCERS_LABELS = [
  "Email",
  "Sign-Up Date"
];
export const ALL_NOT_SUBSCRIBED_PRODUCERS_LABELS = [
  "Name",
  "Sub Role (Community Garden, Backyard Gardener, etc)",
  "ZIP Code"
];

export const DATA_TYPE = "Data Type";
export const NON_PROFIT_EXPORT_DESCRIPTION =
  "This tool can be used to submit a request to export information about the nonprofit(s) you manage. The exported data should take no more than a few minutes to aggregate, and will be sent to the entered email address in the form of a .csv file, which can be opened in Excel or similar programs.";
export const NON_PROFIT_EXPORT_WARNING =
  "WARNING: Generating the .csv files for user and item exports can require reading a large amount of data from the database, which can accrue increased Firebase costs.";
export const WHICH_EMAIL_EXPORT =
  "To what email address would you like the data export sent to?";
export const EMPTY_EXPORT_FILTERS =
  "Please select at least one column to export.";
export const EXPORT_SUBMITTED =
  "Your data export is processing and will soon be sent to ";

/* ERRORS */
export const GENERIC_ERROR = "An error has occurred. Please try again later.";
export const TWITTER_CANCEL = "User cancelled login flow.";
export const EXISTS_ON_OTHER =
  "This email associated with this social media account has already been used for a different sign in method.";
export const NETWORK_ERROR =
  "Couldn't connect to Fresh Food Connect. Please check your internet connection then try again.";
export const USER_NOT_FOUND = "No user with that email was found.";
export const MISSING_TOKEN =
  "An authorization error has occurred. Please sign out then back in.";
export const LOCATION_UNSUPPORTED =
  "Your zip code is not supported at this time. Would you like to be notified when your location is supported?";
export const SUBSCRIPTION_UNAVAILABLE =
  "Another user is already subscribed to this zip code. Please change your primary zip code or unselect Nonprofit.";
export const NO_NON_PROFIT =
  "A Nonprofit has not yet claimed this zip code. Sign up in the selected zip code will be unavailable until a Nonprofit has been approved.";
export const EMAIL_ALREADY_USED =
  "That email address is already in use. Please sign in or choose another email.";
export const INCORRECT_EMAIL_PASSWORD =
  "Incorrect email / password combination.";
export const INCORRECT_PASSWORD = "That password is incorrect.";
export const INVALID_EIN = "Must use a valid EIN.";
export const UNDEFINED = "An undefined error occured";

/* FORMS / MODALS */
export const ACCOUNT_CREATION = "Account Creation";
export const CONTACT_INFO = "CONTACT INFORMATION";
export const DELIVERY_INSTRUCTIONS = "Instructions";
export const COURIER_APPROVAL = "Modify Courier Approval";
export const SEND_EMAIL = "Send an Email to: ";
export const APPROVE = "Approve";
export const DENY = "Deny";
export const LOG_OUT = "Logout";
export const USER_ACCOUNT = "USER ACCOUNT";
export const TAX_IDENTIFIER = "TAX IDENTIFIER";
export const NEXT = "Next";
export const STOP = "Stop";
export const SAVE_CHANGES = "Save Changes";
export const CONFIRM = "Confirm";
export const CREATE = "Create and Save";

/* Form Field Labels */
export const ADD_ZIP = "Add Zip";
export const ZIP_CODE_SECTION = "ZIP CODE";
export const DELIVERY_INSTRUCTIONS_NOTE =
  "Please add details for your gardener. NOTE: All Gardeners will see this message.";
export const OFFERS_DISABLED_INSTRUCTIONS_NOTE =
  "If both boxes are unchecked, Please add notes for your gardener. NOTE: All Gardeners will see this message.";
export const PASSWORD_SECTION = "PASSWORD";
export const NON_PROFIT_SECTION = "NONPROFIT";
export const CONTACT_INFO_NOTE = "Physical address for donation dropoff.";
export const PASSWORD_NOTE =
  "Minumum length of 8 Characters, must contain 1 lower case letter, 1 upper case letter, and 1 number.";
export const ENTER_ZIP_NOTE =
  "To proceed, please enter your ZIP code to see if Fresh Food Connect is available in your area.";
export const ADDITIONAL_ZIPS = "Additional ZIP Codes you operate in:";
export const NON_PROFIT_NOTE = "What kind of Nonprofit are you?";
export const EIN_NOTE =
  "Please enter your tax identifier so we can confirm your Nonprofit status.";

/* Form Fields */
export const USERNAME = "Nonprofit Name";
export const EMAIL = "E-Mail Address";
export const PASSWORD = "Password";
export const PASSWORD_CONFIRMATION = "Password Confirmation";
export const SUBMIT = "Submit";
export const REFRESH = "Refresh";
export const ZIP_CODE = "ZIP Code";
export const NEW_ZIP_CODE = "New ZIP Code";
export const EIN = "12-3456789";
export const SUB_ROLES = "Select Sub Group";
export const STREET_ADDRESS_1 = "Street Address 1";
export const STREET_ADDRESS_2 = "Street Address 2";
export const PHONE_NUMBER = "Phone Number";
export const CITY = "City";
export const STATE = "State";
export const ADDITIONAL_ZIP_1 = "ADDITIONAL_ZIP_1";
export const EMAIL_SUBJECT =
  "Please enter the subject line of the email you'd like to send";
export const EMAIL_BODY =
  "Please enter the body of the email you'd like to send";
export const DELIVERY_INSTRUCTIONS_GARDENER =
  "Ex: Pick-ups are available between 3-5pm on Thursdays, etc...";
export const OFFERS_DISABLED_INSTRUCTIONS_GARDENER =
  "Ex: Pick-ups/Dropoffs are disabled from November-June, etc...";
export const WARNING_LOCATION = "Confirming location";
export const WARNING_ZIP = "Confirming zip";
export const POSITIVE_ZIP = "Zip code confirmed!";
export const POSITIVE_LOCATION = "Location confirmed!";
export const ERROR_ZIP =
  "Zip code not available, please email info@freshfoodconnect.org";
export const ERROR_LOCATION =
  "Location not found! Please try a different address";
export const NON_PROFIT_NAME_TITLE = "NONPROFIT NAME";
export const NON_PROFIT_NAME = "Nonprofit Name";
export const NON_PROFIT_NAME_INFO =
  "Use the spacing and capitalization you prefer.";
export const NON_PROFIT_NAME_INFO_EX =
  "Ex. Tropical Food Bank of Florida, GrowScotland.";

/* IMPERSONATING USE */
export const VIEWING_AS = "Viewing as: ";

/* Modal */
export const ATTENTION = "ATTENTION";
export const PICKUP_WINDOW = "Pickup Window";
export const TIME_SCHEDULING = "TIME SCHEDULING";
export const TIME_SCHEDULING_NOTE =
  "Please select the days and time-blocks you would like to make deliveries available for pickup. Note: If you need to be more specific about your pickup/dropoff timings, you can enter it in the address, in Delivery Instructions.";
export const TIME_SCHEDULING_NOTE_2 =
  "If you aren't ready to start right away, you can leave these blank!";

export const TIME_SCHEDULING_NOTE1 =
  "Please select the days and time-blocks you would like to make deliveries available for pickup.";
export const TIME_SCHEDULING_NOTE2 =
  "Note: If you need to be more specific about your pickup/dropoff timings, you can enter it in the address, in Delivery Instructions.";
/* Results */
export const SUCCESS = "SUCCESS";
export const WAITING_USER_SAVED = "WAITING_USER_SAVED";
export const RESET_PASSWORD = "Reset password sent. Please check your email.";

/* Roles */
export const NON_PROFIT = "Nonprofit";
export const FFC_ADMIN = "FFC Admin";

/* Sub Groups */
export const COMMUNITY_CENTER = "Community Center";
export const FOOD_BANK = "Food Bank";
export const FOOD_NONPROFIT = "Food Nonprofit";
export const FOOD_RESCUE = "Food Rescue";
export const SCHOOL = "School";
export const OTHER = "Other";

/* Table Labels */
export const DONATIONS = "Donations";
export const ACTIVE_DONATIONS = "Active Donations";
export const ACTIVE_PENDING_DONATIONS = "Active/Pending Donations";
export const PAST_DONATIONS = "Past Donations";
export const PENDING_APPROVALS = "Pending Approvals";
export const APPROVED_COURIERS = "Approved Couriers";
export const APPROVED_GARDENERS = "Approved Gardeners";
export const APPROVED_NONPROFITS = "Approved Nonprofit";
export const MORE = "MORE";

export const PENDING = "PENDING";
export const REJECTED = "REJECTED";
export const APPROVED = "APPROVED";

/* VALIDATION */
export const TIME_INVALID = "Enter a valid time.";
export const EMAIL_INVALID = "Enter a valid email.";
export const EIN_INVALID = "Enter a valid ein number.";
export const SUB_GROUP_INVALID = "Please select a subgroup.";
export const PASSWORD_INVALID = "Enter a valid password.";
export const PASSWORD_CONFIRMATION_INVALID = "Passwords must match.";
export const USERNAME_INVALID =
  "User name is required and must be longer than 3 characters.";
export const ZIP_INVALID = "Zip code is not valid.";
export const STREET_ADDRESS_INVALID = "Street Address is not valid.";
export const PHONE_NUMBER_INVALID = "Phone number is not valid.";
export const CITY_INVALID = "City is not valid.";
export const STATE_INVALID = "State is not valid.";

/* ROLE ROUTER MESSAGES */
export const BAD_EMAIL_FORMAT = "The email address is badly formatted.";
export const LOGIN_ADMIN =
  "Hello, Admin! Please use the Fresh Food Connect web app for all your administrative needs. Thank you!";
export const LOGIN_APPROVAL_PENDING =
  "Thank you for signing up to use Fresh Food Connect! Your area administrator needs to approve your account before you may begin using the app. They have already been notified. You may also contact them at {{admin_email}}.";
export const LOGIN_APPROVAL_REJECTED =
  "Your account was rejected by your area administrator. If you believe this was a mistake, you may contact them at {{admin_email}}.";
export const LOGIN_INVALID_ROLE =
  "An error occurred while attempting to login. Please try again later.";
export const WRONG_ROLE =
  "You are trying to log in as a courier or gardener. Please use the mobile app, unless logging in as a Nonprofit";
// "The Web App currently only supports Nonprofits and Admins. Please use the mobile app if logging in as a Gardener or Courier";

/* HOME */
export const GO_BACK = "Go Back";
export const LOGIN = "Login";
export const SIGN_UP = "Sign Up";
export const WELCOME_TO_FFC = "Welcome to Fresh Food Connect!";

/* SELF SERVICE */
export const SELF_SERVICE_DISABLED =
  "This option is turned off because your Non-profit doesn't offer Pickup.";
export const SELF_SERVICE_INSTRUCTION_1 =
  'Offer drop-off donations? When items are dropped off to you, click "deliver" on the item to complete the donation.';
export const SELF_SERVICE_INSTRUCTION_2 =
  "Gardeners can also complete their donations, but occasionally forget. You can complete it for them here.";
export const SELF_SERVICE_INSTRUCTION_DISABLED =
  "You do not currently offer Drop-Off, so this page is disabled. You can enable Drop-Off in the Settings tab.";
export const SELF_SERVICE_INSTRUCTION_DROPOFF =
  'This page shows your pending dropoff donations from your gardeners, if you offer dropoff. Click "delivered" when gardeners drop off their donation to you. You can add weights for dropoff items in Food Weight Review once the items are delivered.';

//#region Offers
export const OFFERS = "Nonprofit Offers";
export const OFFERS_INFO =
  "Choose what your nonprofit will support as options for donations.";
export const OFFERS_DROP_OFF = "Drop-Off";
export const OFFERS_DROP_OFF_INFO =
  "Allow a Gardener to take the donation to the nonprofit.";
export const OFFERS_PICKUP = "Pickup";
export const OFFERS_PICKUP_INFO =
  "Allow a Gardener to request a courier to pickup the donation to take to the nonprofit.";
export const ENABLE_PICKUP = "Enable Pickup";
//#endregion Offers

//#region Partners
export const PARTNERS = "Partner";
export const PARTNERS_INFO =
  "Choose your nonprofit partner. If none, leave unselected.";
export const PARTNERS_CSU = "CSU Grow/Give";
//#endregion Partners
