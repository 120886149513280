import React from "react";
// Components
import { TaxAccountFormContent } from "../../molecules/TaxFormContent";
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";

export default function TaxAccountForm(props) {
  return (
    <div className="modal_main">
      <div className="modal_header">{i18nstring(strings.ACCOUNT_CREATION)}</div>
      <div className="modal_content">
        <TaxAccountFormContent
          handleNext={props.handleNext}
          inputs={props.inputs}
        />
      </div>
    </div>
  );
}
