import { NonProfitSubRoles } from "../Shared/Constants/Roles";
import { Roles } from "../Shared/Constants/Roles";
import * as strings from "../constants/Strings";

export const initialUserContact = {
  zip: "",
  streetAddress1: "",
  phoneNumber: "",
  city: "",
  state: "",
  instructions: "",
};

export const initialSignUpUser = {
  email: "",
  userName: "",
  password: "",
  passwordConfirm: "",
  ein: "",
  subRole: "",
};

export const nonProfitSubGroups = Object.keys(NonProfitSubRoles).map(
  (subRole, index) => {
    return {
      key: index,
      text: strings[subRole],
      value: NonProfitSubRoles[subRole],
    };
  }
);

// https://pixelandtexel.assembla.com/spaces/Fresh-Food-Connect/wiki/Users

export default class User {
  id = null;
  userName = "";
  email = "";
  providerId = "";

  contact = {
    streetAddress1: "",
    city: "",
    state: "",
    zip: "",
    country: "",

    lat: "",
    lng: "",

    timeZoneId: "",
    placeId: "",

    instructions: "",

    phoneNumber: "",
  };

  roles = [Roles.RECIPIENT];

  // stats = null;

  approvalStatus = null;

  subscriber = null;
  ein = "";
  // sponsor = null;
  // sponsees = null;

  // photoURL = null;
  created = null;

  addLocale(zip, roles, subscribe, ein) {
    this.contact.zip = zip;
    this.roles = roles;

    if (subscribe) {
      this.ein = ein;
      this.subscriber = true;
    }
  }

  addAuth(auth) {
    if (auth.id) this.id = auth.id;
    if (auth.email) this.email = auth.email;
    if (auth.userName) this.userName = auth.userName;
    if (auth.photoURL) this.photoURL = auth.photoURL;
    if (auth.providerId) this.providerId = auth.providerId;
    if (auth.created) this.created = new Date(auth.created).getUTCDate;
  }
}
