import Immutable from 'seamless-immutable'

export function appendChats(currentChats, chats) {
  let updatedChats = [...currentChats]
  chats.forEach(chat => {
    let newer = currentChats.findIndex(current => current.id === chat.id)
    if (newer === -1) updatedChats.push(chat)
  })
  return updatedChats
}

export function prependNewChats(newChats, chats) {
  let updatedChats = [...newChats]
  chats.forEach(chat => {
    let newer = newChats.findIndex(newChat => newChat.id === chat.id)
    if (newer === -1) updatedChats.push(chat)
  })
  return updatedChats
}

export function prependNewMessages(newMessages, messages) {
  let updatedMessages = [...newMessages]
  messages.forEach(message => {
    let newer = newMessages.findIndex(newChat => newChat.id === message.id)
    if (newer === -1) updatedMessages.push(message)
  })
  return updatedMessages
}

export function mergeChat(chats, newChat) {
  let updatedChats = [...chats]
  let newChatIndex = updatedChats.findIndex(chat => chat.id === newChat.id)

  if (newChatIndex > -1) {
    updatedChats[newChatIndex] = newChat
  } else {
    updatedChats.push(newChat)
  }

  updatedChats.sort((a, b) => b.date - a.date)

  return updatedChats
}

export function mergeMessages(messagesMap, newMessages, chatId) {
  if (!newMessages.length || !chatId) return messagesMap

  let mergedMessages = [...newMessages]
  let chatMessages = messagesMap[chatId]

  if (chatMessages) {
    let lastNewDate = newMessages[newMessages.length - 1].date

    chatMessages.forEach(message => {
      if (message.date < lastNewDate) {
        mergedMessages.push(message)
      }
    })
  }

  return Immutable.set(messagesMap, chatId, mergedMessages)
}

export function mergeCursors(cursorsMap, newCursor, chatId) {
  if (!newCursor || !chatId) return cursorsMap

  let mergedCursor = { ...newCursor }
  let chatCursor = cursorsMap[chatId]

  if (chatCursor) {
    mergedCursor = Immutable.merge(chatCursor, newCursor)
  }

  return Immutable.set(cursorsMap, chatId, mergedCursor)
}

export function trimMessagesMap(map, chatId) {
  let chatIds = Object.keys(map)
  let oldest = null

  chatIds.forEach(id => {
    if (id === chatId) return

    if (!oldest) {
      oldest = id
      return
    }

    const currentOldest = map[oldest][0]
    const latestMessage = map[id][0]
    if (latestMessage.date < currentOldest.date) {
      oldest = id
    }
  })
  map = Immutable.without(map, oldest)

  return map
}
