import React, { useState } from "react";
import { Button, Checkbox } from "semantic-ui-react";
// Constants
import i18nstring, { strings } from "../../i18n";
import { ItemDayScheduled } from "../../Shared/Constants/Item";
// Theme
import "./MoleculeStyles.scss";

export function DeliveryScheduleFormContent(props) {
  const initialVals = {};
  Object.keys(ItemDayScheduled).map(day => (initialVals[day] = false));
  const [amDays, toggleAmDays] = useState(initialVals);
  const [pmDays, togglePmDays] = useState(initialVals);
  const setAmDays = checkBox => {
    const daySelected = checkBox.target.previousSibling.name;
    const newDays = { ...amDays };
    newDays[daySelected] = !amDays[daySelected];
    toggleAmDays(newDays);
  };
  const setPmDays = checkBox => {
    const daySelected = checkBox.target.previousSibling.name;
    const newDays = { ...pmDays };
    newDays[daySelected] = !pmDays[daySelected];
    togglePmDays(newDays);
  };
  const setDeliverySchedule = () => {
    const selectedDays = {
      AM: Object.keys(amDays).filter(day => amDays[day] === true),
      PM: Object.keys(pmDays).filter(day => pmDays[day] === true)
    };
    props.attemptSetDeliverySchedule(selectedDays);
  };
  return (
    <div>
      <div className="time_scheduling_header">
        {i18nstring(strings.TIME_SCHEDULING)}
      </div>
      <div className="time_scheduling_sub_header">
        {i18nstring(strings.TIME_SCHEDULING_NOTE)}
      </div>
      <div className="time_scheduling_sub_header">
        {i18nstring(strings.TIME_SCHEDULING_NOTE_2)}
      </div>
      <div className="time_scheduling">
        <DeliveryScheduleDays />
        <div className="time_scheduling_row">
          <div className="time_scheduling_hours">
            <div>{i18nstring(strings.AM_LABEL)}</div>
          </div>
          <Days days={amDays} toggleDay={setAmDays} />
        </div>
        <div className="time_scheduling_row">
          <div className="time_scheduling_time_label">
            <div className="time_scheduling_hours">
              {i18nstring(strings.PM_LABEL)}
            </div>
          </div>
          <Days days={pmDays} toggleDay={setPmDays} />
        </div>
      </div>
      <Button type="submit" onClick={setDeliverySchedule} floated="right">
        {i18nstring(strings.NEXT)}
      </Button>
    </div>
  );
}

export function Days({ days, toggleDay }) {
  return Object.keys(days).map((day, index) => (
    <div key={index} className="time_scheduling_hours">
      <Checkbox name={day} checked={days[day]} onChange={toggleDay} />
    </div>
  ));
}

export function DeliveryScheduleDays() {
  const renderItemDays = () =>
    Object.values(i18nstring(strings.DayLabels)).map(day => (
      <div className="time_scheduling_hours" key={day}>
        <div>{day}</div>
      </div>
    ));
  return (
    <div className="time_scheduling_row">
      <div className="time_scheduling_hours">
        <div style={{ color: "white" }}>PM</div>
      </div>
      {renderItemDays()}
    </div>
  );
}
