import { HIDE_LOADING, SHOW_LOADING, SHOW_ERROR, SHOW_MESSAGE } from "./Types";
import api from "../../api";
import i18nstring, { strings } from "../../i18n";
import { validateEmail } from "../../utils";
import { DataExport } from "../../Shared/Constants/Data";

export function requestUserExport(email, role, filters) {
  return async function (dispatch, getState) {
    try {
      if (typeof email !== "string" || !validateEmail(email)) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMAIL_INVALID)
        });
        return;
      }
      if (!Object.keys(filters).length) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMPTY_EXPORT_FILTERS)
        });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      const result = await api.data.getUserExport(email, role, filters);

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          message: i18nstring(strings.EXPORT_SUBMITTED) + email
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function requestItemExport(email, start, end, filters) {
  return async function (dispatch, getState) {
    try {
      if (typeof email !== "string" || !validateEmail(email)) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMAIL_INVALID)
        });
        return;
      }
      if (!Object.keys(filters).length) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMPTY_EXPORT_FILTERS)
        });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      const result = await api.data.getItemExport(email, start, end, filters);

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          message: i18nstring(strings.EXPORT_SUBMITTED) + email
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function requestNonProfitExport(email, filters) {
  return async function (dispatch, getState) {
    try {
      if (typeof email !== "string" || !validateEmail(email)) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMAIL_INVALID)
        });
        return;
      }
      if (!Object.keys(filters).length) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMPTY_EXPORT_FILTERS)
        });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      const result = await api.data.getAdminExport(
        email,
        DataExport.NON_PROFIT,
        filters
      );

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          message: i18nstring(strings.EXPORT_SUBMITTED) + email
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function requestAllItemsExport(email, start, end, filters) {
  return async function (dispatch, getState) {
    try {
      if (typeof email !== "string" || !validateEmail(email)) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMAIL_INVALID)
        });
        return;
      }
      if (!Object.keys(filters).length) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMPTY_EXPORT_FILTERS)
        });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      const result = await api.data.getAdminExport(
        email,
        DataExport.ALL_ITEMS,
        { start, end, ...filters }
      );

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          message: i18nstring(strings.EXPORT_SUBMITTED) + email
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function requestAllUsersExport(email, role, filters) {
  return async function (dispatch, getState) {
    try {
      if (typeof email !== "string" || !validateEmail(email)) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMAIL_INVALID)
        });
        return;
      }
      if (!Object.keys(filters).length) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMPTY_EXPORT_FILTERS)
        });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      const result = await api.data.getAdminExport(
        email,
        DataExport.ALL_USERS,
        { role, ...filters }
      );

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          message: i18nstring(strings.EXPORT_SUBMITTED) + email
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function requestWaitingUsersExport(email) {
  return async function (dispatch, getState) {
    try {
      if (typeof email !== "string" || !validateEmail(email)) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMAIL_INVALID)
        });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      const result = await api.data.getAdminExport(
        email,
        DataExport.WAITING_USERS,
        {}
      );

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          message: i18nstring(strings.EXPORT_SUBMITTED) + email
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function requestNotSubscribedProducersExport(email, filters) {
  return async function (dispatch, getState) {
    try {
      if (typeof email !== "string" || !validateEmail(email)) {
        dispatch({
          type: SHOW_ERROR,
          message: i18nstring(strings.EMAIL_INVALID)
        });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      const result = await api.data.getAdminExport(
        email,
        DataExport.NOT_SUBSCRIBED_PRODUCERS,
        { ...filters }
      );

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          message: i18nstring(strings.EXPORT_SUBMITTED) + email
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
