const Routes = {
  ACCOUNT: "/account",
  ADMIN: "/admin",
  COURIER_REVIEW: "/courierReview",
  DATA_EXPORT: "/dataExport",
  DASHBOARD: "/dashboard",
  SELF_SERVICE: "/selfService",
  FOOD_WEIGHT_REVIEW: "/donations",
  CHATS: "/chats",
  GARDENER_REVIEW: "/gardenerReview",
  HOME: "/",
  LOGIN: "/login",
  NON_PROFIT_REVIEW: "/nonProfitReview",
  PASSWORD_FORGET: "/pw-forget",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  TIME_WINDOWS: "/timeWindows",
  SETTINGS: "/settings",
  SURVEY: "/survey",
};

export default Routes;
