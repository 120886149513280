import {
  UPDATE_SUBSCRIPTIONS,
  LOGOUT,
  STOP_IMPERSONATE_USER
} from "../actions/Types";
import Immutable from "seamless-immutable";

// initial state

const initialState = Immutable({
  subscriptions: []
});

// reducers

const updateSubscriptions = (state, action) => {
  let { subscriptions } = action;
  return Immutable.merge(state, { subscriptions });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTIONS:
      return updateSubscriptions(state, action);
    case STOP_IMPERSONATE_USER:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
