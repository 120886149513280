import Immutable from "seamless-immutable";
// Actions
import { getSubscriptions } from "./Subscription";
// Api
import api from "../../api";
import ErrorCodes from "../../api/ErrorCodes";
// Constants
import { ApprovalStatus } from "../../Shared/Constants/User";
import i18nstring, { strings } from "../../i18n";
// Transforms
import * as transforms from "../../transforms/Account";
// Types
import {
  IMPERSONATE_USER,
  HIDE_LOADING,
  LOGOUT,
  SET_LOCALES,
  SET_AUTH_REFRESH,
  SHOW_MESSAGE,
  SHOW_ERROR,
  SHOW_LOADING,
  UPDATE_USER,
  UPDATE_USER_AUTH
} from "./Types";

export function initialize(user) {
  return async function (dispatch, getState) {
    try {
      await getLocales([user.contact.zip], true, false)(dispatch, getState);

      if (user.subscriber) {
        await getSubscriptions(true)(dispatch, getState);
      }
    } catch (e) {
      return console.error(e);
    }
  };
}

export function deleteAccount() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.account.deleteUserAccount();

      if (result.success) {
        await logOut()(dispatch, getState);
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function refreshAuth() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_AUTH_REFRESH, refreshing: true });
      await api.account.refreshToken();
      dispatch({ type: SET_AUTH_REFRESH, refreshing: false });
    } catch (e) {
      console.error(e);
    }
  };
}

// OBSOLETE
export function getLocaleInfo(zip, silent = false, clear = true) {
  return async function (dispatch, getState) {
    try {
      if (clear) dispatch({ type: SET_LOCALES, locale: null });
      if (!silent) dispatch({ type: SHOW_LOADING });

      const result = await api.locale.getLocaleInfo(zip);

      if (result.success) {
        dispatch({ type: SET_LOCALES, locales: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!silent) dispatch({ type: HIDE_LOADING });
    }
  };
}

export function getLocales(zips, silent = false, clear = true) {
  return async function (dispatch, getState) {
    try {
      if (clear) dispatch({ type: SET_LOCALES, locales: [] });
      if (!silent) dispatch({ type: SHOW_LOADING });

      const { user } = getState().account;

      const result = await api.locale.getLocaleInfo(user.contact.zip);

      if (result.success) {
        dispatch({ type: SET_LOCALES, locale: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!silent) dispatch({ type: HIDE_LOADING });
    }
  };
}

export function updateUserContact(contact) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      let { user } = getState().account;

      // contact = Object.stripped(contact);
      user = Immutable.merge(user, { contact });

      const result = await api.account.updateUserAccount(user);

      if (result.success) {
        dispatch({ type: UPDATE_USER, user: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function resetPassword(email) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      if (!email) {
        dispatch({ type: SHOW_ERROR, message: "Enter a valid email" });
        return;
      }
      const authResult = await api.account.resetPassword(email);

      if (authResult.success) {
        // await logOut()(dispatch, getState);
        dispatch({
          type: SHOW_MESSAGE,
          title: i18nstring(strings.SUCCESS),
          message: i18nstring(strings.RESET_PASSWORD)
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: authResult.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function logOut() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      window.localStorage.removeItem("impersonate");
      await api.account.signOutUser();

      dispatch({ type: LOGOUT });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function login(email, password) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      dispatch({ type: SHOW_ERROR, message: null });

      const authResult = await api.account.signInUser(email, password);

      if (authResult.success) {
        const userResult = await api.account.getUserAccount();
        const approvalStatus = userResult.data.approvalStatus;
        const isNonProfitOrAdmin =
          userResult.data.subRoles.ADMIN !== undefined ||
          userResult.data.subRoles.RECIPIENT !== undefined;

        if (!isNonProfitOrAdmin) {
          dispatch({
            type: SHOW_ERROR,
            message: i18nstring(strings.WRONG_ROLE)
          });
          return;
        }

        if (approvalStatus === ApprovalStatus.PENDING) {
          const message = i18nstring(strings.LOGIN_APPROVAL_PENDING).replace(
            "{{admin_email}}",
            "info@freshfoodconnect.org"
          );
          dispatch({ type: SHOW_ERROR, message });
          return;
        }

        if (userResult.success) {
          const user = userResult.data;
          //await initialize(user)(dispatch, getState);

          dispatch({ type: UPDATE_USER, user });
        } else {
          // authenticated, but no user profile exists; continue to onboarding
          if (userResult.code === ErrorCodes.userNotFound) {
            const auth = transforms.authFromAuthResult(authResult.data.user);
            dispatch({ type: UPDATE_USER_AUTH, userAuth: auth });
          } else {
            dispatch({ type: SHOW_ERROR, message: userResult.error });
          }
        }
      } else {
        dispatch({ type: SHOW_ERROR, message: authResult.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function sendEmailVerification() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      const result = await api.account.sendEmailVerificationLink();

      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function impersonateUser(impersonateId) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      console.log(`begin impersonating ${impersonateId}`);
      window.localStorage.setItem("impersonate", impersonateId);

      const result = await api.account.getUserAccount();
      // eslint-disable-next-line no-unused-vars
      // const subscriptionsResult = await api.subscription.getSubscriptions();
      // TODO handle subscriptionsResult failure

      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      } else {
        dispatch({ type: IMPERSONATE_USER, impersonatedUser: result.data });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function clearError() {
  return async function (dispatch, getState) {
    dispatch({ type: SHOW_ERROR, message: null });
  };
}
