import React, { Component } from "react";
import { connect } from "react-redux";
// Actions
import { sendEmailUser, sendEmailUserGroup } from "../../redux/actions/Email";
import { setUserApproval, getUserGroup } from "../../redux/actions/User";
// Components
import { Button } from "../atoms/Button";
import { courierHeaders } from "../../model/Table";
import { CourierTable } from "../organisms/Table";
// Constants
import { newStatus } from "../../model/Table";
import i18nstring, { strings } from "../../i18n";
// Themes
import "./PageStyles.scss";
import { Roles } from "../../Shared/Constants/Roles";

class CourierReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoreModalOpen: false,
      selectedUsersPending: [],
      selectedUsersApproved: []
    };
  }

  componentDidMount() {
    if (!this.props.couriersFetched) {
      this.props.attemptGetUsers(Roles.COURIER);
    }

    const selectableUsers = {};
    this.props.couriers.forEach(courier => {
      selectableUsers[courier.id] = false;
    });
    this.setState({
      selectedUsersPending: selectableUsers,
      selectedUsersApproved: selectableUsers
    });
  }

  render() {
    const { isMoreModalOpen, selectedUsersPending, selectedUsersApproved } =
      this.state;

    const setMoreModal = () =>
      this.setState({ isMoreModalOpen: !isMoreModalOpen });
    const selectUserPending = selectedUsers =>
      this.setState({ selectedUsersPending: selectedUsers });
    const selectUserApproved = selectedUsers =>
      this.setState({ selectedUsersApproved: selectedUsers });

    const toggleSelectedUserPending = userId => {
      const newThings = { ...selectedUsersPending };
      if (userId === newStatus.APPROVED || userId === newStatus.PENDING) {
        if (selectedUsersPending[userId]) {
          Object.keys(selectedUsersPending).map(
            user => (newThings[user] = false)
          );
          newThings[userId] = false;
        } else {
          Object.keys(selectedUsersPending).map(
            user => (newThings[user] = true)
          );
          newThings[userId] = true;
        }
      }
      newThings[userId] = !selectedUsersPending[userId];
      selectUserPending(newThings);
    };
    const toggleSelectedUserApproved = userId => {
      const newThings = { ...selectedUsersApproved };
      if (userId === newStatus.APPROVED || userId === newStatus.PENDING) {
        if (selectedUsersApproved[userId]) {
          Object.keys(selectedUsersApproved).map(
            user => (newThings[user] = false)
          );
          newThings[userId] = false;
        } else {
          Object.keys(selectedUsersApproved).map(
            user => (newThings[user] = true)
          );
          newThings[userId] = true;
        }
      }
      newThings[userId] = !selectedUsersApproved[userId];
      selectUserApproved(newThings);
    };
    const refreshUsers = () => {
      this.props.attemptGetUsers(Roles.COURIER);
    };
    const toggleMore = () => setMoreModal(!isMoreModalOpen);
    const sendEmail = (userId, body, subject) =>
      this.props.attemptSendEmail([userId], body, subject);
    const sendEmailMultiple = (userIds, body, subject) =>
      this.props.attemptSendEmail(userIds, body, subject);
    const sendEmailToGroup = (body, subject) =>
      this.props.attemptSendEmailToGroup([Roles.COURIER], body, subject);
    const changeApproval = (newStatus, userId) =>
      this.props.attemptApproveUser(newStatus, userId);

    return (
      <div className="main_container_section">
        <div className="refresh_container">
          <Button onClick={refreshUsers}>{i18nstring(strings.REFRESH)}</Button>
        </div>
        <div className="main_container_table">
          <div className="table_title">
            {i18nstring(strings.PENDING_APPROVALS)}
          </div>
          <CourierTable
            approvalStatus={newStatus.PENDING}
            headers={courierHeaders}
            changeApproval={changeApproval}
            users={this.props.couriers.filter(
              user => user.approvalStatus === newStatus.PENDING
            )}
            toggleMore={toggleMore}
            sendEmail={sendEmail}
            sendEmailMultiple={sendEmailMultiple}
            sendEmailToGroup={sendEmailToGroup}
            userGroup={Roles.COURIER}
            selectedUsers={selectedUsersPending}
            selectUser={toggleSelectedUserPending}
          />
        </div>
        <div className="main_container_table">
          <div className="table_title">
            {i18nstring(strings.APPROVED_COURIERS)}
          </div>
          <CourierTable
            approvalStatus={newStatus.APPROVED}
            headers={courierHeaders}
            changeApproval={changeApproval}
            users={this.props.couriers.filter(
              user => user.approvalStatus === newStatus.APPROVED
            )}
            toggleMore={toggleMore}
            sendEmail={sendEmail}
            sendEmailMultiple={sendEmailMultiple}
            sendEmailToGroup={sendEmailToGroup}
            userGroup={Roles.COURIER}
            selectedUsers={selectedUsersApproved}
            selectUser={toggleSelectedUserApproved}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  couriers: state.user.couriers,
  couriersFetched: state.user.couriersFetched,
  user: state.account.user
});

const mapDispatchToProps = dispatch => {
  return {
    attemptApproveUser: (newStatus, userId) =>
      dispatch(setUserApproval(newStatus, Roles.COURIER, userId)),
    attemptGetUsers: userGroup => dispatch(getUserGroup(userGroup)),
    attemptSendEmail: (userIds, body, subject) =>
      dispatch(sendEmailUser(userIds, body, subject)),
    attemptSendEmailToGroup: (userGroup, body, subject) =>
      dispatch(sendEmailUserGroup(userGroup, body, subject))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourierReview);
