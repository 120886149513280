import React, { useEffect, useState } from "react";
import SelectSearchInput from "./SelectSearch";
import api from "../../api";

function TableZipRow({
  subscription,
  users,
  inputChecked,
  userId,
  attemptGetUserSubscriptions
}) {
  const [newOperator, setNewOperator] = useState();

  const zipCode = subscription.locale;
  useEffect(() => {
    setNewOperator("");
  }, []);

  const handleClick = async () => {
    try {
      await api.user.reassignZipCodes(userId, newOperator, zipCode);
      await attemptGetUserSubscriptions(userId);
    } catch (err) {
      console.log(err);
    }
  };
  const handleRemoveClick = async () => {
    try {
      await api.user.newWaitingUser(zipCode, userId);
      await attemptGetUserSubscriptions(userId);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="table_row zip_transfer_row">
      <div className="table_cell">
        {inputChecked ? (
          <ul className="allZipsList">
            {subscription.map((zip, index) => {
              return (
                <li className="zipCodes" key={index}>
                  {zip.id}
                </li>
              );
            })}
          </ul>
        ) : (
          zipCode
        )}
      </div>

      <div className="table_cell table_cell-organization">
        <SelectSearchInput users={users} setNewOperator={setNewOperator} />
      </div>
      <div className="table_cell table-zip-row-btns">
        <div className="button_container undefined">
          <div className="button" onClick={handleClick}>
            {" "}
            Reassign
          </div>
        </div>
        <div className="button_container undefined btn_add_waiting">
          <div className="button btn_add_waiting" onClick={handleRemoveClick}>
            {" "}
            Add to Waiting Users
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableZipRow;
