import {
  SET_AUTH_REFRESH,
  SHOW_LOADING,
  HIDE_LOADING
} from "../redux/actions/Types";
import data from "../app.json";
const logRedux = data.logRedux;

const monitorReducerEnhancer = createStore => (
  reducer,
  initialState,
  enhancer
) => {
  const monitoredReducer = (state, action) => {
    // skip logging the loading dispatches
    if (
      !logRedux ||
      action.type === SET_AUTH_REFRESH ||
      action.type === SHOW_LOADING ||
      action.type === HIDE_LOADING
    ) {
      return reducer(state, action);
    }

    // const start = Date.now();

    const newState = reducer(state, action);

    // const elapsed = Date.now() - start;
    // console.log("reducer process time:", elapsed, "MS");

    return newState;
  };

  return createStore(monitoredReducer, initialState, enhancer);
};

export default monitorReducerEnhancer;
