import React from "react";
// Components
import { Link } from "react-router-dom";
import { AvatarImage, Svg } from "../atoms/Images";
import { Image } from "semantic-ui-react";
//Constants
import { Roles } from "../../Shared/Constants/Roles";
import i18nstring, { strings } from "../../i18n";
import {
  Courier,
  DataExport,
  FoodWeight,
  Gardener,
  NonProfit,
  TimeWindows,
  Settings
} from "../../constants/SvgPaths";
import ClipBoard from "../../images/icons/clipBoard.png";
import Dash from "../../images/icons/dash@3x.png";
import Chat from "../../images/icons/chatOutline.png";
import ChatActive from "../../images/icons/chatOutlineActive.png";
//Themes
import "./OrganismStyles.scss";
// import Home from "../../images/svgs/Home.svg";

export function UserHeader({ user, logOut }) {
  const { userName, roles, photoURL } = user;
  const isAdmin = roles[0] === Roles.ADMIN;
  const role = isAdmin
    ? i18nstring(strings.FFC_ADMIN)
    : i18nstring(strings.NON_PROFIT);
  return (
    <div className="user_header">
      <AvatarImage avatar={photoURL} />
      <div className="avatar_component avatar_name">{userName}</div>
      <div className="avatar_component avatar_role">{role}</div>
      <div className="avatar_component avatar_logout" onClick={logOut}>
        {i18nstring(strings.LOG_OUT)}
      </div>
    </div>
  );
}

export function NavRoute({ name, to, isActive, chatsBadge = null }) {
  return isActive ? (
    <Link to={to}>
      <div className="side_nav_route_active">
        <NavIcon isActive={isActive} name={name} />
        <div className="side_nav_route_name_active">{name}</div>
      </div>
    </Link>
  ) : (
    <Link to={to}>
      <div className="side_nav_route">
        <NavIcon isActive={isActive} name={name} />
        <div
          className="side_nav_route_name"
          style={{ display: "flex", alignItems: "center" }}
        >
          {name}
          {chatsBadge && chatsBadge != "0" ? (
            <div
              style={{
                color: "#fff",
                width: 18,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 18,
                padding: 2,
                borderRadius: 9,
                fontSize: 12,
                marginLeft: 5,
                backgroundColor: "#5db99d"
              }}
            >
              {Number(chatsBadge) > 9 ? "9+" : chatsBadge}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Link>
  );
}

export function LinkNavRoute({ name, to, newTab }) {
  return (
    <a href={to} rel="noreferrer" target={newTab ? "_blank" : "_self"}>
      <div className="side_nav_route">
        <NavIcon name={name} />
        <div className="side_nav_route_name">{name}</div>
      </div>
    </a>
  );
}

export function NavIcon({ isActive, name }) {
  switch (name) {
    case i18nstring(strings.COURIER_REVIEW):
      return <Svg active={isActive} paths={Courier} />;
    case i18nstring(strings.DATA_EXPORT):
      return <Svg active={isActive} paths={DataExport} />;
    case i18nstring(strings.GARDENER_REVIEW):
      return <Svg active={isActive} paths={Gardener} />;
    case i18nstring(strings.NON_PROFIT_REVIEW):
      return <Svg active={isActive} paths={NonProfit} />;
    case i18nstring(strings.SELF_SERVICE):
      return <Svg active={isActive} paths={NonProfit} />;
    case i18nstring(strings.FOOD_WEIGHT_REVIEW):
      return <Svg active={isActive} paths={NonProfit} />;
    case i18nstring(strings.CHATS):
      return (
        <Image
          src={isActive ? ChatActive : Chat}
          style={{
            marginLeft: "6px",
            marginRight: "3px",
            width: "20px",
            height: "20px"
          }}
        />
      );
    case i18nstring(strings.TIME_WINDOWS):
      return <Svg active={isActive} paths={TimeWindows} />;
    case i18nstring(strings.SETTINGS):
      return <Svg active={isActive} paths={Settings} />;
    case i18nstring(strings.SIDE_HELP):
      return <Svg paths={NonProfit} />;
    case i18nstring(strings.SIDE_REPORT_BUG):
      return (
        <Image
          src={Dash}
          style={{
            marginLeft: "4px",
            marginRight: "3px",
            width: "23px",
            height: "23px"
          }}
        />
      );
    case i18nstring(strings.SIDE_REQUEST_FEATURE):
      return <Svg paths={DataExport} />;
    case i18nstring(strings.SURVEY):
      return (
        <Image
          src={ClipBoard}
          style={{
            marginLeft: "5px",
            width: "23px",
            height: "23px"
          }}
        />
      );
    default:
      return null;
  }
}
