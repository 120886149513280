import { BaseApi } from "./Base";

class AddressApi extends BaseApi {
  getAddresses() {
    return super.getApiRequest(`/addresses`);
  }

  createAddress({ ...address }) {
    const { phoneNumber, instructions, name, currentSelectedSchedule } = address;
    delete address.phoneNumber;
    delete address.instructions;
    delete address.name;
    delete address.currentSelectedSchedule;
    return super.postApiRequest(`/addresses`, {
      address,
      phoneNumber,
      instructions,
      name,
      currentSelectedSchedule
    });
  }

  deleteAddress(addressId) {
    return super.deleteApiRequest(`/addresses/address`, { addressId });
  }

  updateAddress(addressId, { ...fullAddress }) {
    const { phoneNumber, instructions, name } = fullAddress;
    const address = {
      city: fullAddress.city,
      state: fullAddress.state,
      streetAddress1: fullAddress.streetAddress1,
      zip: fullAddress.zip
    };
    return super.putApiRequest(`/addresses/address`, {
      addressId,
      address,
      phoneNumber,
      instructions,
      name,
    });
  }

  setAddressToZips(addressId, zips) {
    return super.postApiRequest(`/addresses/address`, {
      addressId,
      zips
    });
  }
}

const addressApi = new AddressApi();

export default addressApi;
