import React from "react";
import { connect } from "react-redux";
// Actions
import { savePartnerOnboarding } from "../../../redux/actions/Onboarding";
// Components
import { PartnerContent } from "../../molecules/Partner";
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";

function PartnerForm(props) {
  const setPartner = partner => {
    props.attemptSetPartner(partner.partnerChecked ? partner.partner : "");
    props.handleNext();
  };
  return (
    <div className="modal_main">
      <div className="modal_header">{i18nstring(strings.ACCOUNT_CREATION)}</div>
      <div className="modal_content">
        <PartnerContent attemptSetPartner={setPartner} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    attemptSetPartner: partner => dispatch(savePartnerOnboarding(partner))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm);
