import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { ApiResult, ApiError, BaseApi } from "./Base";
import ErrorCodes from "./ErrorCodes";

class FacebookApi extends BaseApi {
  async loginUser() {
    var result;
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      const loginResult = await super.callAuth('signInWithPopup', () =>
        firebase.auth().signInWithPopup(provider)
      );

      if (loginResult.isCancelled) {
        throw new ApiError(
          "User cancelled request",
          ErrorCodes.signInCancelled
        );
      }

      if (!loginResult.success) {
        result = new ApiResult(null, false, loginResult.code, loginResult.code);
      }

      console.log(
        `Facebook login success with permission:`,
        loginResult.grantedPermissions
      );

      // create a new firebase credential with the token
      let data = loginResult;
      // const authCredential = await this.callAuth().FacebookAuthProvider.credential(
      //   data.accessToken
      // );

      // const userCredential = await this.callAuth().signInWithCredential(
      //   authCredential
      // );
      // const userCredential = "";
      await super.refreshToken();

      // console.log("signed in user via Facebook provider:", userCredential.user);

      result = new ApiResult(data, true);
    } catch (error) {
      console.warn(`Caught error: ${error.message}`);
      /*
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
      */
      let message = super.messageFromAuthError(error);
      result = new ApiResult(null, false, message, error.code);
    }

    return result;
  }
}

const facebookApi = new FacebookApi();

export default facebookApi;
