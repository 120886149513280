import api from "../../api";
import Keys from "../../constants/Keys";
import {
  HIDE_LOADING,
  SET_SCHEDULES,
  SHOW_ERROR,
  SHOW_LOADING,
  SET_SCHEDULE,
  UPDATE_SELECTED_SCHEDULE_ID,
  SET_SCHEDULES_WITH_NEW_SCHEDULE,
  DELETE_SCHEDULE,
  UPDATE_SCHEDULE,
  SET_ADDRESSES,
  UPDATE_SELECTED_ADDRESS,
  UPDATE_SELECTED_ADDRESS_ID,
  SET_ADDRESSES_WITH_NEW_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  SET_SCHEDULE_TO_ZIPS,
  SET_ADDRESS_TO_ZIPS
} from "./Types";

//#region Schedules
/**
 * Update the state selected schedule index
 * @param {string} selectedScheduleId - Selected schedule index
 */
export function updateSelectedScheduleId(selectedScheduleId) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_SELECTED_SCHEDULE_ID, selectedScheduleId });
    } catch (e) {
      console.log(e);
    }
  };
}

/**
 *  Update the delivery schedule of a schedule
 * @param {{scheduleId: string, name: string, deliverySchedule: {AM:[], PM:[]}}} schedule - Schedule to be updated
 */
export function updateSchedule(schedule, zips = []) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      const scheduleId = schedule.id;
      const { deliverySchedule } = schedule;
      const result = await api.schedule.updateSchedule(
        scheduleId,
        deliverySchedule,
      );
      if (result.success) {
        dispatch({
          type: UPDATE_SCHEDULE,
          scheduleId,
          deliverySchedule,
        });
        if (zips?.length > 0) {
          const resultInner = await api.schedule.setScheduleToZips(
            scheduleId,
            zips
          );
        }
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 * Set schedule to an empty one that will be filled with a new delivery schedule
 */
export function setScheduleToCreate() {
  return async function (dispatch, getState) {
    try {
      const selectedSchedule = {
        deliverySchedule: { AM: [], PM: [] },
        name: ""
      };
      dispatch({ type: SET_SCHEDULE, selectedSchedule });
    } catch (e) {
      console.log(e);
    }
  };
}

/**
 *  Get all the schedules of the recipient
 * @param {boolean} clear - Should clear schedules before retrieving, default = true
 */
export function getSchedules(clear = true) {
  return async function (dispatch, getState) {
    try {
      if (clear) dispatch({ type: SET_SCHEDULES, loadedSchedules: [] });
      dispatch({ type: SHOW_LOADING });

      const result = await api.schedule.getSchedules();
      if (result.success) {
        dispatch({ type: SET_SCHEDULES, loadedSchedules: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 *  Set all the loaded schedules of the recipient, used when updating zips in manage zips
 *  @param {{}} loadedSchedules - Loaded schedules to set
 */
export function setSchedules(loadedSchedules) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      dispatch({ type: SET_SCHEDULES, loadedSchedules });
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 * Create a schedule for the recipient
 * @param {{name: string, deliverySchedule: {AM:[], PM:[]}}} schedule - Schedule to create
 */
export function createSchedule(schedule) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      var { loadedSchedules } = getState().timeWindow;
      const { deliverySchedule } = schedule;

      const result = await api.schedule.createSchedule(deliverySchedule);
      if (result.success) {
        const id = result.data.id;
        const key = loadedSchedules[loadedSchedules.length - 1].key + 1;
        dispatch({
          type: SET_SCHEDULES_WITH_NEW_SCHEDULE,
          loadedSchedules: [
            ...loadedSchedules,
            {
              id,
              key,
              zips: [],
              deliverySchedule,
            }
          ],
          selectedScheduleId: key
        });
        return id
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 *  Delete a schedule, reassigning the deleted schedule's zips to the default schedule
 * @param {string} scheduleId - Schedule id to be deleted
 */
export function deleteSchedule(scheduleId) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.schedule.deleteSchedule(scheduleId);
      if (result.success) {
        dispatch({ type: DELETE_SCHEDULE, scheduleId });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 *  Set a schedule to certain zips
 * @param {string} scheduleId - Schedule id to set to zips
 * @param {number} scheduleIdx - Index of the schedule in the array of loadedSchedules
 * @param {string[]} zips - Zips to be set the schedule
 */
export function setScheduleToZips(scheduleId, scheduleIdx, zips) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.schedule.setScheduleToZips(scheduleId, zips);
      if (result.success) {
        dispatch({ type: SET_SCHEDULE_TO_ZIPS, scheduleIdx, zips });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
//#endregion Schedules

//#region Addresses
/**
 *  Get all the addresses of the recipient
 * @param {boolean} clear - Should clear addresses before retrieving, default = true
 */
export function getAddresses(clear = true) {
  return async function (dispatch, getState) {
    try {
      if (clear) dispatch({ type: SET_ADDRESSES, loadedAddresses: [] });
      dispatch({ type: SHOW_LOADING });

      const result = await api.address.getAddresses();
      if (result.success) {
        dispatch({ type: SET_ADDRESSES, loadedAddresses: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 *  Set all the loaded addresses of the recipient, used when updating zips in manage zips
 *  @param {{}} loadedAddresses - Loaded addresses to set
 */
export function setAddresses(loadedAddresses) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      dispatch({ type: SET_ADDRESSES, loadedAddresses });
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 * Update the state selected address index
 * @param {string} selectedAddressId - Selected address index
 */
export function updateSelectedAddressId(selectedAddressId) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_SELECTED_ADDRESS_ID, selectedAddressId });
    } catch (e) {
      console.log(e);
    }
  };
}

/**
 * Set address to an empty one that will be filled with a new address
 */
export function setAddressToCreate() {
  return async function (dispatch, getState) {
    try {
      const selectedAddress = {
        address: {
          city: "",
          phoneNumber: "",
          state: "",
          streetAddress1: "",
          zip: "",
          instructions: ""
        }
      };
      dispatch({ type: UPDATE_SELECTED_ADDRESS, selectedAddress });
    } catch (e) {
      console.log(e);
    }
  };
}

/**
 * Create an address for the recipient
 * @param {{}} address - Address to create
 */
export function createAddress(address, currentSelectedSchedule) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      var { loadedAddresses } = getState().timeWindow;
      const result = await api.address.createAddress(address);
      if (result.success) {
        const id = result.data.id;
        const key = loadedAddresses[loadedAddresses.length - 1].key + 1;
        const name = address.name;
        const title = name;
        dispatch({
          type: SET_ADDRESSES_WITH_NEW_ADDRESS,
          loadedAddresses: [
            ...loadedAddresses,
            {
              id,
              key,
              title,
              zips: [],
              address,
              name
            }
          ],
          selectedAddressId: key,
          schedules: currentSelectedSchedule,
        });
      } else if (!result.data) {
        dispatch({ type: SHOW_ERROR, message: "Address is not correct" });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 *  Update the delivery schedule of a schedule
 */
export function updateAddress(addressId, address, currentSelectedSchedule) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      const result = await api.address.updateAddress(addressId, address, currentSelectedSchedule);
      if (result.success) {
        dispatch({
          type: UPDATE_ADDRESS,
          addressId,
          address
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 *  Delete an address, reassigning the deleted address' zips to the contact address
 * @param {string} addressId - Address id to be deleted
 */
export function deleteAddress(addressId) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.address.deleteAddress(addressId);
      if (result.success) {
        dispatch({ type: DELETE_ADDRESS, addressId });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

/**
 *  Set an address to certain zips
 * @param {string} addressId - Address id to set to zips
 * @param {number} addressIdx - Index of the address in the array of loadedAddresses
 * @param {string[]} zips - Zips to be set the address
 */
export function setAddressToZips(addressId, addressIdx, zips) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.address.setAddressToZips(addressId, zips);
      if (result.success) {
        dispatch({ type: SET_ADDRESS_TO_ZIPS, addressIdx, zips });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
//#endregion Addresses

//#region Schedules and Addresses
/**
 *  Get schedules and addresses of the recipient
 * @param {boolean} clear - Should clear schedules and addresses before retrieving, default = true
 */
export function getSchedulesAndAddresses(clear = true) {
  return async function (dispatch, getState) {
    try {
      if (clear) {
        dispatch({ type: SET_ADDRESSES, loadedAddresses: [] });
        dispatch({ type: SET_SCHEDULES, loadedSchedules: [] });
        dispatch({
          type: UPDATE_SELECTED_SCHEDULE_ID,
          selectedScheduleId: Keys.DEFAULT
        });
        dispatch({
          type: UPDATE_SELECTED_ADDRESS_ID,
          selectedAddressId: Keys.CONTACT
        });
      }
      dispatch({ type: SHOW_LOADING });

      const resultSchedules = await api.schedule.getSchedules();
      const resultAddresses = await api.address.getAddresses();
      if (resultSchedules.success && resultAddresses.success) {
        dispatch({
          type: SET_SCHEDULES,
          loadedSchedules: resultSchedules.data
        });
        dispatch({
          type: SET_ADDRESSES,
          loadedAddresses: resultAddresses.data
        });
      } else {
        dispatch({
          type: SHOW_ERROR,
          message: resultSchedules.error + resultAddresses.error
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
//#endregion Schedules and Addresses
