import axios from "axios";

import account from "./Account";
import data from "./Data";
import email from "./Email";
import facebook from "./Facebook";
import item from "./Item";
import locale from "./Locale";
import subscription from "./Subscription";
import user from "./User";
import survey from "./Survey";
import schedule from "./Schedule";
import address from "./Address";
import userSettings from "./UserSettings";
import chat from "./ChatApi";

import config from "../app.json";
const apiConfig = config.apiConfig;

const init = () => {
  // initialize axios
  var url = "";
  if (apiConfig.productionLevel === "production") {
    url = apiConfig.productionURI;
  } else {
    if (apiConfig.localTesting) {
      url = apiConfig.localURI;
    } else {
      url = apiConfig.stagingURI;
    }
  }
  axios.defaults.baseURL = url;
  console.log("API initialized with URL: ", url);
};

const api = {
  account,
  facebook,
  data,
  email,
  init,
  item,
  locale,
  subscription,
  user,
  survey,
  schedule,
  address,
  userSettings,
  chat
};

export default api;
