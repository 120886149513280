import Immutable from "seamless-immutable";

export function addItem(items, item) {
  if (!item) return items;

  items = Immutable.asMutable(items);
  items.push(item);
  items.sort((a, b) => a.datePosted - b.datePosted);

  return items;
}

export function replaceInItems(items, newItem) {
  if (!newItem) return items;

  let i = items.findIndex(item => item.id === newItem.id);
  if (i !== -1) {
    items = Immutable.set(items, i, newItem);
    items = Immutable.asMutable(items);
    items.sort((a, b) => a.datePosted - b.datePosted);
  }

  return items;
}
