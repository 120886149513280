import React, { useState } from "react";
import { Button, Checkbox } from "semantic-ui-react";
import { Button as AButton } from "../atoms/Button";
// Constants
import i18nstring, { strings } from "../../i18n";
// Theme
import "./MoleculeStyles.scss";
import { FormSectionLabel, FormSectionLargeInput } from "../atoms/FormFields";

export function OffersContent(props) {
  const [dropoff, setDropoff] = useState(props.dropoff || false);
  const [pickup, setPickup] = useState(props.pickup || false);
  const [offersDisabledInstructions, setOffersDisabledInstructions] = useState(
    props.offersDisabledInstructions || ""
  );
  const [modified, setModified] = useState(false);

  const toggleDropoff = () => {
    setModified(true);
    setDropoff(!dropoff);
  };
  const togglePickup = () => {
    setModified(true);
    setPickup(!pickup);
  };

  const setOffers = () => {
    props.attemptSetOffers(
      { DROPOFF: dropoff, PICKUP: pickup },
      offersDisabledInstructions
    );
  };

  const handleInputChange = (e) => {
    setOffersDisabledInstructions(e.target.value);
    setModified(true)
  };

  return (
    <div>
      <div className="offers_header">{i18nstring(strings.OFFERS)}</div>
      <div className="offers_sub_header">{i18nstring(strings.OFFERS_INFO)}</div>
      <div className="offers width-60 mb-1">
        <div className="offers_checkbox_label_container">
          <div className="offers_checkbox_label">
            {i18nstring(strings.OFFERS_DROP_OFF)}
          </div>
          <Checkbox
            name={i18nstring(strings.OFFERS_DROP_OFF)}
            checked={dropoff}
            onChange={toggleDropoff}
          />
        </div>
        <p className="offers_checkbox_label_info">
          {i18nstring(strings.OFFERS_DROP_OFF_INFO)}
        </p>
        <div className="offers_checkbox_label_container">
          <div className="offers_checkbox_label">
            {i18nstring(strings.OFFERS_PICKUP)}
          </div>
          <Checkbox
            name={i18nstring(strings.OFFERS_PICKUP)}
            checked={pickup}
            onChange={togglePickup}
          />
        </div>
        <p className="offers_checkbox_label_info">
          {i18nstring(strings.OFFERS_PICKUP_INFO)}
        </p>
      </div>
      {!pickup && !dropoff && (
        <>
          <label className="offers_textbox_label_info">
            {i18nstring(strings.OFFERS_DISABLED_INSTRUCTIONS_NOTE)}
          </label>
          <FormSectionLargeInput
            name="offersDisabledInstructions"
            placeholder={i18nstring(
              strings.OFFERS_DISABLED_INSTRUCTIONS_GARDENER
            )}
            onChange={handleInputChange}
            width={"100%"}
            maxChar={600}
            value={offersDisabledInstructions ?? ""}
          />
        </>
      )}
      {!props.save ? (
        <Button type="submit" onClick={setOffers} floated="right">
          {i18nstring(strings.NEXT)}
        </Button>
      ) : (
        <div
          className="button_right_container"
          style={{ paddingTop: 10 }}
          onClick={setOffers}
        >
          <AButton disabled={!modified}>
            {i18nstring(strings.SAVE_CHANGES)}
          </AButton>
        </div>
      )}
    </div>
  );
}
