import Immutable from "seamless-immutable";
import {
  UPDATE_SELECTED_SCHEDULE_ID,
  SET_SCHEDULE,
  SET_SCHEDULES,
  CREATE_SCHEDULE,
  SET_SCHEDULES_WITH_NEW_SCHEDULE,
  DELETE_SCHEDULE,
  UPDATE_SCHEDULE,
  SET_ADDRESSES,
  UPDATE_SELECTED_ADDRESS,
  UPDATE_SELECTED_ADDRESS_ID,
  SET_ADDRESSES_WITH_NEW_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  SET_SCHEDULE_TO_ZIPS,
  SET_ADDRESS_TO_ZIPS
} from "../actions/Types";
import i18nstring, { strings } from "../../i18n";
import { createSchedule } from "../actions/TimeWindow";
import Keys from "../../constants/Keys";

const initialState = Immutable({
  selectedSchedule: null,
  selectedAddress: null,
  selectedScheduleId: Keys.DEFAULT,
  selectedAddressId: Keys.CONTACT,
  loadedSchedules: [],
  loadedAddresses: []
});

//#region Schedules
const setSchedule = (state, action) => {
  const { selectedSchedule } = action;
  return Immutable.merge(state, {
    selectedSchedule
  });
};

const updateSchedules = (state, action) => {
  const loadedSchedules = action.loadedSchedules.map((schedule, key) => ({
    ...schedule,
    key,
    title: key === 0 ? i18nstring(strings.DEFAULT_SCHEDULE) : schedule.name
  }));
  return Immutable.merge(state, {
    loadedSchedules,
    selectedSchedule: loadedSchedules[0]
  });
};

const updateSchedule = (state, action) => {
  const { scheduleId, deliverySchedule, name } = action;
  let loadedSchedules = [...state.loadedSchedules];
  const updatedScheduleIdx = loadedSchedules.findIndex(
    x => x.id === scheduleId
  );
  return Immutable.merge(state, {
    loadedSchedules: [
      ...loadedSchedules.slice(0, updatedScheduleIdx),
      {
        ...loadedSchedules[updatedScheduleIdx],
        deliverySchedule,
        name,
        title: name
      },
      ...loadedSchedules.slice(updatedScheduleIdx + 1)
    ]
  });
};

const updateSelectedScheduleId = (state, action) => {
  const { selectedScheduleId } = action;
  return Immutable.merge(state, {
    selectedScheduleId,
    selectedSchedule: state.loadedSchedules[selectedScheduleId]
  });
};

const setSchedulesWithNewSchedule = (state, action) => {
  const { loadedSchedules, selectedScheduleId } = action;
  return Immutable.merge(state, {
    loadedSchedules,
    selectedScheduleId,
    selectedSchedule: loadedSchedules[selectedScheduleId]
  });
};

const deleteSchedule = (state, action) => {
  const zipsFromDeletion = [...state.selectedSchedule.zips];
  let remainingSchedules = [
    ...state.loadedSchedules
      .map((selectedSchedule, idx) => ({
        ...selectedSchedule,
        key: idx > state.selectedScheduleId ? idx - 1 : idx
      }))
      .filter(schedule => schedule.id !== action.scheduleId)
  ];
  remainingSchedules[Keys.DEFAULT_IDX] = {
    ...remainingSchedules[Keys.DEFAULT_IDX],
    zips: [...remainingSchedules[Keys.DEFAULT_IDX].zips, ...zipsFromDeletion]
  };
  return Immutable.merge(state, {
    loadedSchedules: remainingSchedules,
    selectedScheduleId: Keys.DEFAULT,
    selectedSchedule: state.loadedSchedules[Keys.DEFAULT]
  });
};

const setScheduleToZips = (state, action) => {
  const { scheduleIdx, zips } = action;
  const disabledZips = state.loadedSchedules[scheduleIdx].zips.filter(
    zip => !zips.includes(zip)
  );
  let loadedSchedules = [...state.loadedSchedules];
  loadedSchedules = loadedSchedules.map(schedule => ({
    ...schedule,
    zips: schedule.zips.filter(zip => !zips.includes(zip))
  }));
  loadedSchedules[Keys.DEFAULT_IDX] = {
    ...loadedSchedules[Keys.DEFAULT_IDX],
    zips: [...loadedSchedules[Keys.DEFAULT_IDX].zips, ...disabledZips]
  };
  loadedSchedules[scheduleIdx] = { ...loadedSchedules[scheduleIdx], zips };
  return Immutable.merge(state, {
    loadedSchedules,
    selectedSchedule: loadedSchedules[scheduleIdx]
  });
};
//#endregion Schedules

//#region Addresses
const updateSelectedAddress = (state, action) => {
  const { selectedAddress } = action;
  return Immutable.merge(state, {
    selectedAddress
  });
};

const updateAddresses = (state, action) => {
  const loadedAddresses = action.loadedAddresses.map((address, key) => ({
    ...address,
    key,
    title: key === 0 ? i18nstring(strings.CONTACT_ADDRESS) : address.name
  }));
  return Immutable.merge(state, {
    loadedAddresses,
    selectedAddress: loadedAddresses[0]
  });
};

const updateAddress = (state, action) => {
  const { addressId, address } = action;
  let loadedAddresses = [...state.loadedAddresses];
  const updatedAddressIdx = loadedAddresses.findIndex(x => x.id === addressId);
  return Immutable.merge(state, {
    loadedAddresses: [
      ...loadedAddresses.slice(0, updatedAddressIdx),
      {
        ...loadedAddresses[updatedAddressIdx],
        address,
        title: address.name
      },
      ...loadedAddresses.slice(updatedAddressIdx + 1)
    ]
  });
};

const updateSelectedAddressId = (state, action) => {
  const { selectedAddressId } = action;
  return Immutable.merge(state, {
    selectedAddressId,
    selectedAddress: state.loadedAddresses[selectedAddressId]
  });
};

const setAddressesWithNewAddress = (state, action) => {
  const { loadedAddresses, selectedAddressId } = action;
  return Immutable.merge(state, {
    loadedAddresses,
    selectedAddressId,
    selectedAddress: loadedAddresses[selectedAddressId]
  });
};

const deleteAddress = (state, action) => {
  const zipsFromDeletion = [...state.selectedAddress.zips];
  let remainingAddresses = [
    ...state.loadedAddresses
      .map((address, idx) => ({
        ...address,
        key: idx > state.selectedAddressId ? idx - 1 : idx // Shift one if it's not the last one
      }))
      .filter(address => address.id !== action.addressId)
  ];
  remainingAddresses[Keys.CONTACT_IDX] = {
    ...remainingAddresses[Keys.CONTACT_IDX],
    zips: [...new Set([...remainingAddresses[Keys.CONTACT_IDX].zips, ...zipsFromDeletion])]
  };
  return Immutable.merge(state, {
    loadedAddresses: remainingAddresses,
    selectedAddressId: Keys.CONTACT,
    selectedAddress: state.loadedAddresses[Keys.CONTACT]
  });
};

const setAddressToZips = (state, action) => {
  const { addressIdx, zips } = action;
  let loadedAddresses = [...state.loadedAddresses];
  loadedAddresses = loadedAddresses.map(address => ({
    ...address,
    ...zips
  }));
  loadedAddresses[addressIdx] = { ...loadedAddresses[addressIdx], zips };
  return Immutable.merge(state, {
    loadedAddresses,
    selectedAddress: loadedAddresses[addressIdx]
  });
};
//#endregion Addresses

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //#region Schedules
    case UPDATE_SELECTED_SCHEDULE_ID:
      return updateSelectedScheduleId(state, action);
    case SET_SCHEDULES:
      return updateSchedules(state, action);
    case SET_SCHEDULE:
      return setSchedule(state, action);
    case UPDATE_SCHEDULE:
      return updateSchedule(state, action);
    case CREATE_SCHEDULE:
      return createSchedule(state, action);
    case SET_SCHEDULES_WITH_NEW_SCHEDULE:
      return setSchedulesWithNewSchedule(state, action);
    case DELETE_SCHEDULE:
      return deleteSchedule(state, action);
    case SET_SCHEDULE_TO_ZIPS:
      return setScheduleToZips(state, action);
    //#endregion Schedules
    //#region Addresses
    case SET_ADDRESSES:
      return updateAddresses(state, action);
    case UPDATE_SELECTED_ADDRESS_ID:
      return updateSelectedAddressId(state, action);
    case UPDATE_SELECTED_ADDRESS:
      return updateSelectedAddress(state, action);
    case SET_ADDRESSES_WITH_NEW_ADDRESS:
      return setAddressesWithNewAddress(state, action);
    case DELETE_ADDRESS:
      return deleteAddress(state, action);
    case UPDATE_ADDRESS:
      return updateAddress(state, action);
    case SET_ADDRESS_TO_ZIPS:
      return setAddressToZips(state, action);
    //#endregion Addresses
    default:
      return state;
  }
};

export default reducer;
