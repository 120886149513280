import { SHOW_ERROR, HIDE_LOADING, SHOW_LOADING } from "./Types";
import api from "../../api";

export function sendEmailUser(userIds, body, subject) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.email.sendEmailToUser(userIds, body, subject);
      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function sendEmailUserGroup(userGroup, body, subject) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      const result = await api.email.sendEmailToUserGroup(
        userGroup,
        body,
        subject
      );
      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
