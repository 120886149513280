import { BaseApi } from "./Base";

class DataApi extends BaseApi {
  getUserExport(email, role, filters) {
    return super.getApiRequest("/data/users", {
      email,
      role,
      ...filters
    });
  }

  getItemExport(email, start, end, filters) {
    return super.getApiRequest("/data/items", {
      email,
      start,
      end,
      ...filters
    });
  }

  getAdminExport(email, type, params) {
    return super.getApiRequest("/data/admin", {
      email,
      type,
      ...params
    });
  }
}

const dataApi = new DataApi();

export default dataApi;
