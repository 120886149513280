import {
  GET_SURVEY_RESPONSES,
  IMPERSONATE_USER,
  LOGOUT,
  STOP_IMPERSONATE_USER,
  UPDATE_COURIERS,
  UPDATE_PRODUCERS,
  UPDATE_RECIPIENTS,
  UPDATE_USER_SETTINGS
} from "../actions/Types";
import Immutable from "seamless-immutable";

// initial state

const initialState = Immutable({
  couriers: [],
  couriersFetched: false,
  gardeners: [],
  gardenersFetched: false,
  nonProfits: [],
  nonProfitsFetched: false,
  questions: [],
  zips: [],
  userSettings: null,
  isSelfService: false
});

// reducers

const updateCouriers = (state, action) => {
  const { couriers } = action;
  return Immutable.merge(state, { couriers, couriersFetched: true });
};

const updateGardeners = (state, action) => {
  const { gardeners } = action;
  return Immutable.merge(state, { gardeners, gardenersFetched: true });
};

const updateNonProfits = (state, action) => {
  const { nonProfits } = action;
  return Immutable.merge(state, { nonProfits, nonProfitsFetched: true });
};

const updateUserSettings = (state, action) => {
  const { settings } = action
  return Immutable.merge(state, { userSettings: settings })
}

const setImpersonatedUser = (state, action) => {
  const { impersonatedUser } = action;
  return Immutable.merge(state, {
    couriers: [],
    gardeners: [],
    isSelfService: impersonatedUser.isSelfService
      ? impersonatedUser.isSelfService
      : false
  });
};

const setSurveyResponses = (state, action) => {
  const { data } = action;
  return Immutable.merge(state, {
    questions: data
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_RESPONSES:
      return setSurveyResponses(state, action);
    case IMPERSONATE_USER:
      return setImpersonatedUser(state, action);
    case UPDATE_COURIERS:
      return updateCouriers(state, action);
    case UPDATE_PRODUCERS:
      return updateGardeners(state, action);
    case UPDATE_RECIPIENTS:
      return updateNonProfits(state, action);
    case UPDATE_USER_SETTINGS: 
      return updateUserSettings(state,action);
    case STOP_IMPERSONATE_USER:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
