import { combineReducers } from "redux";
import account from "./Account";
import dialog from "./Dialog";
import subscription from "./Subscription";
import marketplace from "./Marketplace";
import onboarding from "./Onboarding";
import user from "./User";
import timeWindow from "./TimeWindow";
import chat from "./ChatReducers";
import badge from "./BadgeReducers";
import message from "./MessageReducers";

const rootReducer = combineReducers({
  account,
  dialog,
  marketplace,
  onboarding,
  subscription,
  user,
  timeWindow,
  chat,
  badge,
  message
});

export default rootReducer;
