export const confirmPassword = (password, confrimation) => {
  return password === confrimation && password.length > 0;
};

export const validateEIN = eIN => {
  const re = /^\d{2}?-\d{7}$/;
  return re.test(eIN);
};

export const validatePhoneNumber = number => {
  const re = /^\d{10}$/;
  return re.test(number);
};

export const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const roundN = (number, precision = 0) =>
  Math.round(number * 10 ** precision) / 10 ** precision;

export const validatePassword = password => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,}$/;
  return re.test(password);
};

export const validateZip = zip => {
  const re = /^\d{5}$/;
  return re.test(zip);
};

export const validateAdditionalZip = zip => {
  const re = /^\d{5}$/;
  if (zip === "") return true;
  return re.test(zip);
};

export const validateURL = url => {
  const re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return re.test(url);
};

export const validateHttpInUrl = url => {
  const re = /^http[s]*:[/]{2}.*$/;
  return re.test(url);
};

export const ValidationType = {
  EMAIL: "EMAIL",
  PHONE_NUMBER: "PHONE_NUMBER",
  ZIP: "ZIP",
  EIN: "EIN",
  PASSWORD: "PASSWORD"
};

export const Validator = {
  EMAIL: validateEmail,
  PHONE_NUMBER: validatePhoneNumber,
  ZIP: validateZip,
  EIN: validateEIN,
  PASSWORD: validatePassword
};
