import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { ApiResult, BaseApi } from "./Base";

class AccountApi extends BaseApi {
  refreshAuth() {
    return super.refreshToken();
  }

  async signUpUser(email, password) {
    const result = await super.callAuth("createUserWithEmailAndPassword", () =>
      firebase.auth().createUserWithEmailAndPassword(email, password)
    );

    if (!result.success) {
      return result;
    }

    console.log("created user via email provider:", result.data.user);
    result.data = result.data.user;

    await super.refreshToken();
    await this.sendEmailVerificationLink();

    return result;
  }

  async signInUser(email, password) {
    const result = await super.callAuth("signInWithEmailAndPassword", () =>
      firebase.auth().signInWithEmailAndPassword(email, password)
    );

    if (!result.success) {
      return result;
    }

    await super.refreshToken();

    return result;
  }

  createUserAccount(user) {
    return super.postApiRequest("/users", user);
  }

  createUserAccountAndSubscribe(user, zipCodes, deliverySchedule, offers) {
    return super.postApiRequest("/users/subscribe", {
      user,
      zipCodes,
      deliverySchedule,
      offers
    });
  }

  async deleteUserAccount() {
    try {
      const { currentUser } = this.auth();

      if (!currentUser) {
        throw new Error("Current user not found.");
      }

      let verifyDelete = await super.deleteApiRequest("/users");

      if (verifyDelete.data) {
        await currentUser.delete();
      }

      return verifyDelete;
    } catch (e) {
      const message = super.messageFromAuthError(e);
      return new ApiResult(null, false, message);
    }
  }

  getUserAccount() {
    return super.getApiRequest("/users");
  }

  updateUserAccount(user) {
    return super.putApiRequest("/users", user);
  }

  async signOutUser() {
    const result = await super.callAuth("signOut", () =>
      firebase.auth().signOut()
    );

    if (!result.success) {
      return result;
    }

    super.clearToken();

    return new ApiResult(null, true);
  }

  async sendEmailVerificationLink() {
    try {
      const { currentUser } = this.auth();

      await currentUser.sendEmailVerification();

      return new ApiResult(currentUser, true);
    } catch (e) {
      const message = super.messageFromAuthError(e);
      return new ApiResult(null, false, message);
    }
  }

  resetPassword(email) {
    return super.callAuth("sendPasswordResetEmail", () => {
      firebase.auth().sendPasswordResetEmail(email);
    });
  }
}

const accountApi = new AccountApi();

export default accountApi;
