import { connect } from "react-redux";
import React from "react";
// Actions
import { getSurveyResponses } from "../../redux/actions/User";
// Themes
import "./PageStyles.scss";

function SurveyFeedback(props) {
  // const sampleData = ["A", "B", "A", "A"];
  // const truePercentage = sampleData.filter((answer) => answer === "A").length / sampleData.length;
  let questions = props.questions;
  if (questions === null) {
    questions = [];
    props.attemptGetSurveyResponses();
  }

  return (
    <div className="main_container_section">
      {questions.map(question => (
        <QuestionAndAnswer
          key={question.id}
          question={question.question}
          responses={question.responses}
        />
      ))}
    </div>
  );
}

function QuestionAndAnswer({ question, responses }) {
  // const possibleResponses = responses.length > 0 ? responses : [];
  return (
    <div className="table">
      <div className="question_header">Question: "{question}"</div>
      <div className="answer_bar">
        {responses.length > 0 ? (
          responses.map((response, i) => (
            <Answer
              key={i}
              text={response}
              isTrue={response === "Agree"}
              percentage={(
                (responses.filter(answer => answer === "Agree").length /
                  responses.length) *
                100
              ).toFixed(0)}
            />
          ))
        ) : (
          <div className="no_answers">No responses yet</div>
        )}
      </div>
    </div>
  );
}

function Answer({ isTrue, percentage, text }) {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "20pt",
    flex: percentage,
    backgroundColor: isTrue ? "#5db99d" : "#d9912a",
    color: "white",
    fontSize: 16
  };
  return (
    <div style={style}>
      {text} {percentage}%
    </div>
  );
}

const mapStateToProps = state => ({
  questions: state.user.questions
});

const mapDispatchToProps = dispatch => {
  return {
    attemptGetSurveyResponses: () => dispatch(getSurveyResponses())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyFeedback);
