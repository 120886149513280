import React from "react";
import { Modal, Button as SemanticButton } from "semantic-ui-react";
import i18nstring, { strings } from "../../i18n";

export function TutorialModal(props) {
  return (
    <Modal open={props.modalOpen}>
      <Modal.Header>{i18nstring(strings.NEW_FEATURES_TITLE)}</Modal.Header>
      <Modal.Description className={"modal-description"}>
        {i18nstring(strings.NEW_FEATURES_BODY)}
      </Modal.Description>
      <Modal.Content>
        <iframe
          width="910"
          height="500"
          src="https://www.youtube.com/embed/SijGrwMMnKI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Modal.Content>
      <Modal.Actions>
        <SemanticButton
          className={"modal-button-confirm"}
          positive
          onClick={props.closeModal}
        >
          {i18nstring(strings.CLOSE)}
        </SemanticButton>
      </Modal.Actions>
    </Modal>
  );
}
