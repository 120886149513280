import { BaseApi } from "./Base";

class ScheduleApi extends BaseApi {
  getSchedules() {
    return super.getApiRequest(`/schedules`);
  }

  createSchedule(deliverySchedule) {
    return super.postApiRequest(`/schedules`, { deliverySchedule });
  }

  deleteSchedule(scheduleId) {
    return super.deleteApiRequest(`/schedules/schedule`, { scheduleId });
  }

  updateSchedule(scheduleId, deliverySchedule) {
    return super.putApiRequest(`/schedules/schedule`, {
      scheduleId,
      deliverySchedule
    });
  }

  setScheduleToZips(scheduleId, zips) {
    return super.postApiRequest(`/schedules/schedule`, {
      scheduleId,
      zips
    });
  }
}

const scheduleApi = new ScheduleApi();

export default scheduleApi;
