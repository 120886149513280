import React, { useState } from "react";
import { Button, Modal, Image } from "semantic-ui-react";
import { Button as CustomButton } from "../atoms/Button";

// Components
import { EmailSubjectInput, EmailBodyInput } from "../atoms/FormFields";
// Constants
import i18nstring, { strings } from "../../i18n";
import Chat from "../../images/icons/chat.png";

// Theme
import "./OrganismStyles.scss";
import { Roles } from "../../Shared/Constants/Roles";
import { Colors } from "../../constants/Styles";

const modalStyle = {
  width: 900
};

export function GroupMessageModal({
  selectedUsers,
  sendEmailMultiple,
  sendEmailToGroup,
  groupName
}) {
  const [inputs, setInputs] = useState({ messageBody: "", messageSubject: "" });
  const [toggleModal, setToggle] = useState(false);
  const allSelected = selectedUsers["APPROVED"] || selectedUsers["PENDING"];
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  const sendMailToGroup = () => {
    if (allSelected) {
      sendEmailToGroup(inputs.messageBody, inputs.messageSubject);
    } else {
      const emailsToSend = Object.keys(selectedUsers).filter(
        userId =>
          userId !== "PENDING" && userId !== "APPROVED" && selectedUsers[userId]
      );
      sendEmailMultiple(
        emailsToSend,
        inputs.messageBody,
        inputs.messageSubject
      );
    }
    setToggle(false);
  };
  let clientFriendlyGroupName = "";
  switch (groupName) {
    case Roles.RECIPIENT:
      clientFriendlyGroupName = " All Nonprofits";
      break;
    case Roles.COURIER:
      clientFriendlyGroupName = " All Couriers in your Nonprofit";
      break;
    case Roles.PRODUCER:
      clientFriendlyGroupName = " All Gardners in your Nonprofit";
      break;
    default:
      console.warn("unhandled role");
      break;
  }

  return (
    <Modal
      style={modalStyle}
      open={toggleModal}
      trigger={
        <div
          className="table_header_action_button"
          onClick={() => setToggle(true)}
        >
          Message
        </div>
      }
    >
      <Modal.Header>
        {i18nstring(strings.SEND_EMAIL)}{" "}
        {allSelected ? clientFriendlyGroupName : "multiple users"}
      </Modal.Header>
      <Modal.Content>
        <EmailSubjectInput
          name="messageSubject"
          placeholder={i18nstring(strings.EMAIL_SUBJECT)}
          onChange={handleInputChange}
          width={"100%"}
          maxChar={100}
          value={inputs.messageSubject}
        />
        <EmailBodyInput
          name="messageBody"
          placeholder={i18nstring(strings.EMAIL_BODY)}
          onChange={handleInputChange}
          width={"100%"}
          maxChar={300}
          value={inputs.messageBody}
        />
      </Modal.Content>
      <Button
        style={{ marginBottom: "2.5%", marginLeft: "2.5%" }}
        negative
        floated="left"
        onClick={() => setToggle(false)}
      >
        cancel
      </Button>
      <Button
        style={{ marginBottom: "2.5%", marginRight: "2.5%" }}
        positive
        floated="right"
        onClick={sendMailToGroup}
      >
        Send
      </Button>
    </Modal>
  );
}

export function MessageModal({ sendEmail, userEmail, userId }) {
  const [inputs, setInputs] = useState({ messageBody: "", messageSubject: "" });
  const [toggleModal, setToggle] = useState(false);
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  const send = () => {
    sendEmail(userId, inputs.messageBody, inputs.messageSubject);
    setToggle(false);
  };
  return (
    <Modal
      style={modalStyle}
      open={toggleModal}
      trigger={
        <div className="table_cell_action" onClick={() => setToggle(true)}>
          <Image src={Chat} className="chat_image" />
        </div>
      }
    >
      <Modal.Header>
        {i18nstring(strings.SEND_EMAIL)} {userEmail}
      </Modal.Header>
      <Modal.Content>
        <EmailSubjectInput
          name="messageSubject"
          placeholder={i18nstring(strings.EMAIL_SUBJECT)}
          onChange={handleInputChange}
          width={"100%"}
          maxChar={100}
          value={inputs.messageSubject}
        />
        <EmailBodyInput
          name="messageBody"
          placeholder={i18nstring(strings.EMAIL_BODY)}
          onChange={handleInputChange}
          width={"100%"}
          maxChar={300}
          value={inputs.messageBody}
        />
      </Modal.Content>
      <Button
        style={{ marginBottom: "2.5%", marginLeft: "2.5%" }}
        negative
        floated="left"
        onClick={() => setToggle(false)}
      >
        Cancel
      </Button>
      <Button
        style={{ marginBottom: "2.5%", marginRight: "2.5%" }}
        positive
        floated="right"
        onClick={send}
      >
        Send
      </Button>
    </Modal>
  );
}

export function DialogueModal(props) {
  return (
    <Modal
      defaultOpen
      onActionClick={props.toggleModal}
      style={{ ...modalStyle, width: "30%" }}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content style={{ alignItems: "flex-end" }}>
        <div className="content_message">{props.message}</div>
      </Modal.Content>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: props.cancelModal ? "space-between" : "flex-end",
          alignItems: "flex-end",
          padding: "2.3pt",
          height: "40pt",
          width: "100%",
          alignSelf: "center"
        }}
      >
        {props.cancelModal && (
          <CustomButton onClick={props.cancelModal} color={Colors.lightGrey}>
            {i18nstring(strings.CANCEL)}
          </CustomButton>
        )}
        <CustomButton onClick={props.toggleModal}>
          {i18nstring(strings.CONFIRM)}
        </CustomButton>
      </div>
    </Modal>
  );
}
