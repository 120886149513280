import React, { useState } from "react";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import "@firebase/storage";
// Actions
import { logOut, resetPassword } from "../../redux/actions/Account";
import { saveZipCodesOnboarding } from "../../redux/actions/Onboarding";
import { subscribe, getSubscriptions, getUserSubscriptions } from "../../redux/actions/Subscription";
import { useEffect } from "react";
// Components
import { FormField } from "../atoms/FormFields";
import { Button } from "../atoms/Button";
import { DialogueModal } from "../organisms/Modal";
// Constants
import Avatar from "../../images/icons/avatar.png";
// Themes
import "./PageStyles.scss";
import { OffersContent } from "../molecules/OffersContent";
import {
  getUserSettings,
  setLogo,
  setWebsite,
  updateUserOffers,
  updateUserSettings
} from "../../redux/actions/User";
import { validateHttpInUrl } from "../../utils";
import { TutorialModal } from "../molecules/TutorialModal";
import i18nstring, {
  strings,
  setCurrentLocale,
  SupportedLocales
} from "../../i18n";
import DatePicker from "react-datepicker";
import { Picker } from "../atoms/Picker";
import { months } from "../../model/Settings";

function SettingsScreen(props) {
  const { subscriptions, userSettings } = props;
  const [website, setWebsite] = useState(props.user.website || "");
  const [websiteChanged, setWebsiteChanged] = useState(false);
  const [logo, setLogo] = useState(props.user.photoURL || "");
  const [logoChanged, setLogoChanged] = useState(false);
  const [tutorialModal, setTutorialModal] = useState(false);
  const start = new Date();
  const [startMonth, setStartMonth] = useState(4);
  const [endMonth, setEndMonth] = useState(10);
  const isAdmin = props.user && props.user.roles.includes("ADMIN");
  useEffect(()=>{
    props.attemptGetUserSettings();
    if (!isAdmin && (subscriptions === null || subscriptions.length < 1)) {

      props.attemptGetSubscriptions();
    }
  }, [])

  useEffect(()=>{
    if(userSettings && userSettings?.seasonalMonthsDuration){
      if(startMonth != userSettings?.seasonalMonthsDuration?.from)
        setStartMonth(userSettings?.seasonalMonthsDuration?.from)
      if(endMonth != userSettings?.seasonalMonthsDuration?.to)
        setEndMonth(userSettings?.seasonalMonthsDuration?.to)
    }
  },[userSettings])

  const previousZips = props.subscriptions.map(zips => zips.locale);
  const zipResults =
    props.zipCodes && props.zipCodes.length > 1 ? props.zipCodes : [];

  const supportedZips = zipResults.filter(
    zip => zip.supported && zip.subscriber === undefined
  );
  const unsupportedZips = zipResults.filter(zip => zip.subscriber);
  const [modalOpen, toggleModal] = useState(false);
  const [additionalZips, setAdditionalZips] = useState([
    { name: props.subscriptions.length, valid: true, value: "" }
  ]);
  const initialZips = () =>
    props.subscriptions.map((subscription, index) => (
      <div key={index}>{subscription.locale}</div>
    ));
  const [inputs, setInputs] = useState(previousZips);
  const _resetPassword = () => {
    toggleModal(!modalOpen);
    if (!modalOpen) props.attemptResetPassword(props.user.email);
  };
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  const renderAdditionalZips = () => {
    return additionalZips.map((zip, index) => (
      <FormField
        isDisabled={unsupportedZips.length === 0 && supportedZips.length > 0}
        isValid={true}
        key={index}
        name={zip.name}
        onChange={handleInputChange}
        placeholder={i18nstring(strings.NEW_ZIP_CODE)}
        validationMessage=""
        value={inputs[zip.name] || ""}
        width={"100%"}
      />
    ));
  };

  const addNewZips = () => {
    let zipsToSend = [inputs[additionalZips[0].name]];
    setAdditionalZips([
      {
        name: props.subscriptions.length + 1,
        valid: true,
        value: ""
      }
    ]);
    props.attemptSubscribe(zipsToSend);
  };

  const onWebsiteChange = e => {
    setWebsiteChanged(true);
    setWebsite(e.target.value);
  };

  const updateWebsite = () => {
    setWebsiteChanged(false);
    const newWebsite = validateHttpInUrl(website)
      ? website
      : "http://" + website;
    props.attemptSetWebsite(newWebsite);
  };

  const onLogoChange = e => {
    setLogoChanged(true);
    setLogo(e.target.files[0]);
  };

  const uploadLogo = () => {
    setLogoChanged(false);
    _uploadLogo().then(url => {
      console.log("Logo URL", url);
      props.attemptSetLogo(url);
    });
  };

  const _uploadLogo = () => {
    return new Promise((resolve, reject) => {
      if (!logo) resolve("");
      else {
        const uploadTask = firebase
          .storage()
          .ref(`/profile/${props.user.id}`)
          .put(logo);
        uploadTask.on("state_changed", console.log, console.error, () => {
          firebase
            .storage()
            .ref("profile")
            .child(props.user.id)
            .getDownloadURL()
            .then(url => {
              setLogo(null);
              resolve(url);
            });
        });
      }
    });
  };

  const resetNotificationDates = () => {
    setStartMonth(-1);
    setEndMonth(-1);
  }

  const updateSeasonalDates = () => {
    const data = {
      seasonalMonthsDuration: {
        from: Number(startMonth),
        to: Number(endMonth)
      }
    };
    props.attemptUpdateUserSettings(data);
  };

  const handleStartMonth = (e) => {
    setStartMonth(e.target.value);
    if(e.target.value == endMonth){
      setEndMonth((parseInt(e.target.value) % 11) + 1)
    }
  }
  
  const handleEndMonth = (e) => {
    setEndMonth(parseInt(e.target.value));
  }

  return (
    <div className="main_container_section">
      {tutorialModal && (
        <TutorialModal
          modalOpen={tutorialModal}
          closeModal={() => setTutorialModal(false)}
        />
      )}
      {modalOpen ? (
        <DialogueModal
          open={modalOpen}
          title="Password Reset"
          message="Thank you. An email has been sent with directions to reset your password."
          toggleModal={() => {
            toggleModal(false);
            props.attemptLogOut();
          }}
        />
      ) : null}
      <div className="container_divider_row">
        <div className="card">
          <div className="card_title">
            {i18nstring(strings.ACCOUNT_ACTIONS)}
          </div>
          <div className="card_title">Logo</div>
          <label htmlFor="logo-input">
            <Button>{i18nstring(strings.CHOOSE_FILE)}</Button>
          </label>
          <div
            style={{
              marginTop: 24,
              borderRadius: 5,
              justifyContent: "center",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
          >
            <img
              src={
                validateHttpInUrl(logo)
                  ? logo
                  : (logo && URL.createObjectURL(logo)) || Avatar
              }
              alt="Logo"
              style={{
                width: 120,
                maxWidth: 120,
                maxHeight: 120,
                marginBottom: -5,
                borderRadius: 5
              }}
            />
          </div>
          <input
            style={{ visibility: "hidden" }}
            id="logo-input"
            name="file"
            type="file"
            onChange={onLogoChange}
            accept="image/png, image/jpeg"
          />
          <div style={{ marginBottom: 10 }}>
            <Button disabled={!logoChanged} onClick={uploadLogo}>
              {i18nstring(strings.SAVE)}
            </Button>
          </div>
          <div className="card_divider" />
          <div className="card_title">{i18nstring(strings.WEBSITE)}</div>
          <input
            id="website-input"
            onChange={onWebsiteChange}
            value={website}
            placeholder={"https://nonprofit.com"}
            style={{ width: 250 }}
          />
          <Button
            disabled={!websiteChanged}
            className="settings-btn"
            onClick={updateWebsite}
          >
            {i18nstring(strings.SAVE)}
          </Button>
          <div className="card_divider" />
          <div className="card_title">Set seasonal notifications</div>

          <p>
            Input season duration for donation reminders. Leave blank for
            year-round reminders.
          </p>
          <div className="seasonal_date_range_container">
            From&nbsp;
            <Picker
              title={""}
              items={[...months]}
              selectedItem={startMonth}
              onChange={handleStartMonth}
            />
            &nbsp;&nbsp;To&nbsp;
            <Picker
              title={""}
              items={months.filter(
                e => startMonth == -1 || e.key != startMonth
              )}
              selectedItem={endMonth}
              onChange={handleEndMonth}
            />
          </div>
          <div>
            <Button className="settings-btn" onClick={resetNotificationDates}>
              {i18nstring(strings.RESET)}
            </Button>
            <Button
              disabled={
                (startMonth != -1 && endMonth == -1) ||
                (endMonth != -1 && startMonth == -1)
              }
              className="settings-btn"
              onClick={updateSeasonalDates}
            >
              {i18nstring(strings.SAVE)}
            </Button>
          </div>
          <div className="card_divider" />
          <div className="card_title">{i18nstring(strings.PASSWORD)}</div>
          <Button onClick={_resetPassword}>{i18nstring(strings.RESET)}</Button>
          <div className="card_divider" />
          <div className="card_title">{i18nstring(strings.LANGUAGE)}</div>
          <div style={{ marginTop: 10 }}>
            <div style={{ marginBottom: 10 }}>
              <Button onClick={() => setCurrentLocale(SupportedLocales.EN_US)}>
                {i18nstring(strings.ENGLISH)}
              </Button>
            </div>
            <div>
              <Button onClick={() => setCurrentLocale(SupportedLocales.ES_US)}>
                {i18nstring(strings.SPANISH)}
              </Button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card_title">Zip Codes</div>
          <div style={{ textAlign: "center" }}>
            <div className="additional_zips">{renderAdditionalZips()}</div>
            <Button
              disabled={!inputs[additionalZips[0].name]}
              className="settings-btn"
              onClick={addNewZips}
            >
              {i18nstring(strings.ADD_ZIP)}
            </Button>
          </div>
          <div className="card_divider" />
          <div className="card_title">
            {i18nstring(strings.REMOVE_ZIPS_HELP)}
          </div>
          <p>{i18nstring(strings.REMOVE_ZIPS_CLICK_HERE)}</p>
          <a href="mailto:laura@freshfoodconnect.org?cc=noa%40pixel-n-texel.com&subject=Change%20Zips&body=Your%20Name%3A%20%0A%0AOperator%20%2F%20Nonprofit%3A%20%0A%0ALogin%20Email%3A%20%0A%0AZip%20Codes%20to%20Add%20(if%20any)%3A%0A%0AZip%20Codes%20to%20Remove%20(if%20any)%3A%20%0A%0A%20">
            <Button>{i18nstring(strings.CHANGE_ZIPS)}</Button>
          </a>
          <div className="card_divider" />
          <div className="card_title">
            {i18nstring(strings.SUBSCRIBED_ZIPS)}
          </div>
          <div className="zip_codes_list">{initialZips()}</div>
        </div>
        <div className="card">
          <div className="card_title">{i18nstring(strings.OFFERS)}</div>
          <div className="toggle_self_service_dropoff">
            <div className="self_service_font_container">
              <div className="toggle_offers_container">
                <OffersContent
                  save
                  attemptSetOffers={props.attemptUpdateUserOffers}
                  dropoff={props.user.offers?.DROPOFF}
                  pickup={props.user.offers?.PICKUP}
                  offersDisabledInstructions={props.user?.offersDisabledInstructions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  locales: state.account.locales,
  subscriptions: state.subscription.subscriptions,
  user: state.account.user,
  zipCodes: state.onboarding.locales,
  userSettings: state.user?.userSettings || null
});

const mapDispatchToProps = dispatch => {
  return {
    attemptResetPassword: email => dispatch(resetPassword(email)),
    attemptGetSubscriptions: () => dispatch(getSubscriptions()),
    attemptGetUserSubscriptions: () => dispatch(getUserSubscriptions()),
    attemptSetZipCodes: zipCodes => dispatch(saveZipCodesOnboarding(zipCodes)),
    attemptSubscribe: zipCodes => dispatch(subscribe(zipCodes)),
    attemptUpdateUserOffers: (offers, instructions) =>
      dispatch(updateUserOffers(offers, instructions)),
    attemptSetWebsite: website => dispatch(setWebsite(website)),
    attemptSetLogo: logoUrl => dispatch(setLogo(logoUrl)),
    attemptGetUserSettings: () => dispatch(getUserSettings()),
    attemptUpdateUserSettings: data => dispatch(updateUserSettings(data)),
    attemptLogOut: () => dispatch(logOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
