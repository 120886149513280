import React from "react";
// Components
import { Image } from "semantic-ui-react";
// ImagePaths
import Avatar from "../../images/icons/avatar.png";
import checkMark from "../../images/icons/greenCheckMark.png";
// Theme
import "./AtomStyles.scss";
import FFCBackground from "../../images/background.jpg";

const borderStyle = {
  borderColor: '#5db99d',
  borderWidth: "4pt",
};

export function AvatarImage({ avatar }) {
  const imageSource = avatar ? avatar : Avatar;
  return (
    <Image
      src={imageSource}
      circular
      size="tiny"
      bordered
      style={borderStyle}
      className="avatar_component"
    />
  );
}

export function Svg({ active, paths }) {
  const fill = active ? "#fff" : '#96a1a8';
  const pathComponents = paths.map((path, index) => (
    <path key={index} d={path} fill={fill} />
  ));
  return (
    <svg className="icon" viewBox="0 0 33 33">
      {pathComponents}
    </svg>
  );
}

export function CheckBox({ toggleCheckBox, isChecked }) {
  return isChecked ? (
    <div
      onClick={() => toggleCheckBox(!isChecked)}
      className="checkContainer_active"
    >
      <Image src={checkMark} className="check_image" />
    </div>
  ) : (
    <div onClick={() => toggleCheckBox(!isChecked)} className="checkContainer">
      <div className="empty_check" />
    </div>
  );
}

export function BackgroundImage({ children }) {
  const sectionStyle = {
    display: "flex",
    width: "100%",
    height: "120vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url(${FFCBackground})`,
    alignItems: "center",
    justifyContent: "center",
  };
  return <div style={sectionStyle}>{children}</div>;
}
