import React, { useState } from "react";
import { connect } from "react-redux";
// Actions
import { requestUserExport, requestItemExport } from "../../redux/actions/Data";
// Components
import { Image, Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";
// Constants
import i18nstring, { strings } from "../../i18n";
// Images
import dataStack from "../../images/icons/dataStack.png";
import clipBoard from "../../images/icons/clipBoard.png";
import circleArrowRight from "../../images/icons/circleArrowRight.png";
// Themes
import "react-datepicker/dist/react-datepicker.css";
import "./PageStyles.scss";
import { Roles } from "../../Shared/Constants/Roles";

function FilterMap({ filters, setFilters, defaultFilters, labels }) {
  const defaultControls = defaultFilters.map((filter, index) => {
    return (
      <Form.Checkbox
        key={`default_${index}`}
        label={filter}
        checked={true}
        disabled
      />
    );
  });
  const controls = Object.keys(filters).map((filter, index) => {
    const setFilter = () => setFilters(filter);
    return (
      <Form.Checkbox
        key={`${index}`}
        label={labels[index]}
        checked={filters[filter]}
        onChange={setFilter}
      />
    );
  });
  return defaultControls.concat(controls);
}

const ExportType = {
  ITEMS: "ITEMS",
  GARDENERS: "GARDENERS",
  COURIERS: "COURIERS"
};

function DataExport(props) {
  const [email, setEmail] = useState(props.user.email);
  const [dataType, setDataType] = useState(ExportType.ITEMS);

  const [itemFilters, setItemFilters] = useState({
    name: false,
    category: false,
    weight: false,
    pickUpWeight: false,
    distance: false,
    dateDelivered: false,
    producerName: false,
    courierName: false,
    zip: false,
    dropOffLocation: false,
    itemPhoto: false
  });
  const start = new Date();
  const [startDate, setStartDate] = useState(
    new Date(start.setMonth(start.getMonth() - 2))
  );
  const [endDate, setEndDate] = useState(new Date());
  const setItemFilter = filter => {
    const newFilters = { ...itemFilters };
    newFilters[filter] = !itemFilters[filter];
    setItemFilters(newFilters);
  };
  const sendItemFilters = () => {
    const filtersToSend = {};
    Object.keys(itemFilters).forEach(filter => {
      if (itemFilters[filter]) {
        filtersToSend[filter] = 1;
      }
    });
    props.requestItemExport(
      email,
      startDate.getTime(),
      endDate.getTime(),
      filtersToSend
    );
  };

  const [userFilters, setUserFilters] = useState({
    name: false,
    subRole: false,
    phoneNumber: false,
    streetAddress: false,
    city: false,
    state: false,
    zip: false
  });
  const setUserFilter = filter => {
    const newFilters = { ...userFilters };
    newFilters[filter] = !userFilters[filter];
    setUserFilters(newFilters);
  };
  const sendUserFilters = () => {
    const filtersToSend = {};
    Object.keys(userFilters).forEach(filter => {
      if (userFilters[filter]) {
        filtersToSend[filter] = 1;
      }
    });
    const role =
      dataType === ExportType.COURIERS ? Roles.COURIER : Roles.PRODUCER;
    props.requestUserExport(email, role, filtersToSend);
  };

  const sendFilters = () => {
    switch (dataType) {
      case ExportType.ITEMS:
        sendItemFilters();
        break;
      case ExportType.GARDENERS:
      case ExportType.COURIERS:
        sendUserFilters();
        break;
      default:
        console.error(`Invalid export type ${dataType}`);
        break;
    }
  };

  return (
    <div className="main_container_section">
      <div
        className="instructions_button_container"
        style={{ marginBottom: "1.6rem" }}
      >
        <div>{i18nstring(strings.NON_PROFIT_EXPORT_DESCRIPTION)}</div>
      </div>
      <Form onSubmit={sendFilters} autocomplete="off">
        <div className="step_container">
          <div className="step_row">
            <div className="step_label">1</div>
            <div className="step_row_container">
              <Image className="icon_image" src={dataStack} />
              <div className="step_description_container">
                <h3>{i18nstring(strings.DATA_TYPE)}</h3>
                <Form.Group grouped>
                  <Form.Radio
                    label={i18nstring(strings.ITEMS)}
                    checked={dataType === ExportType.ITEMS}
                    onChange={() => setDataType(ExportType.ITEMS)}
                  />
                  <Form.Radio
                    label={i18nstring(strings.GARDENERS)}
                    checked={dataType === ExportType.GARDENERS}
                    onChange={() => setDataType(ExportType.GARDENERS)}
                  />
                  <Form.Radio
                    label={i18nstring(strings.COURIERS)}
                    checked={dataType === ExportType.COURIERS}
                    onChange={() => setDataType(ExportType.COURIERS)}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="step_container">
          <div className="step_row">
            <div className="step_label">2</div>
            <div className="step_row_container">
              <Image className="icon_image" src={clipBoard} />
              <div className="step_description_container">
                <h3>{i18nstring(strings.COLUMNS_TO_EXPORT)}</h3>
                <FilterMap
                  filters={
                    dataType === ExportType.ITEMS ? itemFilters : userFilters
                  }
                  setFilters={
                    dataType === ExportType.ITEMS
                      ? setItemFilter
                      : setUserFilter
                  }
                  defaultFilters={
                    dataType === ExportType.ITEMS
                      ? []
                      : i18nstring(strings.DEFAULT_USER_FILTER_LABELS)
                  }
                  labels={
                    dataType === ExportType.ITEMS
                      ? i18nstring(strings.ITEM_FILTER_LABELS)
                      : i18nstring(strings.USER_FILTER_LABELS)
                  }
                />
                {dataType === ExportType.ITEMS ? (
                  <div className="export_date_range_container">
                    {i18nstring(strings.START_DATE)}
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                    />
                    {i18nstring(strings.END_DATE)}
                    <DatePicker
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="step_container">
          <div className="step_row">
            <div className="step_label">3</div>
            <div className="step_row_container">
              <Image className="icon_image" src={circleArrowRight} />
              <div className="step_description_container">
                <h3>{i18nstring(strings.SUBMIT)}</h3>
                <Form.Group grouped>
                  <Form.Input
                    label={i18nstring(strings.WHICH_EMAIL_EXPORT)}
                    placeholder="your@email.com"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    error={email === ""}
                  />
                  <Form.Button
                    content={i18nstring(strings.SUBMIT)}
                    className="export_form_submit_button"
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = state => ({
  isLoading: state.dialog.isLoading,
  user: state.account.user
});

const mapDispatchToProps = dispatch => {
  return {
    requestItemExport: (email, start, end, filters) =>
      dispatch(requestItemExport(email, start, end, filters)),
    requestUserExport: (email, role, filters) =>
      dispatch(requestUserExport(email, role, filters))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataExport);
