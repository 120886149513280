export const courierHeaders = ["Courier", "Zip", "E-Mail Address"];
export const gardenersHeaders = ["Gardener", "Zip", "E-Mail Address"];
export const nonProfitHeaders = ["Nonprofit", "Zip", "E-Mail Address"];
export const selfServiceHeaders = [
  "Food",
  "Category",
  "Gardener",
  "Date Scheduled",
  ""
];
export const foodWeightHeaders = [
  "Food",
  "Category",
  "Status",
  "Gardener",
  "Courier",
  "Date Posted",
  "Date Delivered",
  "Weight (Editable)"
];

export const cellActionTypes = {
  APPROVE: "APPROVE",
  DENY: "DENY",
  MENU: "MENU"
};

export const newStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DENY: "DENY"
};

export const users = [
  {
    img: false,
    name: "Jack",
    zip: 75215,
    approved: newStatus.PENDING,
    role: "Courier"
  },
  {
    img: false,
    name: "Jill",
    zip: 75215,
    approved: newStatus.PENDING,
    role: "Courier"
  },
  {
    img: false,
    name: "Joe",
    zip: 75210,
    approved: newStatus.DENY,
    role: "Courier"
  },
  {
    img: false,
    name: "Susan",
    zip: 75215,
    approved: newStatus.APPROVED,
    role: "Courier"
  }
];
