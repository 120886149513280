exports.DataExport = {
  // available only to admins
  NON_PROFIT: 'NON_PROFIT',
  ALL_ITEMS: 'ALL_ITEMS',
  ALL_USERS: 'ALL_USERS',
  WAITING_USERS: 'WAITING_USERS',
  NOT_SUBSCRIBED_PRODUCERS: 'NOT_SUBSCRIBED_PRODUCERS',

  // specific to non-profits
  ITEM: 'ITEM',
  USER: 'USER',
}
