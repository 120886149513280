export function mergeSubscription(subscriptions, newSubscription) {
  let updatedSubscriptions = [...subscriptions]
  let newSourceIndex = updatedSubscriptions.findIndex(
    source => source.id === newSubscription.id
  )

  if (newSourceIndex > -1) {
    updatedSubscriptions[newSourceIndex] = newSubscription
  } else {
    updatedSubscriptions.push(newSubscription)
  }

  updatedSubscriptions.sort((a, b) => b.created - a.created)
  return updatedSubscriptions
}

export function mergeSubscriptions(subscriptions, newSubscriptions) {
  let updatedSubscriptions = [...newSubscriptions]

  subscriptions.forEach(subscription => {
    let newer = newSubscriptions.findIndex(
      newSubscription => newSubscription.id === subscription.id
    )
    if (newer === -1) updatedSubscriptions.push(subscription)
  })

  updatedSubscriptions.sort((a, b) => b.created - a.created)
  return updatedSubscriptions
}
