import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
// Components
import { FormField, FormSectionLabel } from "../atoms/FormFields";
// Constants
import i18nstring, { strings } from "../../i18n";
// Theme
import "./MoleculeStyles.scss";
// Utils
import { validateAdditionalZip, validateZip } from "../../utils";

export function ZipAccountFormContent(props) {
  const [inputs, setInputs] = useState(props.inputs);
  const supportedZips = props.zipResults.filter(
    zip => zip.supported && zip.subscriber === undefined
  );
  const unsupportedZips = props.zipResults.filter(zip => !zip.supported);
  const [additionalZips, setAdditionalZips] = useState([
    { name: "zip2", valid: true, value: "" }
  ]);
  const [validations, setValidation] = useState({
    zip: true,
    zip2: true,
    zip3: true,
    zip4: true,
    zip5: true
  });
  const checkValidation = () => {
    setValidation(() => ({
      zip: validateZip(inputs.zip),
      zip2: validateAdditionalZip(inputs.zip2),
      zip3: validateAdditionalZip(inputs.zip3),
      zip4: validateAdditionalZip(inputs.zip4),
      zip5: validateAdditionalZip(inputs.zip5)
    }));
    return validateZip(inputs.zip) && validateAdditionalZip(inputs.zip2);
  };
  const handleSubmit = e => {
    e.preventDefault();

    const supportedZips = props.zipResults.filter(zip => zip.supported && !zip.subscriber);
    const unsupportedZips = props.zipResults.filter(zip => !zip.supported);

    // we already have valid results; go to next onboarding screen
    if (
      props.zipResults.length &&
      supportedZips.length > 0 &&
      unsupportedZips.length < 1
    ) {
      const validAdditionalZips = additionalZips.filter(
        zip => inputs[zip.name]
      );
      const inputsToSend =
        validAdditionalZips.length > 0
          ? [inputs.zip, ...validAdditionalZips.map(zip => inputs[zip.name])]
          : [inputs.zip];

      if (checkValidation()) props.handleNext(inputsToSend);
    }
    // check the zip codes
    else {
      const validAdditionalZips = additionalZips.filter(
        zip => inputs[zip.name]
      );
      const inputsToSend =
        validAdditionalZips.length > 0
          ? [inputs.zip, ...validAdditionalZips.map(zip => inputs[zip.name])]
          : [inputs.zip];
      props.checkZipCode(inputsToSend);
    }
  };
  const handleInputChange = event => {
    event.persist();
    setValidation(validations => ({
      ...validations,
      [event.target.name]: true
    }));
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  const addZipCode = e => {
    e.preventDefault();
    if (additionalZips.length < 4) {
      setAdditionalZips([
        ...additionalZips,
        {
          name: `zip${additionalZips.length + 2}`,
          valid: true,
          value: ""
        }
      ]);
    }
  };

  const renderAdditionalZips = () => {
    return additionalZips.map((zip, index) => (
      <FormField
        isDisabled={unsupportedZips.length === 0 && supportedZips.length > 0}
        isValid={validations[zip.name]}
        key={index}
        name={zip.name}
        onChange={handleInputChange}
        placeholder={i18nstring(strings.ZIP_CODE)}
        validationMessage={i18nstring(strings.ZIP_INVALID)}
        value={inputs[zip.name]}
        width={"40%"}
      />
    ));
  };
  const renderAddZipButton = () => {
    return additionalZips.length < 4 ? (
      <Button onClick={addZipCode}>{i18nstring(strings.ADD_ZIP)}</Button>
    ) : null;
  };
  const renderNextButton = () => {
    const supportedZips = props.zipResults.filter(zip => zip.supported);
    const unsupportedZips = props.zipResults.filter(zip => zip.subscriber);

    if (
      props.zipResults.length > 0 &&
      supportedZips.length > 0 &&
      unsupportedZips.length < 1
    ) {
      return (
        <Button type="submit" floated="right">
          {i18nstring(strings.NEXT)}
        </Button>
      );
    } else {
      return (
        <Button type="submit" floated="right">
          {i18nstring(strings.CONFIRM)}
        </Button>
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.ZIP_CODE_SECTION)}
      />
      <FormSectionLabel sectionLabel={i18nstring(strings.ENTER_ZIP_NOTE)} />
      <FormField
        isDisabled={unsupportedZips.length === 0 && supportedZips.length > 0}
        isValid={validations.zip}
        name="zip"
        onChange={handleInputChange}
        placeholder={i18nstring(strings.ZIP_CODE)}
        validationMessage={i18nstring(strings.ZIP_INVALID)}
        value={inputs.zip}
        width={"40%"}
      />
      <FormSectionLabel sectionLabel={i18nstring(strings.ADDITIONAL_ZIPS)} />
      {renderAdditionalZips()}
      {renderAddZipButton()}
      {renderNextButton()}
    </Form>
  );
}
