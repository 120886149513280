import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// Actions
import { refreshAuth } from "../redux/actions/Account";
// Constants
import Routes from "../constants/Routes";
import i18nstring, { strings } from "../i18n";
// Pages
import HomeScreen from "../components/pages/Home";
import DashboardScreen from "../components/pages/Dashboard";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshed: false
    };
  }

  componentDidMount() {
    var self = this;
    firebase.auth().onAuthStateChanged(auth => {
      if (auth) {
        self.props.attemptRefreshAuth();
      } else {
        self.setState({ refreshed: true });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshing && !this.props.refreshing) {
      this.setState({ refreshed: true });
    }
  }

  render() {
    var { user } = this.props;

    if (!this.state.refreshed) {
      const loaderTextStyle = {
        display: "flex",
        flex: 1,
        fontSize: "larger",
        fontFamily: "Lato",
        fontWeight: "bold",
        color: "#5db99d",
        marginTop: "25vh",
        justifyContent: "center"
      };
      return (
        <div>
          <div style={loaderTextStyle}>
            Loading Fresh Food Connect Dashboard
          </div>
        </div>
      );
    }

    return (
      <Router basename="/ffc" forceRefresh={false}>
        <Switch>
          <Route
            component={() => <HomeScreen user={user} />}
            exact
            path={Routes.HOME}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_DASHBOARD)}
              />
            )}
            exact
            path={Routes.DASHBOARD}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_SELF_SERVICE)}
              />
            )}
            exact
            path={Routes.SELF_SERVICE}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_FOOD_WEIGHT_REVIEW)}
              />
            )}
            exact
            path={Routes.FOOD_WEIGHT_REVIEW}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_CHATS)}
              />
            )}
            exact
            path={Routes.CHATS}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_DATA_EXPORT)}
              />
            )}
            exact
            history
            path={Routes.DATA_EXPORT}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_COURIER)}
              />
            )}
            exact
            history
            path={Routes.COURIER_REVIEW}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_GARDENER)}
              />
            )}
            exact
            path={Routes.GARDENER_REVIEW}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.SIDE_NON_PROFIT)}
              />
            )}
            exact
            path={Routes.NON_PROFIT_REVIEW}
          />
          <Route
            component={() => (
              <DashboardScreen
                user={user}
                nav={i18nstring(strings.TIME_WINDOWS)}
              />
            )}
            exact
            path={Routes.TIME_WINDOWS}
          />
          <Route
            component={() => (
              <DashboardScreen user={user} nav={i18nstring(strings.SETTINGS)} />
            )}
            exact
            path={Routes.SETTINGS}
          />
          <Route
            component={() => (
              <DashboardScreen user={user} nav={i18nstring(strings.SURVEY)} />
            )}
            exact
            path={Routes.SURVEY}
          />
          <Route path="*">
            <Redirect to={Routes.HOME} />
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  user: state.account.user,
  refreshing: state.account.refreshingAuth
});

const mapDispatchToProps = dispatch => {
  return {
    attemptRefreshAuth: () => dispatch(refreshAuth())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
