import {
  UPDATE_ITEMS,
  UPDATE_ITEM_HISTORY,
  STOP_IMPERSONATE_USER,
  LOGOUT
} from "../actions/Types";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  items: [],
  itemsFetched: false,
  history: [],
  historyFetched: false
});

// reducers

const updateItems = (state, action) => {
  const { items } = action;
  return Immutable.merge(state, {
    items,
    itemsFetched: true
  });
};

const updateHistory = (state, action) => {
  const { history } = action;
  return Immutable.merge(state, {
    history: history,
    historyFetched: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ITEMS:
      return updateItems(state, action);
    case UPDATE_ITEM_HISTORY:
      return updateHistory(state, action);
    case STOP_IMPERSONATE_USER:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
