import React, { useState } from "react";
import { connect } from "react-redux";
import "firebase/compat/storage";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { getUserSubscriptions } from "../../redux/actions/Subscription";

import TableZipRow from "../atoms/TableZipRow";

function ZipTransferPopup(props) {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [inputChecked, handleInputChecked] = useState({ isChecked: true });

  return (
    <div className="table_cell reassign-zip-btn">
      <button
        type="button"
        className="zip_actions_btn"
        onClick={() => {
          props.attemptGetUserSubscriptions(props.userId);
          setOpen(o => !o);
        }}
      >
        Reassign zips
      </button>
      <Popup open={open}>
        <div className="modal">
          <div className="close" onClick={closeModal} >
            &times;
          </div>
          <div className="header"> Zip Actions</div>
          <div className="content">
            {" "}
            <p>
              Below there are all zip codes assigned to selected organization.
              If you want to reassign them, Please mark checkbox and then open
              dropdown and choose organization, to which the zip code will be
              assigned.
            </p>
            <div className="zip_codes_list"></div>
            <strong>Attention:</strong>
            <p>
              If you want to delete user account and add to waiting user list, choose "Add to waiting Users"
            </p>
          </div>

          <div className="main_container_table_popup">
            <div className="table">
              <div className="table_header">
                <div className="table_row zip_transfer_row">
                  <div className="table_cell">Zips</div>

                  <div className="table_cell table_cell-organization">
                    Organizations
                  </div>
                  <div className="table_cell zips_select_all">
                    <p>Select All</p>
                    <input
                      type="checkbox"
                      defaultChecked={inputChecked}
                      onChange={() => handleInputChecked(!inputChecked)}
                      value="checkedall"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="picker_container">
                <div>
                  {inputChecked ? (
                    <TableZipRow
                      key={1}
                      subscription={props.subscriptions}
                      users={props.users}
                      userId={props.userId}
                      inputChecked={inputChecked}
                      attemptGetUserSubscriptions={
                        props.attemptGetUserSubscriptions
                      }
                    />
                  ) : (
                    props.subscriptions.map((subscription, index) => (
                      <TableZipRow
                        key={index}
                        subscription={subscription}
                        users={props.users}
                        userId={props.userId}
                        inputChecked={inputChecked}
                        attemptGetUserSubscriptions={
                          props.attemptGetUserSubscriptions
                        }
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    subscriptions: state.subscription.subscriptions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    attemptGetUserSubscriptions: userId =>
      dispatch(getUserSubscriptions(userId))
  };
};
//
//
export default connect(mapStateToProps, mapDispatchToProps)(ZipTransferPopup);
