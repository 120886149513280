import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import api from "../../api";
import {
  Checkbox,
  Form,
  Icon,
  Modal,
  Table,
  Button as SemanticButton
} from "semantic-ui-react";
// Constants
import i18nstring, { strings } from "../../i18n";
import { ItemDayScheduled } from "../../Shared/Constants/Item";
// Theme
import "./MoleculeStyles.scss";

import { Button } from "../atoms/Button";
import { Picker } from "../atoms/Picker";
import Keys from "../../constants/Keys";
import { Colors } from "../../constants/Styles";
import {
  FormField,
  FormSectionLabel,
  FormSectionLargeInput
} from "../atoms/FormFields";

const initialDays = {};
Object.keys(ItemDayScheduled).forEach(day => {
  initialDays[day] = false;
});


export const PickupTimeWindow = forwardRef((props, ref) => {
  const [amDays, toggleAmDays] = useState();
  const [pmDays, togglePmDays] = useState();

  useEffect(() => {
    let tempAM = { ...initialDays };
    let tempPM = { ...initialDays };
    Object.values(props.deliverySchedule.AM).forEach(day => {
      tempAM[day] = true;
    });
    Object.values(props.deliverySchedule.PM).forEach(day => {
      tempPM[day] = true;
    });
    toggleAmDays(tempAM);
    togglePmDays(tempPM);
  }, [props.deliverySchedule]);

  const setAmDays = checkBox => {
    const daySelected = checkBox.target.previousSibling.name;
    const newDays = { ...amDays };
    newDays[daySelected] = !amDays[daySelected];
    toggleAmDays(newDays);
  };

  const setPmDays = checkBox => {
    const daySelected = checkBox.target.previousSibling.name;
    const newDays = { ...pmDays };
    newDays[daySelected] = !pmDays[daySelected];
    togglePmDays(newDays);
  };

  useImperativeHandle(ref, () => ({
    async setSchedule() {
      const selectedDays = {
        AM: Object.keys(amDays).filter(day => amDays[day] === true),
        PM: Object.keys(pmDays).filter(day => pmDays[day] === true)
      };
      props.updateZipSchedule({
        deliverySchedule: selectedDays
      });
    }
  }));

  if (!amDays || !pmDays) return null;

  return (
    <div>
      <div className="time_scheduling">
        <DeliveryScheduleDays />
        <div className="time_scheduling_row">
          <div className="meredian_div">{i18nstring(strings.AM_LABEL)}</div>
          <Days days={amDays} toggleDay={setAmDays} />
        </div>
        <div className="time_scheduling_row">
          <div className="meredian_div">{i18nstring(strings.PM_LABEL)}</div>
          <Days days={pmDays} toggleDay={setPmDays} />
        </div>
      </div>
    </div>
  );
});

export const TimeWindow = forwardRef((props, ref) => {
  const [name, setName] = useState();
  const [amDays, toggleAmDays] = useState();
  const [pmDays, togglePmDays] = useState();

  useEffect(() => {
    let tempAM = { ...initialDays };
    let tempPM = { ...initialDays };
    Object.values(props.deliverySchedule.AM).forEach(day => {
      tempAM[day] = true;
    });
    Object.values(props.deliverySchedule.PM).forEach(day => {
      tempPM[day] = true;
    });
    toggleAmDays(tempAM);
    togglePmDays(tempPM);
    setName(props.selectedSchedule.name || "");
  }, [props.deliverySchedule, props.selectedSchedule.name]);

  const setAmDays = checkBox => {
    const daySelected = checkBox.target.previousSibling?.name;
    const newDays = { ...amDays };
    newDays[daySelected] = !amDays[daySelected];
    toggleAmDays(newDays);
  };

  const setPmDays = checkBox => {
    const daySelected = checkBox.target.previousSibling?.name;
    const newDays = { ...pmDays };
    newDays[daySelected] = !pmDays[daySelected];
    togglePmDays(newDays);
  };

  useImperativeHandle(ref, () => ({
    async setSchedule() {
      const selectedDays = {
        AM: Object.keys(amDays).filter(day => amDays[day] === true),
        PM: Object.keys(pmDays).filter(day => pmDays[day] === true)
      };
      if (!props.selectedSchedule.id) {
        return await props.createNewSchedule({ deliverySchedule: selectedDays, name });
      } else {
        props.updateSchedule({
          id: props.selectedSchedule.id,
          name,
          deliverySchedule: selectedDays
        });
      }
    }
  }))

  const onNameChange = event => {
    setName(event.target.value);
  };

  if (!amDays || !pmDays) return null;

  return (
    <div>
      <div className="time_scheduling">
        <DeliveryScheduleDays />
        <div className="time_scheduling_row">
          <div className="meredian_div">{i18nstring(strings.AM_LABEL)}</div>
          <Days days={amDays} toggleDay={setAmDays} />
        </div>
        <div className="time_scheduling_row">
          <div className="meredian_div">{i18nstring(strings.PM_LABEL)}</div>
          <Days days={pmDays} toggleDay={setPmDays} />
        </div>
      </div>
    </div>
  );
})

export function Days({ days, toggleDay }) {
  return Object.keys(days).map((day, index) => (
    <div key={index} className="time_scheduling_hours">
      <Checkbox name={day} checked={days[day]} onChange={toggleDay} />
    </div>
  ));
}

export function DeliveryScheduleDays() {
  const renderItemDays = () =>
    Object.values(i18nstring(strings.DayLabels)).map(day => (
      <div className="time_scheduling_hours" key={day}>
        <div>{day}</div>
      </div>
    ));
  return (
    <div className="time_scheduling_row">
      <div className="time_scheduling_hours_div">AM</div>
      {renderItemDays()}
    </div>
  );
}

export function ManageZips(props) {
  const [modal, openModal] = useState(false);
  const [modalSettings, setModalSettings] = useState({
    idx: 0,
    id: "",
    title: "",
    isSchedule: false,
    zips: []
  });

  const _modalOnToggleCheckbox = (e, value, idx) => {
    const zips = [...modalSettings.zips];

    let zip = { ...zips[idx] };
    zip.checked = value.checked;
    zips[idx] = zip;

    api.address.getAddresses().then(addresses => {
      let array = [];
      addresses.data.forEach(address => {
        array.push(address.id);
      });

      const currentIndex = array.indexOf(modalSettings.id);
      const allIndexes = Array.from(Array(addresses.data.length).keys());
      let indexesToCheck = allIndexes.filter(el => el !== currentIndex);
      let disableZip = [];

      if (zips.filter(zip => zip.checked).length < zips.length) {
        disableZip = []
        indexesToCheck.forEach(value => {
          if (addresses.data[value].zips.length === 0) {
            disableZip.push(true);
          } else {
            disableZip.push(false);
          }
        });

        if (!disableZip.includes(false)) return;
      }
      if (zips.filter(zip => zip.checked).length <= zips.length - 1) {
        disableZip = []
        indexesToCheck.forEach(value1 => {
          if (!addresses.data[value1].zips.includes(value.label) && !zip.checked) {
            disableZip.push(true);
          } else {
            disableZip.push(false);
          }
        });

        if (!disableZip.includes(false)) return;
      }
      setModalSettings({ ...modalSettings, zips });
    });
  };

  const defaultOrContact =
    modalSettings.idx === Keys.CONTACT_IDX ||
    modalSettings.idx === Keys.DEFAULT_IDX;
  const _modal = () => {
    return (
      <Modal open={modal}>
        <Modal.Header>{modalSettings.title}</Modal.Header>
        {modalSettings.isSchedule && (
          <Modal.Description className={"modal-description"}>
            i18nstring(strings.MANAGE_SCHEDULES_ZIPS_MODAL_INFO
          </Modal.Description>
        )}
        {!defaultOrContact && (
          <Modal.Description className={"modal-description"}>
            {modalSettings.isSchedule
              ? i18nstring(strings.MANAGE_SCHEDULES_ZIPS_MODAL_DISABLE_INFO)
              : i18nstring(strings.MANAGE_ADDRESSES_ZIPS_MODAL_DISABLE_INFO)}
          </Modal.Description>
        )}
        <Modal.Content>
          {modalSettings.zips.map((zip, idx) => (
            <Checkbox
              className={"modal-checkbox"}
              key={zip.key}
              label={zip.key}
              toggle
              checked={zip.checked}
              onChange={(e, data) => _modalOnToggleCheckbox(e, data, idx)}
            />
          ))}
        </Modal.Content>
        <Modal.Actions>
          <SemanticButton
            className={"modal-button-cancel"}
            negative
            onClick={() => {
              openModal(false);
            }}
          >
            {i18nstring(strings.CANCEL)}
          </SemanticButton>
          <SemanticButton
            className={"modal-button-confirm"}
            positive
            onClick={() => {
              openModal(false);
              if (modalSettings.isSchedule) {
                props.onSubmitUpdateZipSchedule(
                  modalSettings.id,
                  modalSettings.idx,
                  modalSettings.zips
                );
              } else {
                props.onSubmitUpdateZipAddress(
                  modalSettings.id,
                  modalSettings.idx,
                  modalSettings.zips
                );
              }
            }}
          >
            {i18nstring(strings.CONFIRM)}
          </SemanticButton>
        </Modal.Actions>
      </Modal>
    );
  };

  const editScheduleZips = (schedule, idx) => {
    const zips = props.zips.map(zip => ({
      key: zip,
      checked: schedule.zips.includes(zip)
    }));
    setModalSettings({
      id: schedule.id,
      idx,
      title: schedule.title,
      zips,
      isSchedule: true
    });
    openModal(true);
  };

  const editAddressZips = (address, idx) => {
    const zips = props.zips.map(zip => ({
      key: zip,
      checked: address.zips.includes(zip)
    }));
    setModalSettings({
      id: address.id,
      idx,
      title: address.title,
      zips,
      isSchedule: false
    });
    openModal(true);
  };

  return (
    <div>
      {_modal()}
      <div className="time_scheduling_header">
        {i18nstring(strings.MANAGE_ZIPS)}
      </div>
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.MANAGE_ADDRESSES_ZIPS)}
      />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={7}>
              {i18nstring(strings.MANAGE_ZIPS_TABLE_HEADER_ADDRESS)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={7}>
              {i18nstring(strings.MANAGE_ZIPS_TABLE_HEADER_ZIPS)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {i18nstring(strings.MANAGE_ZIPS_TABLE_HEADER_EDIT_ZIPS)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.loadedAddresses.map((address, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{address.title}</Table.Cell>
              <Table.Cell textAlign="center">
                {!address.zips.length > 0
                  ? i18nstring(strings.MANAGE_ADDRESSES_NO_ZIPS)
                  : address.zips.map((zip, idx) => (idx ? ", " : "") + zip)}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <div onClick={() => editAddressZips(address, idx)}>
                  <Icon className="edit-icon" name="edit" size="large" />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
export function ManagePickupAndSchedules(props){
  const {locales} = props;
  const timeWindowRef = useRef(null);
  const [selectedZip, setSelectedZip] = useState(props?.zips?.[0]);
  const [instructions, setInstructions] = useState("");
  const [enablePickup, setEnablePickup] = useState(false);

  const selectedSchedule = locales?.find((e)=>e.zipCode == selectedZip.key);
  const selectedScheduleDelivery = selectedSchedule?.deliverySchedule || { AM: [], PM: [] };

  useEffect(() => {
    setInstructions(selectedSchedule.deliveryInstructions);
    setEnablePickup(selectedSchedule.pickupEnabled != undefined ? selectedSchedule.pickupEnabled : true)
  }, []);

  useEffect(() => {
    const selectedInstructions = locales?.find((e)=>e.zipCode == selectedZip.key);
    setInstructions(selectedInstructions.deliveryInstructions);
    setEnablePickup(selectedInstructions.pickupEnabled != undefined ? selectedInstructions.pickupEnabled : true)
  }, [selectedZip]);

  const onChangeSelectedZip = (e) => {

  const selectedZip = props.zips?.find((zip)=>e.target.value == zip.key);
    setSelectedZip(selectedZip)
  }
  const handleInputChange = event => {
    event.persist();
    setInstructions(event.target.value);
  };
  const setSchedule = async () => {
    let currentSelectedSchedule = await timeWindowRef.current.setSchedule();
  };
  const updateZipSchedule = async (zip) => {
    const localeData = {
      zipCode: selectedZip.key,
      deliverySchedule: zip.deliverySchedule,
      deliveryInstructions: instructions || "",
      pickupEnabled: enablePickup
    }
    props.updateZipSchedule(localeData)
  }

  const toggleEnablePickup = value => {
    setEnablePickup(!enablePickup)
  };

  return (
    <Form>
      <div className="time_scheduling_header">
        {i18nstring(strings.MANAGE_PICKUPS)}
      </div>
      <Picker
        title={i18nstring(strings.PICK_ZIP_CODE)}
        items={props.zips}
        selectedItem={selectedZip.key}
        onChange={onChangeSelectedZip}
      />
      <br/>
      <div className="offers_checkbox_label_container" style={{ alignItems:'center' }}>
          <div className="offers_checkbox_label" style={{ width:'auto', marginRight: 10 }}>
            {i18nstring(strings.ENABLE_PICKUP)}
          </div>
          <Checkbox
            name={i18nstring(strings.ENABLE_PICKUP)}
            checked={enablePickup}
            onChange={toggleEnablePickup}
          />
        </div>
      <br/>
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.DELIVERY_INSTRUCTIONS)}
      />
      <FormSectionLabel
        sectionLabel={i18nstring(strings.DELIVERY_INSTRUCTIONS_NOTE)}
      />
      <FormSectionLargeInput
        name="instructions"
        placeholder={i18nstring(strings.DELIVERY_INSTRUCTIONS_GARDENER)}
        onChange={handleInputChange}
        width={"100%"}
        maxChar={600}
        value={instructions ?? ""}
      />
      <div className="button_right_container">
        <div className="time_scheduling_header">
          {i18nstring(strings.ADD_EDIT_SCHEDULES)}
        </div>
        <div className="time_scheduling_sub_header">
          <div className="time_scheduling_note">
            {i18nstring(strings.TIME_SCHEDULES_PICKUP_NOTE)}
          </div>
        </div>
        <div>
          <PickupTimeWindow
            deliverySchedule={selectedScheduleDelivery}
            updateZipSchedule={updateZipSchedule}
            ref={timeWindowRef}
          />
        </div>
        <div className="right_delete_button_container">
              <Button onClick={setSchedule}>
                {i18nstring(strings.SAVE_CHANGES)}
              </Button>
        </div>
      </div>
    </Form>
  );
}

export function ManageAddressAndSchedules(props) {
  const timeWindowRef = useRef(null);
  const [name, setName] = useState();
  const [inputs, setInputs] = useState();
  const [validations, setValidation] = useState({
    city: true,
    phoneNumber: true,
    state: true,
    streetAddress1: true,
    zip: true
  });

  const scheduleId = props.loadedAddresses[props.selectedAddress.key]?.schedule;
  const currentSchedule = props.loadedSchedules.filter(schedule => schedule.id === scheduleId);

  const selectedSchedule = currentSchedule[0] ?? props.selectedSchedule;
  const selectedScheduleDelivery = currentSchedule[0]?.deliverySchedule ?? props.selectedSchedule.deliverySchedule;

  useEffect(() => {
    setInputs(props.selectedAddress.address);
    setName(props.selectedAddress.name || "");
  }, [props.selectedAddress, props.selectedAddress.name]);

  const handleInputChange = event => {
    event.persist();
    setValidation(validations => ({
      ...validations,
      [event.target.name]: true
    }));
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    });
  };
  const setAddress = async () => {
    let currentSelectedSchedule = await timeWindowRef.current.setSchedule();

    if (props.selectedAddress.address.city === "") {
      props.createAddress({ ...inputs, name, currentSelectedSchedule });
    } else props.updateAddress({ ...inputs, name });
  };

  const onNameChange = event => {
    setName(event.target.value);
  };

  const isContactAddress = props.selectedAddressId === Keys.CONTACT;
  const isNewAddress = props.selectedAddressId === Keys.NEW_ITEM;

  if (!inputs) return null;

  return (
    <Form>
      <div className="time_scheduling_header">
        {i18nstring(strings.ADD_EDIT_ADDRESSES)}
      </div>
      <div className="time_scheduling_sub_header">
        <div className="time_scheduling_note">
          {i18nstring(strings.TIME_ADDRESSES_NOTE1)}
        </div>
        <div className="time_scheduling_note">
          {i18nstring(strings.TIME_ADDRESSES_NOTE2)}
        </div>
        <div className="time_scheduling_note">
          {i18nstring(strings.TIME_ADDRESSES_NOTE3)}
        </div>
      </div>
      <Picker
        title={i18nstring(strings.PICK_ADDRESS)}
        items={props.loadedAddresses}
        selectedItem={props.selectedAddress.key}
        onChange={props.onChangeSelectedAddress}
      />
      {props.selectedAddress.key !== 0 && (
        <input
          className={"time-window-name"}
          type="text"
          value={name}
          placeholder={i18nstring(strings.LOCATION_NAME)}
          onChange={onNameChange}
        />
      )}
      <FormField
        name="streetAddress1"
        placeholder={i18nstring(strings.STREET_ADDRESS_1)}
        onChange={handleInputChange}
        maxChar={50}
        value={inputs.streetAddress1 || ""}
        isValid={validations.streetAddress1}
        validationMessage={i18nstring(strings.STREET_ADDRESS_INVALID)}
      />
      <div className="user_account_row">
        <FormField
          name="phoneNumber"
          placeholder={i18nstring(strings.PHONE_NUMBER)}
          onChange={handleInputChange}
          width={"80%"}
          maxChar={10}
          value={inputs.phoneNumber || ""}
          isValid={validations.phoneNumber}
          validationMessage={i18nstring(strings.PHONE_NUMBER_INVALID)}
        />
        <FormField
          name="city"
          placeholder={i18nstring(strings.CITY)}
          onChange={handleInputChange}
          width={"80%"}
          maxChar={30}
          value={inputs.city || ""}
          isValid={validations.city}
          validationMessage={i18nstring(strings.CITY_INVALID)}
        />
        <FormField
          name="state"
          placeholder={i18nstring(strings.STATE)}
          onChange={handleInputChange}
          width={"35%"}
          maxChar={2}
          value={inputs.state || ""}
          isValid={validations.zip}
          validationMessage={i18nstring(strings.ZIP_INVALID)}
        />
        <FormField
          name="zip"
          placeholder={i18nstring(strings.ZIP_CODE)}
          onChange={handleInputChange}
          width={"50%"}
          maxChar={5}
          value={inputs.zip || ""}
          isValid={validations.state}
          validationMessage={i18nstring(strings.STATE_INVALID)}
        />
      </div>
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.DELIVERY_INSTRUCTIONS)}
      />
      <FormSectionLabel
        sectionLabel={i18nstring(strings.DELIVERY_INSTRUCTIONS_NOTE)}
      />
      <FormSectionLargeInput
        name="instructions"
        placeholder={i18nstring(strings.DELIVERY_INSTRUCTIONS_GARDENER)}
        onChange={handleInputChange}
        width={"100%"}
        maxChar={600}
        value={inputs.instructions ?? ""}
      />
      <div className="button_right_container">
        {((props.selectedAddressId !== '0') && (props.selectedAddressId !== '-1')) && (
          <div className="right_delete_button_container">
            <Button color={Colors.brightRed} onClick={props.deleteAddress}>
              {i18nstring(strings.DELETE_ADDRESS)}
            </Button>
          </div>
        )}

        <div className="time_scheduling_header">
          {i18nstring(strings.ADD_EDIT_SCHEDULES)}
        </div>
        <div className="time_scheduling_sub_header">
          <div className="time_scheduling_note">
            {i18nstring(strings.TIME_SCHEDULES_NOTE3)}
          </div>
        </div>
        <div>
          <TimeWindow
            isDefaultSchedule={props.selectedScheduleId === Keys.DEFAULT}
            isNewSchedule={props.selectedScheduleId === Keys.NEW_ITEM}
            selectedSchedule={selectedSchedule}
            deliverySchedule={selectedScheduleDelivery}
            createNewSchedule={props.createNewSchedule}
            selectedAddress={props.selectedAddress}
            updateSchedule={props.updateSchedule}
            deleteSchedule={props.deleteSchedule}
            ref={timeWindowRef}
          />
        </div>
        <div className="right_delete_button_container">
          {
            isNewAddress ?
              <Button disabled={!isContactAddress && !name} onClick={setAddress}>
                {i18nstring(strings.CREATE)}
              </Button>
              :
              <Button disabled={!isContactAddress && !name} onClick={setAddress}>
                {i18nstring(strings.SAVE_CHANGES)}
              </Button>
          }
        </div>
      </div>
    </Form>
  );
}
