import React from "react";
import { useSelector } from "react-redux";
import { Modal, Icon } from "semantic-ui-react";

import "./OverlayStyles.css";

export default function Loading(props) {
  var visible = useSelector(
    state => state.dialog.loading || state.onboarding.isLoading
  );

  return (
    <Modal basic open={visible} size="small" closeOnDimmerClick={false}>
      <Modal.Content className="centeredLoading">
        <Icon loading name="spinner" size="huge" />
      </Modal.Content>
    </Modal>
  );
}
