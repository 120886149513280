export const Colors = {
  black: "#000",
  white: "#fff",
  faded: "#808080",
  border: "#dbd5cf",
  neutralGrey: "#b0b0b0",

  brightRed: "#EB3223",
  lightGrey: "#9b9b9b",
  darkGrey: "#7a7a7a",
  autumnOrange: "#d9912a",
  dirtyOrange: "#c57809",
  brownGrey: "#a1a1a1",
  greyishBrown: "#4a4a4a",
  facebookBlue: "#3A599D",
  fadedOrange: "#eca949",
  greyishTeal: "#5db99d",
  hospitalGreen: "#9bd1a2",
  pale: "#edebe9",
  pine: "#386439",
  transparentWhite: "rgba(255, 255, 255, .75)"
};
