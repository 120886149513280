import {
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ERROR,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  CLEAR_DIALOG
} from "../actions/Types";
import Immutable from "seamless-immutable";

// initial state

const initialState = Immutable({
  loading: false,
  message: null
});

// reducers

const setLoading = (state, loading) => {
  return Immutable.merge(state, { loading });
};

const setMessage = (state, action) => {
  const { message } = action;
  return Immutable.merge(state, { message });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return setLoading(state, true);
    case HIDE_LOADING:
      return setLoading(state, false);
    case SHOW_ERROR:
    case SHOW_MESSAGE:
      return setMessage(state, action);
    case HIDE_MESSAGE:
      return setMessage(state, { message: null });
    case CLEAR_DIALOG:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
