import Immutable from "seamless-immutable";

import { SET_BADGE, CLEAR_BADGES, LOGOUT, IMPERSONATE_USER, STOP_IMPERSONATE_USER } from "../actions/Types";

// initial state

const initialState = Immutable({
  items: 0,
  chat: 0,
  history: 0
});

// reducers

const setBadge = (state, action) => {
  const { tab, add, count } = action;
  let newState = { ...state };
  switch (tab) {
    case "CHAT":
      newState.chat = add ? newState.chat + count : count;
      break;
      break;
  }

  return Immutable.merge(state, newState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BADGE:
      return setBadge(state, action);
    case IMPERSONATE_USER:  
    case STOP_IMPERSONATE_USER:
    case CLEAR_BADGES:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
