import { BaseApi } from "./Base";

class EmailApi extends BaseApi {
  sendEmailToUser(userIds, body, subject) {
    return super.postApiRequest(`/mail/`, {
      to: userIds,
      body,
      subject
    });
  }
  sendEmailToUserGroup(userGroup, body, subject) {
    return super.postApiRequest(`/mail/role`, {
      to: userGroup,
      body,
      subject
    });
  }
}

const emailApi = new EmailApi();

export default emailApi;
