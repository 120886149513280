import { BaseApi } from './Base'

class UserSettingsApi extends BaseApi {
  getSettings() {
    return super.getApiRequest('/user-settings')
  }

  createSettings(settings) {
    return super.postApiRequest('/user-settings', settings)
  }

  updateSettings(settings) {
    return super.putApiRequest('/user-settings', settings)
  }
}

const userSettingsApi = new UserSettingsApi()

export default userSettingsApi
