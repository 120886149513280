import React, { Component } from "react";
import { connect } from "react-redux";
// Components
import {
  ManageAddressAndSchedules,
  ManagePickupAndSchedules,
  ManageZips
} from "../molecules/TimeWindow";
// Redux
// Themes
import "./PageStyles.scss";
import i18nstring, { strings } from "../../i18n";
import {
  createAddress,
  createSchedule,
  deleteAddress,
  deleteSchedule,
  getSchedulesAndAddresses,
  setAddresses,
  setAddressToCreate,
  setAddressToZips,
  setSchedules,
  setScheduleToCreate,
  setScheduleToZips,
  updateAddress,
  updateSchedule,
  updateSelectedAddressId,
  updateSelectedScheduleId
} from "../../redux/actions/TimeWindow";
import Keys from "../../constants/Keys";
import { DialogueModal } from "../organisms/Modal";
import { getSubscriptions } from "../../redux/actions/Subscription";
import ItemDayScheduled from "../../Shared/Constants/Item";
import Immutable from "seamless-immutable";
import { getLocales, updateLocale } from "../../redux/actions/Onboarding";

const initialDays = {};
Object.keys(ItemDayScheduled).forEach(day => {
  initialDays[day] = false;
});

class TimeWindows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteSchedule: true,
      showConfirmDeletionModal: false,
      isAdmin: props.user && props.user.roles.includes("ADMIN"),
      fetchingLocales: false
    };
  }

  componentDidMount() {
    
    this.props.attemptGetSchedulesAndAddresses();

    if (!this.state.isAdmin) {
      this.props.attemptGetSubscriptions();
    }
  }

  componentDidUpdate(prevProps){
    if(!this.state.fetchingLocales && prevProps?.subscriptions?.length > 0){
      this.setState({fetchingLocales: true},()=>{
        this.props.getLocales(this.props.subscriptions?.map(zip => zip.id))
      })
    }
  }

  //#region Schedules
  onChangeSelectedSchedule = scheduleIndex => {
    let newSelection = scheduleIndex.target.value;
    this.props.attemptUpdateSelectedSchedule(newSelection);
    if (newSelection === Keys.NEW_ITEM) {
      this.props.attemptSetScheduleToCreate();
    }
  };

  createNewSchedule = async schedule => {
    return await this.props.attemptCreateSchedule(schedule);
  };

  updateZipSchedule = locale => {
    const allZips = this.props.subscriptions?.map(zip => zip.id);
    if(allZips?.length > 0 && locale)
      this.props.attemptUpdateLocale(allZips,locale);
  }

  updateSchedule = schedule => {
    let mutableArray = null;
    try {
      mutableArray = Immutable.asMutable(this.props.selectedAddress.zips);
    } catch (error) {} 
    this.props.attemptUpdateSchedule(schedule,mutableArray);
  };

  onSubmitUpdateZipSchedule = (scheduleId, scheduleIdx, zips) => {
    zips = zips.filter(zip => zip.checked).map(zip => zip.key);
    this.props.attemptSetScheduleToZips(scheduleId, scheduleIdx, zips);
  };
  //#endregion Schedules

  //#region Addresses
  onChangeSelectedAddress = addressIndex => {
    let newSelection = addressIndex.target.value;
    let finalSchedule = 0

    this.props.attemptUpdateSelectedAddressId(newSelection);
    this.props.attemptUpdateSelectedSchedule(newSelection);

    if (newSelection === Keys.NEW_ITEM) {
      this.props.attemptSetAddressToCreate();
      this.props.attemptSetScheduleToCreate();
    } else {
      const currentAddress = this.props.loadedAddresses[addressIndex.target.value].schedules;
      const currentSchedule = this.props.loadedSchedules.filter(el => el.id === currentAddress)
      finalSchedule = currentSchedule[0]?.key;
    }
  };

  createAddress = address => {
    this.props.attemptCreateAddress(address);
  };

  updateAddress = address => {
    this.props.attemptUpdateAddress(this.props.selectedAddress.id, address);
  };


  onSubmitUpdateZipAddress = (addressId, addressIdx, zips) => {
    zips = zips.filter(zip => zip.checked).map(zip => zip.key);
    this.props.attemptSetAddressToZips(addressId, addressIdx, zips);
  };
  //#endregion Addresses

  delete = () => {
    this.setState({ showConfirmDeletionModal: false });
    if(this.props.selectedAddress.schedule){
      this.props.attemptDeleteSchedule(this.props.selectedAddress.schedule);
      this.props.attemptDeleteAddress(this.props.selectedAddress.id);
    }
  };

  render() {
    const selectedSchedule = this.props.selectedSchedule;
    const selectedAddress = this.props.selectedAddress;

    return (
      <div className="main_container_section">
        {this.state.showConfirmDeletionModal && (
          <DialogueModal
            open={this.state.showConfirmDeletionModal}
            title={
              this.state.deleteSchedule
                ? i18nstring(strings.DELETE_SCHEDULE)
                : i18nstring(strings.DELETE_ADDRESS)
            }
            message={
              this.state.deleteSchedule
                ? i18nstring(strings.DELETE_SCHEDULE_CONFIRM_MESSAGE)
                : i18nstring(strings.DELETE_ADDRESS_CONFIRM_MESSAGE)
            }
            cancelModal={() =>
              this.setState({ showConfirmDeletionModal: false })
            }
            toggleModal={this.delete}
          />
        )}
        <div className="pickup_window">
          <div className="pickup_window_content">
            <ManageZips
              zips={this.props.subscriptions.map(zip => zip.id)}
              loadedAddresses={this.props.loadedAddresses}
              loadedSchedules={this.props.loadedSchedules}
              onSubmitUpdateZipSchedule={this.onSubmitUpdateZipSchedule}
              onSubmitUpdateZipAddress={this.onSubmitUpdateZipAddress}
            />
          </div>
        </div>
        <div className="add_edit_window">
          <div className="pickup_window add_edit_address_window">
            <div className="pickup_window_content">
              {selectedAddress && (
                <ManageAddressAndSchedules
                  selectedAddress={selectedAddress}
                  selectedAddressId={this.props.selectedAddressId}
                  loadedAddresses={[
                    ...this.props.loadedAddresses,
                    {
                      key: Keys.NEW_ITEM,
                      title: i18nstring(strings.CREATE_ADDRESS)
                    }
                  ]}
                  onChangeSelectedAddress={this.onChangeSelectedAddress}
                  createAddress={this.createAddress}
                  updateAddress={this.updateAddress}
                  deleteAddress={() =>
                    this.setState({
                      deleteSchedule: false,
                      showConfirmDeletionModal: true
                    })
                  }
                  selectedSchedule={selectedSchedule}
                  selectedScheduleId={this.props.selectedScheduleId}
                  loadedSchedules={[
                    ...this.props.loadedSchedules,
                    {
                      key: Keys.NEW_ITEM,
                      title: i18nstring(strings.CREATE_SCHEDULE)
                    }
                  ]}
                  onChangeSelectedSchedule={this.onChangeSelectedSchedule}
                  createNewSchedule={this.createNewSchedule}
                  updateSchedule={this.updateSchedule}
                  deleteSchedule={() =>
                    this.setState({
                      deleteSchedule: true,
                      showConfirmDeletionModal: true
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="add_edit_window">
          <div className="pickup_window add_edit_address_window">
            <div className="pickup_window_content">
              {(selectedAddress && this.props.locales?.length > 0) && (
                <ManagePickupAndSchedules
                  locales={this.props.locales}
                  zips={this.props.subscriptions.map(zip => ({key: zip.id, title: zip.id}))}
                  updateZipSchedule={this.updateZipSchedule}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.account.user,
  subscriptions: state.subscription.subscriptions,
  selectedSchedule: state.timeWindow.selectedSchedule,
  selectedScheduleId: state.timeWindow.selectedScheduleId,
  selectedAddress: state.timeWindow.selectedAddress,
  selectedAddressId: state.timeWindow.selectedAddressId,
  loadedSchedules: state.timeWindow.loadedSchedules,
  loadedAddresses: state.timeWindow.loadedAddresses,
  locales: state.onboarding.locales
});

const mapDispatchToProps = dispatch => {
  return {
    attemptGetSubscriptions: () => dispatch(getSubscriptions()),
    attemptGetSchedulesAndAddresses: () => dispatch(getSchedulesAndAddresses()),
    attemptSetScheduleToCreate: () => dispatch(setScheduleToCreate()),
    attemptUpdateSelectedSchedule: selectedScheduleId =>
      dispatch(updateSelectedScheduleId(selectedScheduleId)),
    attemptCreateSchedule: schedule => dispatch(createSchedule(schedule)),
    attemptUpdateSchedule: (schedule, zips) =>
      dispatch(updateSchedule(schedule, zips)),
    attemptDeleteSchedule: scheduleId => dispatch(deleteSchedule(scheduleId)),
    attemptSetSchedules: schedules => dispatch(setSchedules(schedules)),
    attemptSetScheduleToZips: (scheduleId, scheduleIdx, zips) =>
      dispatch(setScheduleToZips(scheduleId, scheduleIdx, zips)),
    attemptSetAddressToCreate: () => dispatch(setAddressToCreate()),
    attemptUpdateSelectedAddressId: selectedAddressId =>
      dispatch(updateSelectedAddressId(selectedAddressId)),
    attemptCreateAddress: address => dispatch(createAddress(address)),
    attemptDeleteAddress: addressId => dispatch(deleteAddress(addressId)),
    attemptSetAddresses: addresses => dispatch(setAddresses(addresses)),
    attemptUpdateAddress: (addressId, address) =>
      dispatch(updateAddress(addressId, address)),
    attemptSetAddressToZips: (addressId, addressIdx, zips) =>
      dispatch(setAddressToZips(addressId, addressIdx, zips)),
    getLocales: zips => dispatch(getLocales(zips)),
    attemptUpdateLocale: (zips, locale) => dispatch(updateLocale(zips, locale))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeWindows);
