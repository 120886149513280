import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// Config
import { configureStore } from "./redux";
import FirebaseConfig from "./configs/firebase/config";
// Components
import Navigation from "./navigation/Routes";
import Loading from "./components/overlays/Loading";
import Message from "./components/overlays/Message";
// Firebase
import firebase from "firebase/compat/app";
// Themes
import "./styles/global-styles.scss";
import "semantic-ui-css/semantic.min.css";
import { getCurrentLocale, SupportedLocales } from "./i18n";
import api from "./api";

const { store, persistor } = configureStore();

export let currentLocale = SupportedLocales.DEFAULT;

function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(FirebaseConfig);
  }

  useEffect(() => {
    async function updateCurrentLocale() {
      currentLocale = await getCurrentLocale();
      api.init();
    }
    updateCurrentLocale();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Navigation />
        <Loading />
        <Message />
      </PersistGate>
    </Provider>
  );
}

export default App;
