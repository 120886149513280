/* ACCOUNT */
export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const GET_SURVEY_RESPONSES = "GET_SURVEY_RESPONSES";
export const STOP_IMPERSONATE_USER = "STOP_IMPERSONATE_USER";
export const UPDATE_ROUTES = "UPDATE_ROUTES";

/* ONBOARDING */
export const CLEAR_ITEMS = "CLEAR_ITEM";
export const LOGOUT = "LOGOUT";
export const SET_ADDRESS_RESULTS = "SET_ADDRESS_RESULTS";
export const SET_AUTH_REFRESH = "SET_AUTH_REFRESH";
export const SET_DELIVERY_SCHEDULE = "SET_DELIVERY_SCHEDULE";
export const SET_OFFERS = "SET_OFFERS";
export const SET_PARTNER = "SET_PARTNER";
export const SET_LOCALE = "SET_LOCALE";
export const SET_LOCALES = "SET_LOCALE";
export const SET_ZIP_CODES = "SET_ZIP_CODES";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_AUTH = "UPDATE_USER_AUTH";
export const UPDATE_SUBSCRIPTIONS = "UPDATE_SUBSCRIPTIONS";

/* SHARED */
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SHOW_ERROR = "SHOW_ERROR";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const HIDE_LOADING = "HIDE_LOADING";
export const CLEAR_DIALOG = "CLEAR_DIALOG";

/* ITEMS */
export const UPDATE_ITEMS = "UPDATE_ITEMS";
export const UPDATE_ITEM_HISTORY = "UPDATE_ITEM_HISTORY";

/* Users */
export const UPDATE_PRODUCERS = "UPDATE_PRODUCERS";
export const UPDATE_COURIERS = "UPDATE_COURIERS";
export const UPDATE_RECIPIENTS = "UPDATE_RECIPIENTS";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";

/* Time Windows */
export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_SCHEDULES = "SET_SCHEDULES";
export const SET_SCHEDULE_TO_ZIPS = "SET_SCHEDULE_TO_ZIPS";
export const UPDATE_SELECTED_SCHEDULE_ID = "UPDATE_SELECTED_SCHEDULE_ID";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const SET_SCHEDULES_WITH_NEW_SCHEDULE =
  "SET_SCHEDULES_WITH_NEW_SCHEDULE";
export const SET_ADDRESSES = "SET_ADDRESSES";
export const SET_ADDRESS_TO_ZIPS = "SET_ADDRESS_TO_ZIPS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_SELECTED_ADDRESS = "UPDATE_SELECTED_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_SELECTED_ADDRESS_ID = "UPDATE_SELECTED_ADDRESS_ID";
export const SET_ADDRESSES_WITH_NEW_ADDRESS = "SET_ADDRESSES_WITH_NEW_ADDRESS";

// chat actions
export const UPDATE_CHATS = 'UPDATE_CHATS'
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES'
export const SET_CHAT = 'SET_CHAT'
export const CLEAR_CHAT = 'CLEAR_CHAT'
export const CHAT_BUSY = 'CHAT_BUSY'
export const CHAT_FREE = 'CHAT_FREE'
export const RESET_CHAT = 'RESET_CHAT'

// badge handling
export const SET_BADGE = 'SET_BADGE'
export const CLEAR_BADGES = 'CLEAR_BADGES'