import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";

import { HIDE_MESSAGE } from "../../redux/actions/Types";

import "./OverlayStyles.css";

export default function Message(props) {
  var dispatch = useDispatch();
  var message = useSelector(state => state.dialog.message);

  return (
    <Modal
      open={!!message}
      size="tiny"
      content={message}
      onClose={() => {
        dispatch({ type: HIDE_MESSAGE });
      }}
    />
  );
}
