import { SET_BADGE, CLEAR_BADGES } from "./Types"

export function setBadge(tab, add, count) {
  return function(dispatch, getState) {
    dispatch({ type: SET_BADGE, tab, add, count })
  }
}

export function clearBadges(tab) {
  return function(dispatch, getState) {
    switch (tab) {
      case "CHAT":
        dispatch({ type: SET_BADGE, tab, count: 0 })
        break

      default:
        dispatch({ type: CLEAR_BADGES })
        break
    }
  }
}
