import React, { useState } from "react";
// Components
import { Header, Image, Icon, Button } from "semantic-ui-react";
import { CheckBox } from "../atoms/Images";
// Constants
import { newStatus, cellActionTypes } from "../../model/Table";
import i18nstring, { strings } from "../../i18n";
// Images
import Avatar from "../../images/icons/avatar.png";
import PlaceHolder from "../../images/icons/placeHolder.png";
// Themes
import "./AtomStyles.scss";
import Popup from "reactjs-popup";

export function ApprovalCellNonProfit({
  changeApproval,
  impersonate,
  isApproved,
  userId
}) {
  const impersonateUser = () => {
    impersonate(userId);
  };
  const approveUser = event => {
    if (event) {
      event.preventDefault();
    }
    changeApproval(i18nstring(strings.APPROVED), [userId]);
  };
  return isApproved ? (
    <div className="impersonate_cell" onClick={impersonateUser}>
      {i18nstring(strings.VIEW_AS)}
    </div>
  ) : (
    <div className="table_cell_action" onClick={approveUser}>
      <Icon name="check" color="green" size="large" />
    </div>
  );
}

export function ApprovalCell({ changeApproval, isApproved, userId }) {
  const approveUser = event => {
    if (event) {
      event.preventDefault();
    }
    changeApproval(i18nstring(strings.APPROVED), [userId]);
  };
  return isApproved ? null : (
    <div className="table_cell_action" onClick={approveUser}>
      <Icon name="check" color="green" size="large" />
    </div>
  );
}
export function ConfirmationPopup({changeApproval,userId}){
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const denyUser = event => {
    if (event) {
      event.preventDefault();
    }
    changeApproval(i18nstring(strings.PENDING), [userId]);
  };
  return(
    <>
      <div className="table_cell_action" onClick={()=>setOpen(o => !o)}>
        <Icon name="cancel" color="orange" size="large" />
      </div>
      <Popup open={open}  >

        <div className="modal">
          <div className="close" onClick={closeModal} >
            &times;
          </div>
          <div className="header">Deleting Account Confirmation</div>
          <div className="content">
            {" "}
            <p>This is confirmation popup. If you are sure that want to delete this account, Please choose "Delete this account" button.</p>
            <p>If you close this popup or click "Don't Delete the account", nothing happened.</p>
            <div className="deletingConfirmationBtn">
              <button className="deleteAccount" onClick={()=>{
                denyUser() ; closeModal()
              }  }>Yes, remove this account</button>
              <button className=" dontDeleteAccount zip_actions_btn" onClick={closeModal}>Don't Delete the account</button>
            </div>
          </div>
        </div>
      </Popup>
    </>

  )
}

export function DenyCell({ changeApproval, userId }) {


  const denyUser = event => {
    if (event) {
      event.preventDefault();
    }
    changeApproval(i18nstring(strings.PENDING), [userId]);
  };
  return (
      <div className="table_cell_action" onClick={denyUser}>
        <Icon name="cancel" color="orange" size="large" />
      </div>
  )
}

export function ActionCell({ approved, approveCourier, id, type }) {
  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
  };

  switch (type) {
    case cellActionTypes.APPROVE:
      return approved === newStatus.APPROVED ? null : (
        <Button type="submit" onClick={handleSubmit} />
      );
    case cellActionTypes.DENY:
      return (
        <Header.Content className="action_cell">
          <Icon name="cancel" color="orange" size="large" />
        </Header.Content>
      );
    default:
      return (
        <Header.Content className="action_cell">
          <Icon
            name="ellipsis horizontal"
            size="large"
            onClick={() => console.log("open modal")}
          />
          {i18nstring(strings.MORE)}
        </Header.Content>
      );
  }
}

export function MiscCell({ label }) {
  return <div className="table_cell">{label}</div>;
}

export function RadioCell({ options, selectStatus }) {
  return options.map((option, index) => {
    const { action, active, label, unavailable } = option;
    const selectThisStatus = () => selectStatus(action);
    let cellStyle = unavailable ? "radio_cell_inactive" : "radio_cell";
    return (
      <div
        key={index}
        onClick={selectThisStatus}
        className={active ? "radio_cell_active" : cellStyle}
      >
        {label}
      </div>
    );
  });
}

export function MarkCompletedCell({ options, selectStatus }) {
  return options.map((option, index) => {
    const { action, active, label, unavailable } = option;
    const selectThisStatus = () => selectStatus(action);
    let cellStyle = unavailable
      ? "mark_completed_cell_inactive"
      : "mark_completed_cell";
    return (
      <div key={index} className={cellStyle}>
        <div
          onClick={selectThisStatus}
          className={"mark_completed_cell_button"}
        >
          {label}
        </div>
      </div>
    );
  });
}

export function EditableCell(props) {
  const { updatedWeights, handleUpdateWeights, item } = props;
  const { pickUpWeight, weight, id } = item;

  const initialWeight = pickUpWeight ? pickUpWeight : weight;
  const [newWeight, setNewWeight] = useState(initialWeight);
  const handleInputChange = event => {
    event.persist();
    setNewWeight(event.target.value);
    handleUpdateWeights({ id: item.id, updatedWeight: event.target.value });
    // changeWeight(newItem);
  };
  const weightIsUpdated = updatedWeights[id] !== undefined;
  const weightValid = parseInt(newWeight) >= 0 || newWeight === "";

  const cellStyle = {
    borderWidth: 0,
    padding: 10,
    borderLeftWidth: 0,
    color: weightIsUpdated ? "white" : null,
    backgroundColor: !weightValid ? "red" : weightIsUpdated ? "#d9912a" : null
  };
  return (
    <input style={cellStyle} onChange={handleInputChange} value={newWeight} />
  );
}

export function UserCell({ user, isSelected, selectUser }) {
  // const avatar = user.photoURL ? user.photoURL : Avatar;
  const avatar = Avatar;
  return (
    <div className="table_cell_user">
      <CheckBox isChecked={isSelected} toggleCheckBox={selectUser} />
      <div className="table_cell_image">
        <Image className="avatar_image" src={avatar} circular />
      </div>
      <div className="user_name_cell">{user.name}</div>
    </div>
  );
}

export function ItemCell({ item }) {
  const avatar = item.photoURL ? item.photoURL : PlaceHolder;
  return (
    <div className="table_cell_item">
      <div className="table_cell_image">
        <Image className="avatar_image" src={avatar} circular />
      </div>
      <div className="user_name_cell">{item.name}</div>
    </div>
  );
}
