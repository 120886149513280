import React, { useState } from "react";
import { connect } from "react-redux";
// Actions
import {
  createAccount,
  getPossibleAddresses
} from "../../../redux/actions/Onboarding";
// Components
import { ContactInfoFormContent } from "../../molecules/ContactInfoFormContent";
import { Icon } from "semantic-ui-react";
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";

function ContactInfoAccountForm(props) {
  const [isLoading, setLoading] = useState(props.isLoading);

  const createAccount = inputs => {
    const contactInfo = props.addressResults[0];
    const contact = {
      contact: {
        city: contactInfo.address.city,
        country: contactInfo.address.country,
        lat: contactInfo.coordinates.lat,
        lng: contactInfo.coordinates.lng,
        instructions: inputs.instructions,
        placeId: contactInfo.placeId,
        state: contactInfo.address.state,
        streetAddress1: contactInfo.address.streetAddress1,
        zip: contactInfo.address.zip
      },
      phoneNumber: inputs.phoneNumber
    };
    props.handleNext(contact);
  };
  const checkLocation = address => {
    setLoading(true);
    props.attemptGetAddresses(address);
  };

  return (
    <div className="modal_main">
      <div className="modal_header">{i18nstring(strings.ACCOUNT_CREATION)}</div>
      <div className="modal_content">
        <ContactInfoFormContent
          createAccount={createAccount}
          inputs={props.inputs}
          addressResults={props.addressResults}
          checkLocation={checkLocation}
        />
      </div>
      <ModalMessage
        showLoading={isLoading}
        addressResults={props.addressResults}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  addressResults: state.onboarding.addressResults,
  isLoading: state.onboarding.isLoading
});

const mapDispatchToProps = dispatch => {
  return {
    attemptCreateAccount: user => dispatch(createAccount(user)),
    attemptGetAddresses: contact => dispatch(getPossibleAddresses(contact))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfoAccountForm);

function ModalMessage({ addressResults, showLoading }) {
  const icon = showLoading ? <Icon loading name="spinner" /> : null;
  if (showLoading && addressResults === null) {
    return (
      <div className="modal_message">
        {icon}
        {i18nstring(strings.WARNING_LOCATION)}
      </div>
    );
  } else if (showLoading && addressResults[0]) {
    return (
      <div className="modal_message_success">
        {i18nstring(strings.POSITIVE_LOCATION)}
      </div>
    );
  } else if (!showLoading && addressResults === null) {
    return null;
  } else {
    return (
      <div className="modal_message_error">
        {i18nstring(strings.ERROR_LOCATION)}
      </div>
    );
  }
}
