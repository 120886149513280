import { BaseApi } from "./Base";

class LocaleApi extends BaseApi {
  getLocaleInfo(zip) {
    return super.getApiRequest(`/locales/${zip}`);
  }

  getLocales(zips) {
    return super.postApiRequest("/locales", { zips });
  }

  saveWaitingUser(email, zip) {
    return super.postApiRequest("/waiting_users", { email, zip });
  }

  getPlaces(address) {
    return super.postApiRequest("/locales/geocode", address);
  }

  updateLocale(data) {
    return super.putApiRequest("/locales", data);
  }
}

const localeApi = new LocaleApi();

export default localeApi;
