import Immutable from "seamless-immutable";
import {
  UPDATE_USER,
  GET_SURVEY_RESPONSES,
  HIDE_LOADING,
  SHOW_ERROR,
  SHOW_LOADING,
  UPDATE_PRODUCERS,
  UPDATE_COURIERS,
  UPDATE_RECIPIENTS,
  UPDATE_USER_SETTINGS
} from "./Types";
import api from "../../api";
import { Roles } from "../../Shared/Constants/Roles";
import { validateURL } from "../../utils";

export function getUserGroup(userGroup) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      let result = await _getUserGroup(dispatch, getState, userGroup);

      if (result && !result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

async function _getUserGroup(dispatch, getState, userGroup) {
  try {
    let result;

    switch (userGroup) {
      case Roles.PRODUCER:
        result = await api.user.getGardeners();
        break;
      case Roles.COURIER:
        result = await api.user.getCouriers();
        break;
      case Roles.RECIPIENT:
        result = await api.user.getNonProfits();
        break;
      default:
        break;
    }

    if (result && result.success) {
      switch (userGroup) {
        case Roles.PRODUCER:
          let { gardeners } = getState().user;
          gardeners = result.data;
          dispatch({ type: UPDATE_PRODUCERS, gardeners });
          break;
        case Roles.COURIER:
          let { couriers } = getState().user;
          couriers = result.data;
          dispatch({ type: UPDATE_COURIERS, couriers });
          break;
        case Roles.RECIPIENT:
          let { nonProfits } = getState().user;
          nonProfits = result.data;
          dispatch({ type: UPDATE_RECIPIENTS, nonProfits });
          break;
        default:
          break;
      }
    } else if (result && result.error) {
      return Promise.reject(result.error);
    }
    return Promise.resolve(result);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export function getSurveyResponses() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      let result = await api.survey.getSurveyResponses();

      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      } else {
        dispatch({ type: GET_SURVEY_RESPONSES, data: result.data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function answerSurvey(response) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      let result = await api.survey.answerSurvey(response);

      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function setUserApproval(newStatus, userGroup, userIds) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      let result = await _setUserApproval(
        dispatch,
        getState,
        newStatus,
        userGroup,
        userIds
      );
      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

async function _setUserApproval(
  dispatch,
  getState,
  newStatus,
  userGroup,
  userIds
) {
  try {
    let result = await api.user.changeApproval(userIds, newStatus);

    if (result.success) {
      result = await _getUserGroup(dispatch, getState, userGroup);
      return Promise.resolve(result);
    } else {
      return Promise.resolve(result);
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export function setSelfService(isSelfService) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      let { user } = getState().account;
      user = Immutable.asMutable(user);
      user.isSelfService = isSelfService;

      let result = await api.account.updateUserAccount(user);

      if (result.success) {
        dispatch({ type: UPDATE_USER, user: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function updateUserOffers(offers, offersDisabledInstructions) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      let { user } = getState().account;
      // If offer is only dropoff, disable self service in case it was enabled
      let isSelfService =
        offers.DROPOFF && !offers.PICKUP ? false : user.isSelfService;
      
      user = Immutable.merge(user, { offers, isSelfService, offersDisabledInstructions });

      const result = await api.account.updateUserAccount(user);

      if (result.success) {
        dispatch({ type: UPDATE_USER, user: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function setWebsite(website) {
  return async function (dispatch, getState) {
    try {
      if (!validateURL(website)) {
        dispatch({ type: SHOW_ERROR, message: "Please enter a valid URL." });
        return;
      }

      dispatch({ type: SHOW_LOADING });

      let { user } = getState().account;
      user = Immutable.asMutable(user);
      user.website = website;

      let result = await api.account.updateUserAccount(user);

      if (result.success) {
        dispatch({ type: UPDATE_USER, user: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function setLogo(logoUrl) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      let { user } = getState().account;
      user = Immutable.asMutable(user);
      user.photoURL = logoUrl;

      let result = await api.account.updateUserAccount(user);

      if (result.success) {
        dispatch({ type: UPDATE_USER, user: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}


export function updateUserSettings(settings) {
  return async function (dispatch, getState) {
    try {
      let { user } = getState().account
      if (!user) return

      const result = await api.userSettings.updateSettings(settings)

      if (result.success) {
        dispatch({ type: UPDATE_USER_SETTINGS, settings })
      } else {
        dispatch({ type: SHOW_ERROR, message: 'Error while trying to update user settings' })
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export function getUserSettings() {
  return async function (dispatch, getState) {
    try {
      let { user } = getState().account
      if (!user) return

      const result = await api.userSettings.getSettings()

      if (result.success) {
        dispatch({ type: UPDATE_USER_SETTINGS, settings: result.data })
      }
    } catch (e) {
      console.error(e)
    }
  }
}
