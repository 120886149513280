export const months = [
  {key: -1, title: "Select"},
  { key: 0, title: "January" },
  { key: 1, title: "February" },
  { key: 2, title: "March" },
  { key: 3, title: "April" },
  { key: 4, title: "May" },
  { key: 5, title: "June" },
  { key: 6, title: "July" },
  { key: 7, title: "August" },
  { key: 8, title: "September" },
  { key: 9, title: "October" },
  { key: 10, title: "November" },
  { key: 11, title: "December" }
];
