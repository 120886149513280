import { ApiResult, BaseApi } from './Base'

class ChatApi extends BaseApi {
  createChat(newChat) {
    return super.postApiRequest('/chats', newChat)
  }

  sendMessage(id, message) {
    return super.postApiRequest(`/chats/${id}/messages`, message)
  }

  getUserChat(userId, cursor) {
    return super.getApiRequest(`/chats/user/${userId}`, cursor)
  }

  getChat(id, cursor) {
    return super.getApiRequest(`/chats/${id}`, cursor)
  }

  getChats(cursor) {
    return super.getApiRequest('/chats', cursor)
  }

  getMessages(id, cursor) {
    return super.getApiRequest(`/chats/${id}/messages`, cursor)
  }
}

const chatApi = new ChatApi()

export default chatApi
