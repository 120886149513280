exports.ApprovalStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
}

exports.Partner = {
  NONE: '',
  CSU: 'CSU',
}
