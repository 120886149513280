import {
  HIDE_LOADING,
  LOGOUT,
  SET_ADDRESS_RESULTS,
  SET_DELIVERY_SCHEDULE,
  SET_LOCALES,
  SET_OFFERS,
  SET_PARTNER,
  SET_ZIP_CODES,
  SHOW_LOADING,
  STOP_IMPERSONATE_USER,
  UPDATE_USER_AUTH
} from "../actions/Types";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  addressResults: null,
  userAuth: null,
  isLoading: false,

  deliverySchedule: {},
  locales: [],
  zipCodes: [],
  offers: { DROPOFF: false, PICKUP: false },
  partner: ""
});

// reducers
const setIsLoading = (state, isLoading) => {
  return Immutable.merge(state, { isLoading: isLoading });
};

const setAddressResults = (state, action) => {
  const { results } = action;
  return Immutable.merge(state, { addressResults: results, isLoading: false });
};

const updateUserAuth = (state, action) => {
  const { userAuth } = action;
  return Immutable.merge(state, {
    userAuth,
    isLoading: false
  });
};

const setDeliverySchedule = (state, action) => {
  const { deliverySchedule } = action;
  return Immutable.merge(state, { deliverySchedule });
};

const setOffers = (state, action) => {
  const { offers } = action;
  return Immutable.merge(state, { offers });
};

const setPartner = (state, action) => {
  const { partner } = action;
  return Immutable.merge(state, { partner });
};

const setZipCodes = (state, action) => {
  const { zipCodes } = action;
  return Immutable.merge(state, { locales: [], zipCodes });
};

const setLocales = (state, action) => {
  const { locales } = action;
  return Immutable.merge(state, { locales });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADDRESS_RESULTS:
      return setAddressResults(state, action);
    case UPDATE_USER_AUTH:
      return updateUserAuth(state, action);
    case SET_DELIVERY_SCHEDULE:
      return setDeliverySchedule(state, action);
    case SET_OFFERS:
      return setOffers(state, action);
    case SET_PARTNER:
      return setPartner(state, action);
    case SET_LOCALES:
      return setLocales(state, action);
    case SET_ZIP_CODES:
      return setZipCodes(state, action);
    case SHOW_LOADING:
      return setIsLoading(state, true);
    case HIDE_LOADING:
      return setIsLoading(state, false);
    case STOP_IMPERSONATE_USER:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
