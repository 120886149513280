import React, { useState } from "react";
import { Button, Icon, Form, Modal } from "semantic-ui-react";
//Components
import { LargeButton } from "../../atoms/Button";
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";
import { Colors } from "../../../constants/Styles";

const modalStyle = {
  maxWidth: 680
};

export function LoginForm(props) {
  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  });
  const [modalOpen, toggleModal] = useState(props.errorMessage !== null);

  const { label } = props;
  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    props.clearError();
    props.login(inputs);
    toggleModal(true);
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  const handleOpen = () => {
    props.clearError();
    toggleModal(!modalOpen);
  };
  const handleClose = () => toggleModal(!modalOpen);
  const forgotPassword = () => {
    props.clearError();
    props.resetPassword(inputs.email);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      style={modalStyle}
      trigger={<LargeButton onClick={handleOpen}>{label}</LargeButton>}
    >
      <Modal.Header>{i18nstring(strings.LOGIN)}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>{i18nstring(strings.EMAIL)}</label>
            <input
              name="email"
              placeholder={i18nstring(strings.EMAIL)}
              onChange={handleInputChange}
              value={inputs.email}
            />
          </Form.Field>
          <Form.Field>
            <label>{i18nstring(strings.PASSWORD)}</label>
            <input
              name="password"
              placeholder={i18nstring(strings.PASSWORD)}
              onChange={handleInputChange}
              value={inputs.password}
              type="password"
            />
          </Form.Field>
          <div className="login_bottom_container">
            <div className="social_container">
              {/* <Image
                src={Facebook}
                className="social_image"
                onClick={props.loginFacebook}
              />
              <Image src={Google} className="social_image" />
              <Image src={Twitter} className="social_image" /> */}
              <p
                style={{ color: Colors.facebookBlue }}
                onClick={forgotPassword}
              >
                {i18nstring(strings.FORGOT_PASSWORD)}
              </p>
            </div>
            <Button
              type="submit"
              onClick={handleSubmit}
              loading={props.isLoading}
            >
              {i18nstring(strings.SUBMIT)}
            </Button>
          </div>
        </Form>
      </Modal.Content>
      <ModalMessage
        errorMessage={props.errorMessage}
        showLoading={props.isLoading}
      />
    </Modal>
  );
}

function ModalMessage({ errorMessage, showLoading }) {
  const icon = showLoading ? <Icon loading name="spinner" /> : null;
  if (errorMessage === i18nstring(strings.BAD_EMAIL_FORMAT)) {
    errorMessage = i18nstring(strings.EMAIL_INVALID);
  }
  if (showLoading && errorMessage === null) {
    return (
      <div className="modal_message">
        {icon}
        Loading...
      </div>
    );
  } else if (showLoading && errorMessage === null) {
    return (
      <div className="modal_message_success">
        {i18nstring(strings.POSITIVE_LOCATION)}
      </div>
    );
  } else if (!showLoading && errorMessage === null) {
    return null;
  } else {
    return <div className="modal_message_error">{errorMessage}</div>;
  }
}
