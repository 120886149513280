import { BaseApi } from "./Base";
import { Roles } from "../Shared/Constants/Roles";

class UserApi extends BaseApi {
  getCouriers() {
    return super.getApiRequest("/users/others", {
      role: Roles.COURIER
    });
  }
  getGardeners() {
    return super.getApiRequest("/users/others", {
      role: Roles.PRODUCER
    });
  }
  getNonProfits() {
    return super.getApiRequest("/users/others", {
      role: Roles.RECIPIENT
    });
  }
  changeApproval(userIds, newStatus) {
    return super.postApiRequest(`/users/approve`, {
      users: userIds,
      newStatus: newStatus
    });
  }
  reassignZipCodes(userId, newOperator, zipCode) {
    return super.postApiRequest(`/users/reassignZipcode`, {
      userId, newOperator, zipCode

    });
  }
  newWaitingUser(zip,userId) {
    return super.postApiRequest("/newWaitingUser", { zip, userId });
  }
}

const userApi = new UserApi();

export default userApi;
