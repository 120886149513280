import { BaseApi } from "./Base";

class SubscriptionApi extends BaseApi {
  subscribe(zipCodes) {
    return super.postApiRequest(`/subscriptions`, {
      zipCodes
    });
  }

  getSubscriptions() {
    return super.getApiRequest(`/subscriptions`);
  }

  // TODO: Review for deletion
  updateSubscription(deliverySchedule) {
    return super.putApiRequest(`/subscriptions`, { deliverySchedule });
  }

  unsubscribe(id) {
    return super.deleteApiRequest(`/subscriptions/${id}`);
  }
  getUserSubscriptions(userId) {
    return super.getApiRequest(`/subscriptions`, {userId});
  }
}

const subscriptionApi = new SubscriptionApi();

export default subscriptionApi;
