import React from "react";
// Theme
import "./AtomStyles.scss";

export const Picker = props => {
  return (
    <div className="picker_container">
      <label htmlFor="picker">{props.title}: </label>
      <select
        name="picker"
        id="picker"
        value={props.selectedItem}
        onChange={props.onChange}
      >
        {props.items.map(item => (
          <option key={item.key} value={item.key}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};
