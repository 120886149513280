import api from "../../api";
// Actions
import { initialize } from "./Account";
// Constants
import i18nstring, { strings } from "../../i18n";
// Models
// Transforms
import * as transforms from "../../transforms/Account";
// Types
import {
  SET_ADDRESS_RESULTS,
  UPDATE_USER_AUTH,
  SET_DELIVERY_SCHEDULE,
  SET_LOCALES,
  SET_ZIP_CODES,
  UPDATE_USER,
  UPDATE_SUBSCRIPTIONS,
  SHOW_MESSAGE,
  SHOW_ERROR,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_OFFERS,
  SET_PARTNER
} from "./Types";
import ErrorCodes from "../../api/ErrorCodes";

export function getPossibleAddresses(address) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      dispatch({ type: SET_ADDRESS_RESULTS, results: null });

      const result = await api.locale.getPlaces(address);

      if (result.success) {
        dispatch({ type: SET_ADDRESS_RESULTS, results: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function getLocales(zips, silent = false, clear = true) {
  return async function (dispatch, getState) {
    try {
      if (clear) dispatch({ type: SET_LOCALES, locales: [] });
      if (!silent) dispatch({ type: SHOW_LOADING });

      let { user } = getState().account;
      zips = zips || [user.contact.zip];

      const result = await api.locale.getLocales(zips);

      if (result.success) {
        dispatch({ type: SET_LOCALES, locales: result.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!silent) dispatch({ type: HIDE_LOADING });
    }
  };
}


export function updateLocale(zips, data, silent = false, clear = true) {
  return async function (dispatch, getState) {
    try {
      if (!silent) dispatch({ type: SHOW_LOADING });
      const result = await api.locale.updateLocale(data);

      if (result.success) {
        const resultLocales = await api.locale.getLocales(zips);
        dispatch({ type: SET_LOCALES, locales: resultLocales.data });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!silent) dispatch({ type: HIDE_LOADING });
    }
  };
}

export function saveDeliveryScheduleOnboarding(deliverySchedule) {
  return function (dispatch, getState) {
    dispatch({ type: SET_DELIVERY_SCHEDULE, deliverySchedule });
  };
}

export function setLocales(locales) {
  return function (dispatch, getState) {
    dispatch({ type: SET_LOCALES, locales })
  }
}

export function saveOffersOnboarding(offers) {
  return function (dispatch, getState) {
    dispatch({ type: SET_OFFERS, offers });
  };
}

export function savePartnerOnboarding(partner) {
  return function (dispatch, getState) {
    dispatch({ type: SET_PARTNER, partner });
  };
}

export function saveZipCodesOnboarding(zipCodes) {
  return function (dispatch, getState) {
    dispatch({ type: SET_ZIP_CODES, zipCodes });
  };
}

export function saveWaitingUser(email, zip) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      const result = await api.locale.saveWaitingUser(email, zip);

      if (result.success) {
        dispatch({
          type: SHOW_MESSAGE,
          title: i18nstring(strings.SUCCESS),
          message: i18nstring(strings.WAITING_USER_SAVED)
        });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

// authorize the user via email/password provider,
// then create the user account
export function signUpUser(email, password, userObj) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      const newUser = transforms.fillInMockUser(userObj);

      const authResult = await api.account.signUpUser(email, password);

      if (authResult.success) {
        const auth = transforms.authFromAuthResult(authResult.data);
        newUser.addAuth(auth);

        if (!newUser.subscriber) {
          const userResult = await api.account.createUserAccount(newUser);

          if (userResult.success) {
            const user = userResult.data;

            dispatch({ type: UPDATE_USER, user });
          } else {
            dispatch({ type: SHOW_ERROR, message: userResult.error });
          }
        } else {
          const { deliverySchedule, zipCodes, offers, partner } =
            getState().onboarding;

          if (partner) newUser.partner = partner;

          const userResult = await api.account.createUserAccountAndSubscribe(
            newUser,
            zipCodes,
            deliverySchedule,
            offers
          );

          if (userResult.success) {
            // const { user, subscriptions } = userResult.data;
            // dispatch({ type: UPDATE_SUBSCRIPTIONS, subscriptions });
            // dispatch({ type: UPDATE_USER, user });
          } else {
            dispatch({ type: SHOW_ERROR, message: userResult.error });
          }
        }
      } else {
        dispatch({ type: SHOW_ERROR, message: authResult.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function loginFacebook() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SHOW_LOADING });

      const authResult = await api.facebook.loginUser();

      if (authResult.success) {
        const userResult = await api.account.getUserAccount();

        if (userResult.success) {
          const user = userResult.data;
          await initialize(user)(dispatch, getState);

          dispatch({ type: UPDATE_USER, user });
        } else {
          // authenticated, but no user profile exists; continue to onboarding
          if (userResult.code === ErrorCodes.userNotFound) {
            const auth = transforms.authFromAuthResult(authResult.data);
            dispatch({ type: UPDATE_USER_AUTH, userAuth: auth });
          } else {
            dispatch({ type: SHOW_ERROR, message: userResult.error });
          }
        }
      } else {
        if (authResult.code === ErrorCodes.signInCancelled) {
          // user cancelled, do nothing
        } else {
          dispatch({ type: SHOW_ERROR, message: authResult.error });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function loginGoogle() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SHOW_LOADING });

      const authResult = await api.google.loginUser();

      if (authResult.success) {
        const userResult = await api.account.getUserAccount();

        if (userResult.success) {
          const user = userResult.data;
          await initialize(user)(dispatch, getState);

          dispatch({ type: UPDATE_USER, user });
        } else {
          // authenticated, but no user profile exists; continue to onboarding
          if (userResult.code === ErrorCodes.userNotFound) {
            const auth = transforms.authFromAuthResult(authResult.data);
            dispatch({ type: UPDATE_USER_AUTH, userAuth: auth });
          } else {
            dispatch({ type: SHOW_ERROR, message: userResult.error });
          }
        }
      } else {
        if (authResult.code === ErrorCodes.signInCancelled) {
          // user cancelled, do nothing
        } else {
          dispatch({ type: SHOW_ERROR, message: authResult.error });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function loginTwitter() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SHOW_LOADING });

      const authResult = await api.twitter.loginUser();

      if (authResult.success) {
        const userResult = await api.account.getUserAccount();

        if (userResult.success) {
          const user = userResult.data;
          await initialize(user)(dispatch, getState);

          dispatch({ type: UPDATE_USER, user });
        } else {
          // authenticated, but no user profile exists; continue to onboarding
          if (userResult.code === ErrorCodes.userNotFound) {
            const auth = transforms.authFromAuthResult(authResult.data);
            dispatch({ type: UPDATE_USER_AUTH, userAuth: auth });
          } else {
            dispatch({ type: SHOW_ERROR, message: userResult.error });
          }
        }
      } else {
        if (authResult.code === ErrorCodes.signInCancelled) {
          // user cancelled, do nothing
        } else {
          dispatch({ type: SHOW_ERROR, message: authResult.error });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

// the user is already authorized via a provider
export function createAccount(user) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      user = Object.stripped(user);

      if (!user.subscriber) {
        const userResult = await api.account.createUserAccount(user);

        if (userResult.success) {
          const user = userResult.data;

          dispatch({ type: UPDATE_USER, user });
        } else {
          dispatch({ type: SHOW_ERROR, message: userResult.error });
        }
      } else {
        const { deliverySchedule, zipCodes } = getState().onboarding;

        const userResult = await api.account.createUserAccountAndSubscribe(
          user,
          zipCodes,
          deliverySchedule
        );

        if (userResult.success) {
          const { user, subscriptions } = userResult.data;

          dispatch({ type: UPDATE_SUBSCRIPTIONS, subscriptions });
          dispatch({ type: UPDATE_USER, user });
        } else {
          dispatch({ type: SHOW_ERROR, message: userResult.error });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
