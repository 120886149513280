import { BaseApi } from "./Base";

class SurveyApi extends BaseApi {
  getSurveyResponses() {
    return super.getApiRequest("/surveys");
  }
  answerSurvey(response) {
    return super.postApiRequest("/surveys", { response });
  }
}

const surveyApi = new SurveyApi();

export default surveyApi;
