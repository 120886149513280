import Immutable from "seamless-immutable";
import {
  HIDE_LOADING,
  SHOW_ERROR,
  SHOW_LOADING,
  UPDATE_ITEMS,
  SHOW_MESSAGE
} from "./Types";
import api from "../../api";

import { ATTENTION } from "../../constants/Strings";
import {
  ItemAction,
  ItemState,
  ItemRejectedReason
} from "../../Shared/Constants/Item";
import { addItem } from "../../transforms/ItemTransforms";

export function claimJob(id) {
  return async function (dispatch, getState) {
    try {
      if (!id) return;

      dispatch({ type: SHOW_LOADING });

      const result = await api.item.updateItem(id, ItemAction.CLAIM);

      if (result.success) {
        let claimedItem = result.data;
        let { items } = getState().marketplace;
        let itemIdx = items.findIndex(item => item.id === id);
        if (itemIdx === -1) {
          dispatch({ type: SHOW_ERROR, message: "Could not find job." });
          return;
        }

        items = Immutable.asMutable(items);
        items.splice(itemIdx, 1);

        items = addItem(items, claimedItem);

        dispatch({ type: UPDATE_ITEMS, items });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function markAsPickedUp(id, weight, photo) {
  return async function (dispatch, getState) {
    try {
      if (!id) return;

      dispatch({ type: SHOW_LOADING });

      // attempt to upload the photo
      let photoURL = null;
      if (photo) {
        photo.name = "courier_photo";
        const photoResult = await api.item.uploadItemPhoto(id, photo);
        if (photoResult.success) {
          photoURL = photoResult.data.url;
        } else {
          dispatch({ type: SHOW_ERROR, message: photoResult.error });
          throw new Error(photoResult.error.toString());
        }
      }

      let data = { weight, photoURL };
      const result = await api.item.updateItem(id, ItemAction.PICK_UP, data);

      if (result.success) {
        let pickedUpJob = result.data;
        let { items } = getState().marketplace;

        let itemIdx = items.findIndex(item => item.id === id);
        if (itemIdx === -1) {
          dispatch({ type: SHOW_ERROR, message: "Could not find job." });
          return;
        }

        items = Immutable.asMutable(items);

        if (pickedUpJob.state === ItemState.REJECTED) {
          let rejection;

          switch (pickedUpJob.rejectedReason) {
            case ItemRejectedReason.INCORRECT_WEIGHT:
              rejection = "the pick-up weight did not match the quoted weight";
              break;
            default:
              console.warn("unhandled pick up job rejection reason");
              break;
          }

          let message = `The item was rejected because ${rejection}.`;
          dispatch({ type: SHOW_MESSAGE, title: ATTENTION, message });

          items.splice(itemIdx, 1);
        } else {
          items.splice(itemIdx, 1, pickedUpJob);
        }

        dispatch({ type: UPDATE_ITEMS, items });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function markAsDelivered(id) {
  return async function (dispatch, getState) {
    try {
      if (!id) return;

      dispatch({ type: SHOW_LOADING });

      const result = await api.item.updateItem(id, ItemAction.DELIVER);

      if (result.success) {
        // let deliveredItem = result.data;
        let { items } = getState().marketplace;

        let itemIdx = items.findIndex(item => item.id === id);
        if (itemIdx === -1) {
          dispatch({ type: SHOW_ERROR, message: "Could not find job." });
          return;
        }

        items = Immutable.asMutable(items);
        items.splice(itemIdx, 1);

        dispatch({ type: UPDATE_ITEMS, items });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
