import React, { useState } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";
// Constants
import i18nstring, { strings } from "../../i18n";
// Theme
import "./MoleculeStyles.scss";
import { FormField } from "../atoms/FormFields";

const csuPassword = "CSUgrowgive";

export function PartnerContent(props) {
  const [partnerChecked, setPartnerChecked] = useState(false);
  const [partnerPassword, setPartnerPassword] = useState("");

  const togglePartnerChecked = () => setPartnerChecked(!partnerChecked);

  const setPartner = () => {
    props.attemptSetPartner({ partner: "CSU", partnerChecked });
  };

  const onChangePassword = e => {
    setPartnerPassword(e.target.value);
  };

  const passwordCorrect = partnerPassword === csuPassword;

  return (
    <div>
      <div className="partners_header">{i18nstring(strings.PARTNERS)}</div>
      <div className="partners_sub_header">
        {i18nstring(strings.PARTNERS_INFO)}
      </div>
      <div className="partners">
        <div className="partners_checkbox_label_container">
          <div className="partners_checkbox_label">
            {i18nstring(strings.PARTNERS_CSU)}
          </div>
          <Checkbox
            name={i18nstring(strings.PARTNERS_CSU)}
            checked={partnerChecked}
            onChange={togglePartnerChecked}
          />
        </div>
      </div>
      {partnerChecked && (
        <Form onSubmit={() => {}}>
          <FormField
            isValid={passwordCorrect}
            name="password"
            onChange={onChangePassword}
            placeholder={i18nstring(strings.PASSWORD)}
            validationMessage={i18nstring(strings.INCORRECT_PASSWORD)}
            value={partnerPassword}
            width={"30%"}
          />
        </Form>
      )}
      <Button
        disabled={partnerChecked && !passwordCorrect}
        type="submit"
        onClick={setPartner}
        floated="right"
      >
        {i18nstring(strings.NEXT)}
      </Button>
    </div>
  );
}
