import React from "react";
import { connect } from "react-redux";
// Actions
import { saveOffersOnboarding } from "../../../redux/actions/Onboarding";
// Components
import { OffersContent } from "../../molecules/OffersContent";
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";

function OffersForm(props) {
  const setOffers = offers => {
    props.attemptSetOffers(offers);
    props.handleNext();
  };
  return (
    <div className="modal_main">
      <div className="modal_header">{i18nstring(strings.ACCOUNT_CREATION)}</div>
      <div className="modal_content">
        <OffersContent attemptSetOffers={setOffers} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    attemptSetOffers: offers => dispatch(saveOffersOnboarding(offers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersForm);
