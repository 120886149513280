import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
// Components
import {
  FormField,
  FormSectionLabel,
  FormSectionLargeInput
} from "../atoms/FormFields";
// Constants
import i18nstring, { strings } from "../../i18n";
// Theme
import "./MoleculeStyles.scss";
// Utils
import { validatePhoneNumber } from "../../utils";

export function ContactInfoFormContent(props) {
  const [inputs, setInputs] = useState(props.inputs);
  const [validations, setValidation] = useState({
    city: true,
    phoneNumber: true,
    state: true,
    streetAddress1: true,
    zip: true
  });
  const checkValidation = () => {
    setValidation(() => ({
      city: inputs.city !== "",
      phoneNumber: validatePhoneNumber(inputs.phoneNumber),
      state: inputs.state !== "",
      streetAddress1: inputs.streetAddress1 !== "",
      zip: inputs.zip !== ""
    }));
    return (
      inputs.city !== "" &&
        validatePhoneNumber(inputs.phoneNumber) &&
        inputs.state !== "" &&
        inputs.streetAddress1 !== "",
      inputs.zip !== ""
    );
  };
  const handleSubmit = () => {
    if (checkValidation()) props.createAccount(inputs);
  };
  const handleInputChange = event => {
    event.persist();
    setValidation(validations => ({
      ...validations,
      [event.target.name]: true
    }));
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  const checkLocation = () => {
    const contact = {
      address: {
        city: inputs.city,
        state: inputs.state,
        streetAddress1: inputs.streetAddress1,
        zip: inputs.zip
      }
    };
    props.checkLocation(contact);
  };
  const addresResults = props.addressResults ? props.addressResults : [];
  return (
    <Form>
      <FormSectionLabel bold sectionLabel={i18nstring(strings.CONTACT_INFO)} />
      <FormSectionLabel sectionLabel={i18nstring(strings.CONTACT_INFO_NOTE)} />
      <FormField
        name="streetAddress1"
        placeholder={i18nstring(strings.STREET_ADDRESS_1)}
        onChange={handleInputChange}
        width={"90%"}
        maxChar={50}
        value={inputs.streetAddress1}
        isValid={validations.streetAddress1}
        validationMessage={i18nstring(strings.STREET_ADDRESS_INVALID)}
      />
      <div className="user_account_row">
        <FormField
          name="phoneNumber"
          placeholder={i18nstring(strings.PHONE_NUMBER)}
          onChange={handleInputChange}
          width={"80%"}
          maxChar={10}
          value={inputs.phoneNumber}
          isValid={validations.phoneNumber}
          validationMessage={i18nstring(strings.PHONE_NUMBER_INVALID)}
        />
        <FormField
          name="city"
          placeholder={i18nstring(strings.CITY)}
          onChange={handleInputChange}
          width={"80%"}
          maxChar={30}
          value={inputs.city}
          isValid={validations.city}
          validationMessage={i18nstring(strings.CITY_INVALID)}
        />
        <FormField
          name="state"
          placeholder={i18nstring(strings.STATE)}
          onChange={handleInputChange}
          width={"50%"}
          maxChar={2}
          value={inputs.state}
          isValid={validations.zip}
          validationMessage={i18nstring(strings.ZIP_INVALID)}
        />
        <FormField
          name="zip"
          placeholder={i18nstring(strings.ZIP_CODE)}
          onChange={handleInputChange}
          width={"60%"}
          maxChar={5}
          value={inputs.zip}
          isValid={validations.state}
          validationMessage={i18nstring(strings.STATE_INVALID)}
        />
      </div>
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.DELIVERY_INSTRUCTIONS)}
      />
      <FormSectionLabel
        sectionLabel={i18nstring(strings.DELIVERY_INSTRUCTIONS_NOTE)}
      />
      <FormSectionLargeInput
        name="instructions"
        placeholder={i18nstring(strings.DELIVERY_INSTRUCTIONS_GARDENER)}
        onChange={handleInputChange}
        width={"100%"}
        maxChar={180}
        value={inputs.instructions}
      />
      <AddressResultsButton
        propResults={addresResults}
        checkValue={checkLocation}
        followUpAction={handleSubmit}
      />
    </Form>
  );
}

function AddressResultsButton({ propResults, checkValue, followUpAction }) {
  const handleClick = () => {
    checkValue();
  };
  return propResults.length > 0 ? (
    <Button type="submit" onClick={followUpAction} floated="right">
      {i18nstring(strings.NEXT)}
    </Button>
  ) : (
    <Button type="submit" onClick={handleClick} floated="right">
      {i18nstring(strings.CONFIRM)}
    </Button>
  );
}
