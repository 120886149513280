import React, { useEffect, useState } from "react";
// Components
import { UserHeader, NavRoute, LinkNavRoute } from "../organisms/SideBar";
import CourierReview from "../pages/CourierReview";
import DataExport from "../pages/DataExport";
import DataExportAdmin from "../pages/DataExportAdmin";
import GardenerReview from "../pages/GardenerReview";
import NonProfitReview from "../pages/NonProfitReview";
import SettingsScreen from "../pages/Settings";
import SurveyScreen from "../pages/SurveyFeedback";
import FoodWeightReview from "../pages/FoodWeightReview";
import SelfService from "../pages/SelfService";
// Constants
import i18nstring, { strings } from "../../i18n";
import Routes from "../../constants/Routes";
// Themes
import "./TemplateStyles.scss";
import TimeWindows from "../pages/TimeWindows";
import Chats from "../pages/Chats";
import { collection, query, where, onSnapshot, getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import { useDispatch, useSelector } from "react-redux";
import { setBadge } from "../../redux/actions/BadgeActions";

export function DashWrapper({ user, isAdmin, impersonatedUser, nav, logOut, attemptRefreshChat, attemptRefreshChats }) {
  let navRoutes;
  let adminRoutes = false;
  const dispatch = useDispatch();

  if (
    nav === i18nstring(strings.SIDE_NON_PROFIT) &&
    isAdmin &&
    impersonatedUser !== null
  ) {
    navRoutes = () => <NonProfitRoutes nav={nav} />;
  } else {
    adminRoutes = isAdmin;
    navRoutes = () =>
      isAdmin ? <AdminRoutes nav={nav} /> : <NonProfitRoutes nav={nav} />;
  }

  useEffect(()=>{
    if(!adminRoutes){

    const db = getFirestore(firebase.app());
    const q = query(collection(db, "chats"), where("members", "array-contains-any" , [user?.id ||  impersonatedUser?.id]));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          if(change.oldIndex === 0 && change.newIndex === 0){
            if(nav !== i18nstring(strings.SIDE_CHATS)){
              dispatch(setBadge(
                "CHAT",
                true,
                1
              ))
            }
            attemptRefreshChat(change.doc.id)
          }
        }
      });
    });
    return ()=>
      unsubscribe()
    
  }
  },[])
  return (
    <div className="full_page_layout" style={impersonatedUser !== null ? {minHeight:"85.5vh"} : {}}>
      <div className="side_bar">
        <UserHeader user={user} logOut={logOut} />
        {navRoutes()}
      </div>
      <div className="main_container">
        <div className="main_container_header">{nav}</div>
        <CurrentDash sideBarNav={nav} isAdmin={isAdmin} />
      </div>
    </div>
  );
}

function NonProfitRoutes({ nav }) {
  const chatsBadge = useSelector((state) => state.badge.chat);
  return (
    <div>
      <NavRoute
        to={Routes.DATA_EXPORT}
        name={i18nstring(strings.SIDE_DATA_EXPORT)}
        isActive={nav === i18nstring(strings.SIDE_DATA_EXPORT)}
      />
      <NavRoute
        to={Routes.FOOD_WEIGHT_REVIEW}
        name={i18nstring(strings.SIDE_FOOD_WEIGHT_REVIEW)}
        isActive={nav === i18nstring(strings.SIDE_FOOD_WEIGHT_REVIEW)}
      />
      <NavRoute
        to={Routes.CHATS}
        name={i18nstring(strings.SIDE_CHATS)}
        chatsBadge={chatsBadge}
        isActive={nav === i18nstring(strings.SIDE_CHATS)}
      />
      <NavRoute
        to={Routes.COURIER_REVIEW}
        name={i18nstring(strings.COURIER_REVIEW)}
        isActive={nav === i18nstring(strings.SIDE_COURIER)}
      />
      <NavRoute
        to={Routes.GARDENER_REVIEW}
        name={i18nstring(strings.GARDENER_REVIEW)}
        isActive={nav === i18nstring(strings.SIDE_GARDENER)}
      />
      <NavRoute
        to={Routes.TIME_WINDOWS}
        name={i18nstring(strings.TIME_WINDOWS)}
        isActive={nav === i18nstring(strings.TIME_WINDOWS)}
      />
      <LinkNavRoute
        to={"https://freshfoodconnect.org/nonprofits-2021/"}
        name={i18nstring(strings.SIDE_HELP)}
        newTab
      />
      <LinkNavRoute
        to={
          "https://docs.google.com/forms/d/e/1FAIpQLSc8Z_ByFO4R5HzNxlLno7Brg5Vl9c5WcZweqHXV6h9-UOzNjw/viewform?usp=sf_link"
        }
        name={i18nstring(strings.SIDE_REPORT_BUG)}
        newTab
      />
      <LinkNavRoute
        to={
          "https://docs.google.com/forms/d/e/1FAIpQLSf62ywPZ6CsM9ARhPU7b0op0hZz_Re5soICqi0lXJIku60NJQ/viewform?usp=sf_link"
        }
        name={i18nstring(strings.SIDE_REQUEST_FEATURE)}
        newTab
      />
      <NavRoute
        to={Routes.SETTINGS}
        name={i18nstring(strings.SETTINGS)}
        isActive={nav === i18nstring(strings.SETTINGS)}
      />
    </div>
  );
}

function AdminRoutes({ nav }) {
  return (
    <div>
      <NavRoute
        to={Routes.DATA_EXPORT}
        name={i18nstring(strings.DATA_EXPORT)}
        isActive={nav === i18nstring(strings.SIDE_DATA_EXPORT)}
      />
      <NavRoute
        to={Routes.NON_PROFIT_REVIEW}
        name={i18nstring(strings.NON_PROFIT_REVIEW)}
        isActive={nav === i18nstring(strings.SIDE_NON_PROFIT)}
      />
      <NavRoute
        to={Routes.SETTINGS}
        name={i18nstring(strings.SETTINGS)}
        isActive={nav === i18nstring(strings.SIDE_SETTINGS)}
      />
      <NavRoute
        to={Routes.SURVEY}
        name={i18nstring(strings.SURVEY)}
        isActive={nav === i18nstring(strings.SURVEY)}
      />
    </div>
  );
}

function CurrentDash({ sideBarNav, isAdmin }) {
  const [currentNavigation, setCurrentNavigation] = useState(
    i18nstring(strings.SIDE_COURIER)
  );
  if (sideBarNav !== currentNavigation) {
    setCurrentNavigation(sideBarNav);
  }

  if (isAdmin) {
    switch (sideBarNav) {
      case i18nstring(strings.SIDE_COURIER):
        return <CourierReview />;
      case i18nstring(strings.SIDE_GARDENER):
        return <GardenerReview />;
      case i18nstring(strings.SIDE_NON_PROFIT):
        return <NonProfitReview />;
      case i18nstring(strings.SIDE_DATA_EXPORT):
        return <DataExportAdmin />;
      case i18nstring(strings.SIDE_TIME_WINDOWS):
        return <TimeWindows />;
      case i18nstring(strings.SIDE_SETTINGS):
        return <SettingsScreen />;
      case i18nstring(strings.SIDE_SURVEY):
        return <SurveyScreen />;
      default:
        return <NonProfitReview />;
    }
  } else {
    switch (sideBarNav) {
      case i18nstring(strings.SIDE_SELF_SERVICE):
        return <SelfService />;
      case i18nstring(strings.SIDE_FOOD_WEIGHT_REVIEW):
        return <FoodWeightReview />;
      case i18nstring(strings.SIDE_CHATS):
        return <Chats />;
      case i18nstring(strings.SIDE_COURIER):
        return <CourierReview />;
      case i18nstring(strings.SIDE_GARDENER):
        return <GardenerReview />;
      case i18nstring(strings.SIDE_DATA_EXPORT):
        return <DataExport />;
      case i18nstring(strings.SIDE_TIME_WINDOWS):
        return <TimeWindows />;
      case i18nstring(strings.SIDE_SETTINGS):
        return <SettingsScreen />;
      default:
        return <CourierReview />;
    }
  }
}
