import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// Actions
import { clearError, login, resetPassword } from "../../redux/actions/Account";
import {
  signUpUser,
  loginFacebook,
  loginGoogle
} from "../../redux/actions/Onboarding";
// Components
import { LoginForm } from "../organisms/forms/Login";
import { SignupForm } from "../organisms/forms/Account";
import { Button } from "../atoms/Button";
import { BackgroundImage } from "../atoms/Images";
// Constants
import Routes from "../../constants/Routes";
import i18nstring, {
  setCurrentLocale,
  strings,
  SupportedLocales
} from "../../i18n";

import { setLocales } from "../../redux/actions/Onboarding"

function Home(props) {
  if (props.user) return <Redirect to={Routes.DASHBOARD} />;

  const login = credentials => {
    const { email, password } = credentials;
    props.attemptLogin(email, password);
  };

  const createAccount = credentials => {
    props.attemptCreateAccount(credentials);
  };

  const routeBack = () => {
    window.location.href = "http://www.freshfoodconnect.org";
  };

  return (
    <nav className="nav_bar_container">
      <div className="home_layout">
        <div className="top_row_container">
          <Button onClick={routeBack}>{i18nstring(strings.GO_BACK)}</Button>
          <div className="header">{i18nstring(strings.WELCOME_TO_FFC)}</div>
          <div>
            <div style={{ marginBottom: 10 }}>
              <Button onClick={() => setCurrentLocale(SupportedLocales.EN_US)}>
                {i18nstring(strings.ENGLISH)}
              </Button>
            </div>
            <div style={{ marginBottom: 15 }}>
              <Button onClick={() => setCurrentLocale(SupportedLocales.ES_US)}>
                {i18nstring(strings.SPANISH)}
              </Button>
            </div>
          </div>
          <div className="top_row_button" />
        </div>
        <BackgroundImage>
          <div className="buttons_container">
            <SignupForm
              addressResults={props.addressResults}
              isLoading={props.isLoading}
              label={i18nstring(strings.SIGN_UP)}
              createAccount={createAccount}
              zipResults={props.zipResults}
              clearZipResults={props.attemptClearZips}
            />
            <LoginForm
              isLoading={props.isLoading}
              errorMessage={props.errorMessage}
              clearError={props.attemptClearError}
              login={login}
              label={i18nstring(strings.LOGIN)}
              loginFacebook={() => props.attemptFacebook()}
              resetPassword={props.attemptResetPassword}
            />
          </div>
        </BackgroundImage>
      </div>
    </nav>
  );
}

const mapStateToProps = state => ({
  addressResults: state.onboarding.addressResults,
  isLoading: state.onboarding.isLoading,
  zipResults: state.onboarding.locales,
  deliverySchedule: state.onboarding.deliverySchedule,
  errorMessage: state.account.errorMessage
});

const mapDispatchToProps = dispatch => {
  return {
    attemptClearZips: () => dispatch(setLocales([])),
    attemptFacebook: () => dispatch(loginFacebook()),
    attemptGoogle: () => dispatch(loginGoogle()),
    attemptCreateAccount: user =>
      dispatch(signUpUser(user.email, user.password, user)),
    attemptLogin: (email, password) => dispatch(login(email, password)),
    attemptClearError: () => dispatch(clearError()),
    attemptResetPassword: email => dispatch(resetPassword(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
