import {
  UPDATE_SUBSCRIPTIONS,
  SET_LOCALE,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ERROR
} from "./Types";
import api from "../../api";
import { getLocaleInfo } from "./Account";

import {
  mergeSubscription,
  mergeSubscriptions
} from "../../transforms/Subscription";

export function subscribe(zipCodes) {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.subscription.subscribe(zipCodes);

      if (result.success) {
        let { subscriptions } = getState().subscription;

        let newSubscriptions = result.data.subscriptions;
        subscriptions = mergeSubscriptions(subscriptions, newSubscriptions);

        dispatch({ type: UPDATE_SUBSCRIPTIONS, subscriptions });

        await getLocaleInfo(zipCodes[0], true, false)(dispatch, getState);
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function getSubscriptions() {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const { id } = getState().account.user;

      const result = await api.subscription.getSubscriptions(id);
      if (result.success) {
        let subscriptions = result.data;

        dispatch({ type: UPDATE_SUBSCRIPTIONS, subscriptions });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function updateSubscription(deliverySchedule) {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.subscription.updateSubscription(
        deliverySchedule
      );

      if (result.success) {
        let subscriptionLocales = result.data;
        // if (!subscriptionLocales.length) {
        //   dispatch({
        //     type: SHOW_ERROR,
        //     message: "Invalid data returned from updateSubscription."
        //   });
        //   throw new Error("Invalid data returned from updateSubscription.");
        // }

        let { locale } = getState().account;
        const newDeliverySchedule = subscriptionLocales[0].deliverySchedule;
        // const newLocales = [];
        // Object.values(locales).forEach(locale => {
        //   const updatedLocale = {
        //     ...locale,
        //     deliverySchedule: newDeliverySchedule
        //   };
        //   newLocales.push(updatedLocale);
        // });
        if (locale !== null) {
          dispatch({
            type: SET_LOCALE,
            locale: {
              ...locale,
              deliverySchedule: newDeliverySchedule
            }
          });
        }
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function unsubscribe(id) {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      const result = await api.subscription.unsubscribe(id);

      if (result.success) {
        let { subscriptions } = getState().subscription;
        let canceled = result.data;

        subscriptions = mergeSubscription(subscriptions, canceled);

        dispatch({ type: UPDATE_SUBSCRIPTIONS, subscriptions });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function getUserSubscriptions(userId) {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });
      // const { id } = getState().account.user;
      const id = userId ? userId: getState().account.user;

      const result = await api.subscription.getUserSubscriptions(id);
      if (result.success) {
        let subscriptions = result.data;

        dispatch({ type: UPDATE_SUBSCRIPTIONS, subscriptions });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  }
}

