import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global-styles.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
