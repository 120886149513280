import React from "react";
import { Link } from "react-router-dom";

// Themes
import "./MoleculeStyles.scss";

export function NavBarLargeSection(props) {
  const { label, to } = props;
  return (
    <Link className="nav_section_large" to={to}>
      {label}
    </Link>
  );
}

export function NavBarLink(props) {
  const { label, to } = props;
  return (
    <Link className="nav_section_link" to={to}>
      {label}
    </Link>
  );
}

export function NavLabel(props) {
  const { label, onClick } = props;
  return (
    <div className="nav_section_link" onClick={onClick}>
      {label}
    </div>
  );
}
