import { compose, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./reducers";
import dataAppJSON from "../app.json";

import monitorReducersEnhancer from "../enhancers/MonitorReducer";
const config = dataAppJSON.persistence;
const version =  dataAppJSON.version;
// import loggerMiddleware from '../middleware/Logger'

// https://redux.js.org/recipes/configuring-your-store#the-solution-configurestore
export function configureStore(preloadedState) {
  // any redux dispatch middlewares
  const middlewares = [
    // Todo re-add logger if we want it
    // loggerMiddleware,
    thunkMiddleware
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  // any redux store enhancers
  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  if (
    process.env.NODE_ENV !== "prod" &&
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
  var composedEnhancers = compose(...enhancers);

  // redux persistence
  const persistConfig = {
    ...config,
    key: "root",
    storage
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // finally, create the store
  const store = createStore(
    persistedReducer,
    preloadedState,
    composedEnhancers
  );
  const persistor = persistStore(store);

  // persistor.purge();

  const localVersion = window.localStorage.getItem("buildVersion");
  console.log("State initialized with version: ", version);
  if (localVersion === null || localVersion !== version) {
    console.log("Purging local storage");
    persistor.purge();
    window.localStorage.removeItem("impersonate");
    window.localStorage.setItem("buildVersion", version);
  }

  return { store, persistor };
}
