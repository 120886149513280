import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
// Components
import {
  DropDownSection,
  FormField,
  FormSectionLabel
} from "../atoms/FormFields";
// Constants
import i18nstring, { strings } from "../../i18n";
// Theme
import "./MoleculeStyles.scss";
// Utils
import { validateEIN } from "../../utils";

export function TaxAccountFormContent(props) {
  const [inputs, setInputs] = useState(props.inputs);
  const [validations, setValidation] = useState({
    ein: true,
    subRole: true
  });
  const checkValidation = () => {
    setValidation(() => ({
      ein: validateEIN(inputs.ein),
      subRole: typeof inputs.subRole !== "undefined"
    }));
    return validateEIN(inputs.ein) && typeof inputs.subRole !== "undefined";
  };
  const handleSubmit = () => {
    if (checkValidation()) props.handleNext(inputs);
  };
  const handleInputChange = event => {
    event.persist();
    setValidation(validations => ({
      ...validations,
      [event.target.name]: true
    }));
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  const handleDropDownChange = (name, value) => {
    setValidation(validations => ({
      ...validations,
      [name]: true
    }));
    setInputs(inputs => ({
      ...inputs,
      [name]: value
    }));
  };
  return (
    <Form>
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.TAX_IDENTIFIER)}
      />
      <FormSectionLabel sectionLabel={i18nstring(strings.EIN_NOTE)} />
      <FormField
        isValid={validations.ein}
        name="ein"
        onChange={handleInputChange}
        placeholder={i18nstring(strings.EIN)}
        validationMessage={i18nstring(strings.EIN_INVALID)}
        value={inputs.ein}
        width={"30%"}
      />
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.NON_PROFIT_SECTION)}
      />
      <FormSectionLabel sectionLabel={i18nstring(strings.NON_PROFIT_NOTE)} />
      <DropDownSection
        isValid={validations.subRole}
        name="subRole"
        onChange={handleDropDownChange}
        placeholder={i18nstring(strings.SUB_ROLES)}
        validationMessage={i18nstring(strings.SUB_GROUP_INVALID)}
        value={inputs.subRole}
        width={"30%"}
      />
      <Button type="submit" onClick={handleSubmit} floated="right">
        {i18nstring(strings.NEXT)}
      </Button>
    </Form>
  );
}
