import React from "react";
// Theme
import "./AtomStyles.scss";

export function Button(props) {
  const buttonStyle = {
    backgroundColor: props.color ? props.color : "#5db99d"
  };
  return (
    <div
      className={`button_container ${props.className}`}
      style={props.color ? buttonStyle : null}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <div className="button">{props.children}</div>
    </div>
  );
}

export function LargeButton(props) {
  const buttonStyle = {
    backgroundColor: props.color ? props.color : "#5db99d"
  };
  const handleClick = props.onClick;
  return (
    <div
      className="large_button_container"
      style={props.color ? buttonStyle : null}
      onClick={handleClick}
    >
      <div className="large_button">{props.children}</div>
    </div>
  );
}
