import {
  IMPERSONATE_USER,
  LOGOUT,
  SET_AUTH_REFRESH,
  SET_LOCALE,
  SET_LOCALES,
  SHOW_ERROR,
  STOP_IMPERSONATE_USER,
  UPDATE_ROUTES,
  UPDATE_USER
} from "../actions/Types";
import Immutable from "seamless-immutable";

// initial state
const initialState = Immutable({
  locales: null,
  locale: null,
  refreshingAuth: false,

  user: null,
  errorMessage: null,
  impersonatedUser: null,
  updateRoutes: false
});

const didUpdateRoutes = (state, action) => {
  return Immutable.merge(state, {
    updateRoutes: false
  });
};

const setImpersonatedUser = (state, action) => {
  const { impersonatedUser } = action;
  return Immutable.merge(state, {
    impersonatedUser: state.user,
    user: impersonatedUser,
    updateRoutes: true
  });
};

const setErrorMessage = (state, action) => {
  const { message } = action;
  return Immutable.merge(state, {
    errorMessage: message
  });
};

const setAuthRefresh = (state, action) => {
  const { refreshing } = action;
  return Immutable.merge(state, {
    refreshingAuth: refreshing
  });
};

const setLocales = (state, action) => {
  const { locales } = action;
  return Immutable.merge(state, {
    locales,
    locale: locales[0]
  });
};

const setLocale = (state, action) => {
  const { locale } = action;
  return Immutable.merge(state, {
    locale
  });
};

const updateUser = (state, action) => {
  const { user } = action;
  return Immutable.merge(state, { user });
};

const stopImpersonation = (state, action) => {
  console.log(`stop impersonating user`);
  localStorage.removeItem("impersonate");

  return Immutable.merge(state, {
    errorMessage: null,
    impersonatedUser: null,
    locale: null,
    locales: null,
    refreshingAuth: false,
    updateRoutes: true,
    user: state.impersonatedUser
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ROUTES:
      return didUpdateRoutes(state, action);
    case STOP_IMPERSONATE_USER:
      return stopImpersonation(state, action);
    case IMPERSONATE_USER:
      return setImpersonatedUser(state, action);
    case SHOW_ERROR:
      return setErrorMessage(state, action);
    case SET_AUTH_REFRESH:
      return setAuthRefresh(state, action);
    case SET_LOCALE:
      return setLocale(state, action);
    case SET_LOCALES:
      return setLocales(state, action);
    case UPDATE_USER:
      return updateUser(state, action);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
