import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import ChatListItem from "../../components/molecules/ChatListItem";

// Redux
import {
  getChats,
  refreshChats,
  clearCurrentChat,
  getUserChat,
  getCurrentChat
} from "../../redux/actions/ChatActions";
import { clearBadges } from "../../redux/actions/BadgeActions";
import ChatRoom from "../organisms/ChatRoom";

class ChatListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      chatItems: [],
      selectedChat: null
    };
  }

  componentDidMount() {
    const { chats } = this.props;
    if (chats) {
      const newItems = this.getChatItems();
      this.setState({
        chatItems: newItems
      });
    }
    this.clearBadge();
    this.props.attemptGetChats();
  }

  _keyExtractor = (item, index) => item.id;

  componentDidUpdate(prevProps) {
    const { chats } = this.props;

    if (chats !== prevProps.chats) {
      const newItems = this.getChatItems();
      this.setState({
        chatItems: newItems,
        refreshing: false
      });
    }
  }

  getChatItems = () => {
    const { user, chats } = this.props;
    let items = [];

    if (chats) {
      chats.forEach(chat => {
        let name, avatar;
        if (chat.lastSender !== user.id) {
          name = chat.userNames[chat.lastSender];
          avatar = chat.photos[chat.lastSender];
        } else {
          const other = chat.members.find(id => id !== user.id);
          name = chat.userNames[other];
          avatar = chat.photos[other];
        }

        let chatItem = {
          id: chat.id,
          date: chat.date,
          preview: chat.lastMessage,
          name,
          avatar
        };
        items.push(chatItem);
      });
    }

    return items;
  };

  clearBadge = () => {
    if (this.props.chatBadge > 0) {
      this.props.clearChatBadge();
    }
  };

  goToChat = item => {
    this.props.attemptClearCurrentChat();
    this.handleSelectChat(item);
  };

  renderChatItem = (item, index) => {
    return (
      <ChatListItem
        item={item}
        index={index}
        activeChat={this.state.selectedChat}
        selected={this.goToChat}
      />
    );
  };

  _keyExtractor = item => item.id;

  refreshList = () => {
    this.setState({ refreshing: true });
    this.props.attemptRefreshChats();
  };

  loadMoreChats() {
    // TODO
    // this.props.attemptGetChats()
  }

  handleSelectChat = chat => {
    this.setState({ selectedChat: chat });
    const chatId = chat.id;
    const userId = this.props.user.id;
    if (chatId) {
      this.props.attemptGetChat(chatId, true);
    } else if (userId) {
      this.props.attemptGetUserChat(userId);
    }
    this.clearBadge();
    // Fetch messages for the selected chat from the server or any other source
  };

  handleSendMessage = message => {};

  clearBadge = () => {
    if (this.props.chatBadge > 0) {
      this.props.clearChatBadge();
    }
  };

  render() {
    const { refreshing, chatItems, selectedChat } = this.state;
    console.log("chatItems", chatItems);
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          maxHeight: this.props?.impersonatedUser !== null ? "77.5vh" : "84vh"
        }}
        className="chat_container"
      >
        <div
          style={{
            width: 310,
            overflowY: "scroll",
            paddingTop: 10,
            backgroundColor: "#fff",
            borderRight: "1px solid rgba(0,0,0,0.2)"
          }}
        >
          {chatItems.length > 0 && chatItems.map(this.renderChatItem)}
        </div>
        {selectedChat && (
          <ChatRoom
            selectedChat={selectedChat}
            messages={[]}
            onSendMessage={this.handleSendMessage}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.account.user,
    chats: state.chat.chats,
    chatBadge: state.badge.chat,
    impersonatedUser: state.account.impersonatedUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearChatBadge: () => dispatch(clearBadges("CHAT")),
    attemptGetChats: refresh => dispatch(getChats()),
    attemptRefreshChats: refresh => dispatch(refreshChats()),
    attemptClearCurrentChat: refresh => dispatch(clearCurrentChat()),
    attemptGetUserChat: userId => dispatch(getUserChat(userId)),
    attemptGetChat: (id, silent) => dispatch(getCurrentChat(id, silent))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatListScreen);
