import {
  UPDATE_CHATS,
  SET_CHAT,
  CLEAR_CHAT,
  CHAT_BUSY,
  LOGOUT,
  CHAT_FREE,
  RESET_CHAT,
  IMPERSONATE_USER,
  STOP_IMPERSONATE_USER
} from "../actions/Types";
import Immutable from "seamless-immutable";

// initial state

const initialState = Immutable({
  chats: [],
  cursor: {
    count: 0,
    before: null,
    after: null
  },
  current: null,
  busy: false
});

// reducers

const updateChats = (state, action) => {
  let { chats, cursor } = action;
  cursor = cursor || state.cursor;
  return Immutable.merge({...state, chats: []}, {
    chats,
    cursor
  });
};

const setCurrentChat = (state, action) => {
  const { current } = action;
  return Immutable.merge(state, {
    current
  });
};

const setBusy = (state, action) => {
  const { busy } = action;
  return Immutable.merge(state, {
    busy
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHATS:
      return updateChats(state, action);
    case SET_CHAT:
      return setCurrentChat(state, action);
    case IMPERSONATE_USER:
    case STOP_IMPERSONATE_USER:
    case RESET_CHAT:
      return initialState;
    case CLEAR_CHAT:
      return setCurrentChat(state, { current: null });
    case CHAT_BUSY:
      return setBusy(state, { busy: true });
    case CHAT_FREE:
      return setBusy(state, { busy: false });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
