import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
// Components
import { FormField, FormSectionLabel } from "../atoms/FormFields";
// Constants
import i18nstring, { strings } from "../../i18n";
// Theme
import "./MoleculeStyles.scss";
// Utils
import { confirmPassword, validateEmail, validatePassword } from "../../utils";

export function UserAccountFormContent(props) {
  const [inputs, setInputs] = useState({
    ...props.inputs,
    password: "",
    passwordConfirm: ""
  });
  const [validations, setValidation] = useState({
    email: true,
    password: true,
    passwordConfirm: true,
    userName: true
  });
  const checkValidation = () => {
    setValidation(() => ({
      email: validateEmail(inputs.email),
      password: validatePassword(inputs.password),
      passwordConfirm: confirmPassword(inputs.password, inputs.passwordConfirm),
      userName: inputs.userName.length > 4
    }));
    return (
      confirmPassword(inputs.password, inputs.passwordConfirm) &&
      inputs.userName.length > 3 &&
      validateEmail(inputs.email) &&
      validatePassword(inputs.password)
    );
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (checkValidation()) props.handleNext(inputs);
  };
  const handleInputChange = event => {
    event.persist();
    setValidation(validations => ({
      ...validations,
      [event.target.name]: true
    }));
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormSectionLabel bold sectionLabel={i18nstring(strings.USER_ACCOUNT)} />
      <FormField
        isValid={validations.email}
        name="email"
        onChange={handleInputChange}
        placeholder={i18nstring(strings.EMAIL)}
        type="email"
        validationMessage={i18nstring(strings.EMAIL_INVALID)}
        value={inputs.email}
        width={"60%"}
      />
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.NON_PROFIT_NAME_TITLE)}
      />
      <FormSectionLabel
        sectionLabel={i18nstring(strings.NON_PROFIT_NAME_INFO)}
      />
      <FormSectionLabel
        sectionLabel={i18nstring(strings.NON_PROFIT_NAME_INFO_EX)}
      />
      <FormField
        isValid={validations.userName}
        name="userName"
        onChange={handleInputChange}
        placeholder={i18nstring(strings.USERNAME)}
        type="text"
        validationMessage={i18nstring(strings.USERNAME_INVALID)}
        value={inputs.userName}
        width={"40%"}
      />
      <FormSectionLabel
        bold
        sectionLabel={i18nstring(strings.PASSWORD_SECTION)}
      />
      <FormSectionLabel sectionLabel={i18nstring(strings.PASSWORD_NOTE)} />
      <FormField
        isValid={validations.password}
        name="password"
        onChange={handleInputChange}
        placeholder={i18nstring(strings.PASSWORD)}
        type="password"
        validationMessage={i18nstring(strings.PASSWORD_INVALID)}
        value={inputs.password}
        width={"35%"}
      />
      <FormField
        isValid={validations.passwordConfirm}
        name="passwordConfirm"
        onChange={handleInputChange}
        placeholder={i18nstring(strings.PASSWORD_CONFIRMATION)}
        type="password"
        validationMessage={i18nstring(strings.PASSWORD_CONFIRMATION_INVALID)}
        value={inputs.passwordConfirm}
        width={"35%"}
      />
      <Button type="submit" floated="right">
        {i18nstring(strings.NEXT)}
      </Button>
    </Form>
  );
}
