import axios from "axios";
import ErrorCodes from "./ErrorCodes";
import * as strings from "../constants/Strings";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export class ApiResult {
  constructor(data, success, error, code) {
    this.data = data;
    this.success = success;
    this.error = error;
    this.code = code;
  }
}

export class ApiError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

const NetworkError = new ApiResult(
  null,
  false,
  strings.NETWORK_ERROR,
  ErrorCodes.networkError
);

export class BaseApi {
  async callAuth(name, task) {
    console.log(`[API] begin auth '${name}'`);
    const start = Date.now();

    var result;

    try {
      const authResult = await task();
      result = new ApiResult(authResult, true);
    } catch (e) {
      console.warn("Caught error:", { message: e.message, code: e.code });
      const message = this.messageFromAuthError(e);
      result = new ApiResult(null, false, message, e.code);
    }

    const elapsed = Date.now() - start;
    console.log(`[API] finished auth '${name}' in`, elapsed, "MS");

    return result;
  }

  async refreshToken() {
    if (!firebase.auth().currentUser) {
      return;
    }

    var currentUser;

    try {
      const tokenResult = await firebase.auth().currentUser.getIdTokenResult();
      const token = tokenResult.token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      // TODO add back for use of auth token
      // console.log("[API] auth token refreshed:", token);
      currentUser = firebase.auth().currentUser;
    } catch (e) {
      console.warn("Caught error while trying to refresh token:", e.message);
    }

    return currentUser;
  }

  clearToken() {
    axios.defaults.headers.common["Authorization"] = null;
  }

  getApiRequest(url, params) {
    return this.sendApiRequest(url, "GET", null, params);
  }

  postApiRequest(url, data) {
    return this.sendApiRequest(url, "POST", data);
  }

  putApiRequest(url, data) {
    return this.sendApiRequest(url, "PUT", data);
  }

  deleteApiRequest(url, data) {
    return this.sendApiRequest(url, "DELETE", data);
  }

  async sendApiRequest(url, method, data, params, retry) {
    if (retry) {
      console.log("[API]\tretrying request after token refresh");
    } else {
      console.log(
        `[API] begin apiRequest ${method} '${url}'; data: `,
        data,
        "; params: ",
        params
      );
    }
    const start = Date.now();

    var result;
    var status;

    try {
      if (typeof url !== "string" || typeof method !== "string") {
        throw new Error(`Invalid params passed to callFunction: '${method}'`);
      }

      // add impersonate param, if applicable
      var impersonate = window.localStorage.getItem("impersonate");
      if (impersonate !== null) {
        console.log(`[API]\timpersonating user ${impersonate}`);
        if (typeof params === "undefined") params = {};
        params.impersonate = impersonate;
      }

      var requestData = { url, method, data, params };

      const response = await axios.request(requestData);
      status = response.status;

      if (response.data.error) {
        const { message, code } = response.data.error;
        throw new ApiError(message, code);
      }

      result = new ApiResult(response.data.data, true);
    } catch (e) {
      // an axios error
      if (e.response) {
        console.warn("Caught axios error:", e.response.data);
        status = e.response.status;
        const error = e.response.data.error || {};
        // token needs refreshing, and haven't already tried refreshing
        if (this.tokenRefreshError(error) && !retry) {
          await this.refreshToken();
          return this.sendApiRequest(url, method, data, params, true);
        }
        const message = this.messageFromApiError(error);
        result = new ApiResult(
          e.response.data.data,
          false,
          message,
          error.code
        );
      } else {
        console.warn("Caught error:", e.message);
        if (e.message === "Network Error") {
          result = NetworkError;
        } else {
          const message = this.messageFromApiError(e);
          result = new ApiResult(null, false, message);
        }
      }
    }

    const elapsed = Date.now() - start;
    console.log(
      `[API] finished apiRequest ${method} '${url}' in`,
      elapsed,
      "MS with status",
      status,
      "; result data: ",
      JSON.parse(JSON.stringify(result.data || {})) // make a copy so the console shows the snapshot, not the latest
    );

    return result;
  }

  tokenRefreshError(e) {
    return (
      typeof e.message === "string" &&
      e.message.includes("Firebase ID token has expired")
    );
  }

  messageFromApiError(e) {
    var message = e.message || strings.GENERIC_ERROR;
    if (e.code) {
      switch (e.code) {
        case ErrorCodes.networkError:
          message = strings.NETWORK_ERROR;
          break;
        case ErrorCodes.userNotFound:
          // a user has authenticated, but hasn't created a db user account
          break;
        case "auth/invalid-user-token":
          // an api request was made without attaching a bearer token
          message = strings.MISSING_TOKEN;
          break;
        default:
          message = strings.UNDEFINED;
          break;
      }
    }
    return message;
  }

  messageFromAuthError(e) {
    var message = e.message || strings.GENERIC_ERROR;
    if (e.code) {
      switch (e.code) {
        case "auth/email-already-in-use":
          message = strings.EMAIL_ALREADY_USED;
          break;
        case "auth/user-not-found":
          message = strings.USER_NOT_FOUND;
          break;
        case "auth/incorrect-email":
          message = strings.INCORRECT_EMAIL_PASSWORD;
          break;
        case "auth/wrong-password":
          message = strings.INCORRECT_PASSWORD;
          break;
        case "auth/network-request-failed":
          message = strings.NETWORK_ERROR;
          break;
        case "auth/account-exists-with-different-credential":
          message = strings.EXISTS_ON_OTHER;
          break;
        default:
          break;
      }
    }
    return message;
  }
}
