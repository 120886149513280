import Immutable from 'seamless-immutable'
import {
  UPDATE_CHATS,
  UPDATE_MESSAGES,
  SET_CHAT,
  CLEAR_CHAT,
  SHOW_ERROR,
  SHOW_LOADING,
  HIDE_LOADING,
  CHAT_BUSY,
  CHAT_FREE,
  RESET_CHAT
} from "./Types"
import api from '../../api'
import * as transforms from "../../transforms/ChatTransforms"

// max number of chats or messages loaded at a time
const pageSize = 50
// keep the messages of this many chats cached
const messagesMapCap = 5

export function createChat(text, other) {
  return async function(dispatch, getState) {
    try {
      let { busy } = getState().chat
      if (busy) return

      dispatch({ type: CHAT_BUSY })

      const result = await api.chat.createChat({ text, other })

      if (result.success) {
        let { chats } = getState().chat
        let { messages } = getState().message

        let chat = result.data
        let message = chat.message
        delete chat.message

        chats = transforms.mergeChat(chats, chat)
        messages = transforms.mergeMessages(messages, [message], chat.id)

        dispatch({ type: UPDATE_CHATS, chats })
        dispatch({ type: UPDATE_MESSAGES, messages })
        dispatch({ type: SET_CHAT, current: chat.id })
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error })
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({ type: CHAT_FREE })
    }
  }
}

export function sendMessage(text, chatId) {
  return async function(dispatch, getState) {
    try {
      let { busy } = getState().chat
      if (busy) return

      dispatch({ type: CHAT_BUSY })

      const result = await api.chat.sendMessage(chatId, { text })

      if (result.success) {
        let { chats } = getState().chat
        let { messages } = getState().message

        let chat = result.data
        let message = chat.message
        delete chat.message

        chats = transforms.mergeChat(chats, chat)
        messages = transforms.mergeMessages(messages, [message], chatId)

        dispatch({ type: UPDATE_CHATS, chats })
        dispatch({ type: UPDATE_MESSAGES, messages })
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error })
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({ type: CHAT_FREE })
    }
  }
}

export function getCurrentChat(id, silent) {
  return async function(dispatch, getState) {
    try {
      if (!silent) dispatch({ type: SHOW_LOADING })

      const { chats } = getState().chat
      const { messages } = getState().message

      // messages are already cached; show and continue with refresh
      const currentChat = chats.find(chat => chat.id === id)
      const chatMessages = messages[id]
      let chatSet = false
      if (currentChat && chatMessages) {
        dispatch({ type: SET_CHAT, current: id })
        chatSet = true
      }

      const success = await refreshChat(id)(dispatch, getState)

      if (!chatSet) dispatch({ type: SET_CHAT, current: id })
    } catch (e) {
      console.error(e)
    } finally {
      if (!silent) dispatch({ type: HIDE_LOADING })
    }
  }
}

export function getUserChat(userId) {
  return async function(dispatch, getState) {
    try {
      let { chats } = getState().chat
      let { messages, cursors } = getState().message

      // chat with user already cached
      let userChat = chats.find(chat => chat.members.includes(userId))
      if (userChat) {
        await getCurrentChat(userChat.id, true)(dispatch, getState)
        return
      }

      dispatch({ type: SHOW_LOADING })

      let cursor = {
        limit: pageSize
      }

      const result = await api.chat.getUserChat(userId, cursor)

      if (result.success) {
        let newChat = result.data
        let newChatMessages = newChat.messages
        let newChatCursor = newChat.cursor
        delete newChat.messages
        delete newChat.cursor

        let id = newChat.id

        chats = transforms.mergeChat(chats, newChat)
        messages = transforms.mergeMessages(messages, newChatMessages, id)
        cursors = transforms.mergeCursors(cursors, newChatCursor, id)

        // TODO trim messages map
        // const numKeys = Object.keys(mergedMessages).length
        // if (numKeys > messagesMapCap) {
        //   mergedMessages = transforms.trimMessagesMap(mergedMessages, id)
        // }

        dispatch({ type: SET_CHAT, current: id })
        dispatch({ type: UPDATE_CHATS, chats })
        dispatch({ type: UPDATE_MESSAGES, messages, cursors })
      } else {
        if (result.code === 'api/user-chat-not-found') {
          // starting new chat with user
        } else {
          dispatch({ type: SHOW_ERROR, message: result.error })
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({ type: HIDE_LOADING })
    }
  }
}

// silently fetch and updates chats and messages
export function refreshChat(id) {
  return async function(dispatch, getState) {
    try {
      let { chats, busy } = getState().chat
      let { messages, cursors } = getState().message

      if (busy) return
      dispatch({ type: CHAT_BUSY })

      let thisChat = chats.find(chat => chat.id === id)
      let thisChatMessages = messages[id]
      let thisChatCursor = cursors[id]

      let cursor = {}

      // already cached, let's just refresh
      if (thisChat && thisChatMessages && thisChatCursor) {
        cursor = {
          before: thisChatCursor.before
        }
      } else {
        cursor = {
          limit: pageSize
        }
      }

      const result = await api.chat.getChat(id, cursor)

      if (result.success) {
        let newChat = result.data
        let newChatMessages = newChat.messages
        let newChatCursor = newChat.cursor
        delete newChat.messages
        delete newChat.cursor

        chats = transforms.mergeChat(chats, newChat)
        messages = transforms.mergeMessages(messages, newChatMessages, id)
        cursors = transforms.mergeCursors(cursors, newChatCursor, id)

        // TODO trim messages map
        // const numKeys = Object.keys(mergedMessages).length
        // if (numKeys > messagesMapCap) {
        //   mergedMessages = transforms.trimMessagesMap(mergedMessages, id)
        // }

        dispatch({ type: UPDATE_CHATS, chats })
        dispatch({ type: UPDATE_MESSAGES, messages, cursors })
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error })
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({ type: CHAT_FREE })
    }
  }
}

export function clearCurrentChat() {
  return function(dispatch, getState) {
    // TODO trim all cached messages to the pageSize

    dispatch({ type: CLEAR_CHAT })
  }
}

export function getMessages(chatId) {
  return async function(dispatch, getState) {
    try {
      let { messages, cursors } = getState().message
      let chatMessages = messages[chatId]
      let chatCursor = cursors[chatId]

      let outCursor = {
        limit: pageSize,
        acter: chatCursor.after
      }

      const result = await api.chat.getMessages(chatId, outCursor)

      if (result.success) {
        chatMessages = [...chatMessages, ...result.data.messages]
        chatCursor = Immutable.merge(chatCursor, result.data.cursor)

        messages = transforms.mergeMessages(messages, chatMessages, chatId)
        cursors = transforms.mergeCursors(cursors, chatCursor, chatId)

        dispatch({ type: UPDATE_MESSAGES, messages, cursors })
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error })
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export function getChats() {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING })

      let { chats, cursor } = getState().chat
      const outCursor = {
        limit: pageSize,
        before: null,
        after: null
      }

      const result = await api.chat.getChats(outCursor)

      if (result.success) {
        chats = transforms.appendChats([], result.data.chats)
        cursor = Immutable.merge(cursor, result.data.cursor)

        dispatch({ type: UPDATE_CHATS, chats, cursor })
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error })
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({ type: HIDE_LOADING })
    }
  }
}

export function refreshChats() {
  return async function(dispatch, getState) {
    try {
      let { chats, cursor } = getState().chat
      const outCursor = {
        before: cursor.before
      }

      const result = await api.chat.getChats(outCursor)

      if (!result.success) {
        dispatch({ type: SHOW_ERROR, message: result.error })
        return
      }

      chats = transforms.prependNewChats(result.data.chats, chats)
      cursor = Immutable.merge(cursor, result.data.cursor)

      dispatch({ type: UPDATE_CHATS, chats, cursor })
    } catch (e) {
      console.error(e)
    }
  }
}
