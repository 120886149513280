import React, { useState } from "react";
import Select from "react-select";


export default function SelectSearchInput({ users,setNewOperator }) {
  const [selectedOptions, setSelectedOptions] = useState();


  const options = users.map((user) =>
    ({ label: `${user.name}`, value: `${user.name}`, uid:`${user.id}` })
  );

  function handleSelect(data) {
    setSelectedOptions(data);
    setNewOperator(data.uid);
  }

  



  return (
    <div className="zipTransferInput">

      <div className="dropdown-container">
        <Select
          options={options}
          placeholder="Select organization"
          value={selectedOptions}
          onChange={handleSelect}
          isSearchable={true}
        />
        <div className="resetValuePopup" onClick={() => { setSelectedOptions(''); setNewOperator('')} }>
       &times;
        </div>
      </div>
    </div>
  );
}
