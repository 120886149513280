import React, { Component } from "react";
import { connect } from "react-redux";
// Redux
import {
  createChat,
  getUserChat,
  getCurrentChat,
  sendMessage,
  clearCurrentChat,
  getMessages,
  refreshChat
  // refreshMessages
} from "../../redux/actions/ChatActions";
import { clearBadges } from "../../redux/actions/BadgeActions";
import AppIcon from "../../images/appIcon.png";
import { Message } from "../molecules/Message";
import { asMutable } from "seamless-immutable";
import SendGrey from "../../images/icons/sendGrey.png";
import SendPrimary from "../../images/icons/sendPrimary.png";

export class MessagesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceInset: "always",
      refreshing: false,
      messages: [],
      chat: {},
      inputMessage: ""
    };
  }

  componentDidMount() {
    const { navigation, chats, route, chatId, userId } = this.props;

    // NOTE: possibly deep linked here
    // org.freshfoodconnect.market://app/role/chat/messages/:chatId
  }

  componentDidUpdate(prevProps) {
    const { chats, chatBusy, currentId, messagesMap } = this.props;
    if (!currentId) return;

    if (
      messagesMap !== prevProps.messagesMap ||
      currentId !== prevProps.currentId
    ) {
      let currentChat = chats.find(chat => chat.id === currentId);
      let chatMessages = messagesMap[currentId];

      if (currentChat && chatMessages) {
        this.setState({
          refreshing: false,
          chat: this.transformChat(currentChat),
          messages: asMutable(chatMessages, { deep: true })
        });
        return;
      }
    } else if (
      messagesMap === prevProps.messagesMap &&
      this.state.messages?.length === 0
    ) {
      let currentChat = chats.find(chat => chat.id === currentId);
      let chatMessages = messagesMap[currentId];

      if (currentChat && chatMessages) {
        this.setState({
          refreshing: false,
          chat: this.transformChat(currentChat),
          messages: asMutable(chatMessages, { deep: true })
        });
        return;
      }
    }

    if (this.state.refreshing && !chatBusy && prevProps.chatBusy) {
      this.setState({
        refreshing: false
      });
    }
  }

  transformChat = chat => {
    const { user } = this.props;

    let name, avatar;
    if (chat.lastSender !== user.id) {
      name = chat.userNames[chat.lastSender];
      avatar = chat.photos[chat.lastSender];
    } else {
      const other = chat.members.find(id => id !== user.id);
      name = chat.userNames[other];
      avatar = chat.photos[other];
    }

    return {
      name: name.split(" ")[0],
      avatar
    };
  };

  renderMessage = (item, index) => {
    return <Message item={item} />;
  };

  _keyExtractor = item => item.id;

  refreshList = () => {
    const { chatBusy, currentId } = this.props;
    if (chatBusy || !currentId) return;

    this.setState({ refreshing: true });
    this.props.attemptRefreshMessages(currentId);
  };

  loadMoreMessages() {
    if (!this.props) return;

    const { chatBusy, currentId } = this.props;
    if (chatBusy || !currentId) return;

    // this.props.attemptGetMessages(currentId)
  }

  sendMessage = () => {
    let { chatBusy } = this.props;
    if (chatBusy) return;

    let { inputMessage, messages } = this.state;

    // is null or whitespace
    if (inputMessage === null || inputMessage.match(/^\s*$/) !== null) {
      return;
    }

    inputMessage = inputMessage.trim();

    if (this.props.currentId) {
      this.props.attemptSendMessage(inputMessage, this.props.currentId);
    } else {
      const userId = this.props.route.params?.userId;
      if (!userId) return;

      this.props.attemptCreateChat(inputMessage, userId);
    }

    const tempId = `${Date.now()}`;
    messages.unshift({ id: tempId, sender: "self", text: inputMessage });

    this.setState({
      inputMessage: "",
      messages
    });
  };

  onChangeText = e => {
    this.setState({ inputMessage: e.target.value });
  };

  goBack = () => {
    this.props.attemptClearCurrentChat();
  };

  render() {
    const { chat, forceInset, messages, refreshing } = this.state;
    let { avatar, name, preview, date } = this.props.selectedChat;
    const invertFlatList = messages && messages.length > 0;
    const flexGrowStyle = { flexGrow: 1 };
    const forceInsetStyle = { bottom: forceInset };
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column"
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "12px 10px",
            alignItems: "center",
            cursor: "pointer"
          }}
          onClick={this.pressed}
        >
          <img
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              objectFit: "contain"
            }}
            src={avatar ? avatar : AppIcon}
          />
          <div
            style={{
              margin: "0px 15px",
              flex: 1,
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            <div
              style={{
                fontSize: 20,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {name}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column-reverse",
            overflowY: "scroll"
          }}
        >
          {messages?.map(this.renderMessage)}
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 10,
            display: "flex",
            alignItems: "center",
            boxShadow: "4px 1px 2px 3px #d2d2d2"
          }}
        >
          <textarea
            style={{
              border: "none",
              minHeight: 40,
              maxHeight: 100,
              fontSize: 16,
              display: "flex",
              flex: 1
            }}
            type="text"
            rows={4}
            placeholder="Type your message..."
            value={this.state.inputMessage}
            onChange={this.onChangeText}
          />
          <div
            style={{ margin: "0px 10px", cursor: "pointer" }}
            onClick={this.sendMessage}
          >
            <img
              src={
                this.state.inputMessage === null ||
                this.state.inputMessage.match(/^\s*$/) !== null
                  ? SendGrey
                  : SendPrimary
              }
              style={{
                width: 30,
                height: 30
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.account.user,
    chats: state.chat.chats,
    chatBadge: state.badge.chat,
    chatBusy: state.chat.busy,
    currentId: state.chat.current,
    messagesMap: state.message.messages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearChatBadge: () => dispatch(clearBadges("CHAT")),
    attemptCreateChat: (text, userId) => dispatch(createChat(text, userId)),
    attemptSendMessage: (text, id) => dispatch(sendMessage(text, id)),
    attemptGetMessages: id => dispatch(getMessages(id)),
    attemptRefreshMessages: id => dispatch(refreshChat(id)),
    attemptClearCurrentChat: () => dispatch(clearCurrentChat())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesScreen);
