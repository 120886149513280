import React from "react";
// Components
import { Dropdown } from "semantic-ui-react";
// Constants
import { nonProfitSubGroups } from "../../model/User";
// Theme
import "./AtomStyles.scss";

export function FormField({
  isDisabled,
  isValid,
  maxChar,
  name,
  onChange,
  placeholder,
  type,
  validationMessage,
  value,
  width
}) {
  const formStyle = {
    borderColor: isValid ? "#5db99d" : "red",
    marginTop: 9,
    backgroundColor: isDisabled ? "#edebe9" : "white"
  };
  if (width) formStyle.width = width;

  return (
    <div className="modal_form_field">
      <label className="modal_form_field_label">{placeholder}</label>
      <input
        disabled={isDisabled}
        maxLength={maxChar}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        style={formStyle}
        type={type}
        value={value}
      />
      <ValidationMessage
        isValid={isValid}
        validationMessage={validationMessage}
      />
    </div>
  );
}

function ValidationMessage({ isValid, validationMessage }) {
  return isValid ? (
    <div className="form_field_validation_message"> </div>
  ) : (
    <div className="form_field_validation_message">{validationMessage}</div>
  );
}

export function FormSectionLabel({ bold, sectionLabel }) {
  const classString = bold ? "form_section_label_bold" : "form_section_label";
  return (
    <div className="form_section_container">
      <label className={classString}>{sectionLabel}</label>
    </div>
  );
}

export function EmailSubjectInput({
  maxChar,
  name,
  onChange,
  placeholder,
  type,
  value,
  width
}) {
  const formStyle = {
    borderColor: "#5db99d",
    height: 40,
    marginTop: 9,
    width: width ? width : 240.5
  };
  return (
    <div className="email_subject">
      <label className="modal_form_field_label">Subject</label>
      <input
        maxLength={maxChar}
        name={name}
        className="email_subject_input"
        onChange={onChange}
        placeholder={placeholder}
        style={formStyle}
        type={type}
        value={value}
      />
    </div>
  );
}

export function EmailBodyInput({
  maxChar,
  name,
  onChange,
  placeholder,
  value,
  width
}) {
  const textAreaStyle = {
    borderColor: "#5db99d",
    height: 120
  };
  return (
    <div className="email_body">
      <label className="modal_form_field_label">Body</label>
      <textarea
        style={textAreaStyle}
        className="email_body_input"
        maxLength={maxChar}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type="text"
      />
    </div>
  );
}

export function FormSectionLargeInput({
  maxChar,
  name,
  onChange,
  placeholder,
  value
}) {
  return (
    <div className="form_section_window">
      <textarea
        maxLength={maxChar}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type="text"
      />
    </div>
  );
}

export function DropDownSection({
  isValid,
  name,
  onChange,
  placeholder,
  validationMessage,
  value,
  width
}) {
  const dropDownStyle = {
    borderColor: isValid ? "#5db99d" : "red",
    marginTop: 9,
    width: width ? width : 240.5
  };
  return (
    <div className="modal_form_field">
      <Dropdown
        onChange={(evt, other) => onChange(name, other.value)}
        options={nonProfitSubGroups}
        placeholder={placeholder}
        selection
        style={dropDownStyle}
        value={value}
      />
      <ValidationMessage
        isValid={isValid}
        validationMessage={validationMessage}
      />
    </div>
  );
}

export function FormFieldNoLabel({
  isValid,
  maxChar,
  name,
  onChange,
  placeholder,
  type,
  value,
  width
}) {
  const formStyle = {
    borderColor: isValid ? "#5db99d" : "red",
    marginTop: 9,
    width: width ? width : 240.5
  };
  return (
    <div className="no_label_form_field">
      <input
        maxLength={maxChar}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        style={formStyle}
        type={type}
        value={value}
      />
    </div>
  );
}
