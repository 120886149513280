import React, { useState } from "react";
import { Modal } from "semantic-ui-react";

// Components
import ContactInfoAccountForm from "./ContactInfoAccount";
import UserAccountForm from "./UserAccount";
import ZipAccountForm from "./ZipAccount";
import TaxAccountForm from "./TaxAccount";
import DeliveryScheduleForm from "./DeliverySchedule";
import OffersForm from "./Offers";
import PartnerForm from "./Partner";
import SignUpConfirm from "./SignUpConfirm";
import { LargeButton } from "../../atoms/Button";
// Constants
import User from "../../../model/User";
// Theme
import ".././OrganismStyles.scss";

const modalStyle = {
  width: 680
};

export function SignupForm(props) {
  const [inputs, setInputs] = useState(new User());
  const [modalOpen, toggleModal] = useState(false);
  const [showWarning, toggleWarning] = useState(false);

  const { label } = props;

  const handleNext = newInputs => {
    toggleWarning(false);
    const updateInputs = {
      ...inputs,
      ...newInputs
    };
    setInputs(updateInputs);
  };
  const checkLocation = inputs => {
    props.checkLocation(inputs);
  };
  const checkZipCode = zip => {
    props.checkZipCode(zip);
  };
  const handleOpen = () => toggleModal(!modalOpen);
  const handleClose = () => {
    if (showWarning) {
      toggleModal(!modalOpen);
      props.clearZipResults([]);
      toggleWarning(false);
    } else {
      toggleWarning(true);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      style={modalStyle}
      trigger={<LargeButton onClick={handleOpen}>{label}</LargeButton>}
    >
      <CurrentForm
        checkZipCode={checkZipCode}
        checkLocation={checkLocation}
        inputs={inputs}
        createAccount={props.createAccount}
        handleNext={handleNext}
        addressResults={props.addressResults}
        handleClose={handleClose}
        zipResults={props.zipResults}
      />
      <ModalMessage
        showModal={showWarning}
        modalMessage="You will lose progress if you click out of this modal"
      />
    </Modal>
  );
}

function CurrentForm(props) {
  const [currentPage, setPage] = useState(1);

  const { inputs } = props;

  const handleNext = inputs => {
    setPage(currentPage + 1);
    props.handleNext(inputs);
  };
  const handleLocation = inputs => {
    props.checkLocation(inputs);
  };
  const createUser = () => {
    props.createAccount(inputs);
    setPage(currentPage + 1);
    props.handleClose();
  };

  switch (currentPage) {
    case 1:
      return <UserAccountForm handleNext={handleNext} inputs={inputs} />;
    case 2:
      return (
        <ZipAccountForm
          checkZipCode={props.checkZipCode}
          zipResults={props.zipResults}
          handleNext={handleNext}
          inputs={inputs}
        />
      );
    case 3:
      return <TaxAccountForm handleNext={handleNext} inputs={inputs} />;
    case 4:
      return <PartnerForm handleNext={handleNext} inputs={inputs} />;
    case 5:
      return (
        <ContactInfoAccountForm
          handleNext={handleNext}
          inputs={inputs}
          addressResults={props.addressResults}
          checkLocation={handleLocation}
        />
      );
    case 6:
      return <DeliveryScheduleForm handleNext={handleNext} inputs={inputs} />;
    case 7:
      return <OffersForm handleNext={handleNext} inputs={inputs} />;
    case 8:
      return <SignUpConfirm handleNext={() => setPage(currentPage + 1)} />;
    case 9:
      return createUser();
    default:
      return null;
  }
}

function ModalMessage({ showModal, modalMessage, type }) {
  if (!showModal) {
    return null;
  } else {
    return (
      <div className="modal_message">
        {modalMessage}
        {/* <Button color="red">Cancel</Button> */}
      </div>
    );
  }

  // No action taken yet so don't show a message
  //   if (zipResults.length === 0) {
  //     return null;
  //   } else {
  //     const supportedZips = zipResults.filter(zip => zip.supported);
  //     const unsupportedZips = zipResults.filter(
  //       zip => zip.supported === false || zip.subscriber
  //     );
  //     if (unsupportedZips.length > 0) {
  //       return <div className="modal_message_error">{strings.ERROR_ZIP}</div>;
  //     }
  //     if (supportedZips.length > 0 && unsupportedZips.length < 1) {
  //       return (
  //         <div className="modal_message_success">{strings.POSITIVE_ZIP}</div>
  //       );
  //     }
  //   }
  //   return <div className="modal_message_error">{strings.ERROR_ZIP}</div>;
  // }
}
