import React from "react";
import { connect } from "react-redux";
// Actions
// Components
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";

function SignUpConfirm(props) {
  return (
    <div className="modal_main">
      <div className="modal_header">{i18nstring(strings.ACCOUNT_CREATION)}</div>
      <div className="modal_content">
        <div className="modal_message_font">
          Thanks for signing up! You will be notified when you are approved.
        </div>
        <div className="modal_message_font">
          Please email info@freshfoodconnect.org if you have any questions
        </div>
      </div>
      <div className="modal_footer" onClick={props.handleNext}>
        <label className="nav_link_button">Done</label>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpConfirm);
