import User from "../model/User";
export function authFromAuthResult(user) {
  let providerId = user.providerId;
  if (user.providerData && user.providerData.length) {
    providerId = user.providerData[0].providerId || providerId;
  }
  let created = Date.now();
  if (user.metadata && user.metadata.creationTime) {
    created = user.metadata.creationTime;
  }

  return {
    id: user.uid,
    email: user.email,
    userName: user.displayName,
    photoURL: user.photoURL,
    providerId,
    created
  };
}

export function fillInMockUser(user) {
  const newUser = new User();
  const updatedContact = {
    ...user.contact,
    phoneNumber: user.phoneNumber
  };

  newUser.approvalStatus = user.approvalStatus;
  newUser.contact = updatedContact;
  newUser.created = user.created;
  newUser.ein = user.ein;
  newUser.email = user.email;
  newUser.providerId = user.providerId;
  newUser.roles = user.roles;
  newUser.subRoles = { RECIPIENT: user.subRole };
  newUser.subscriber = true;
  newUser.userName = user.userName;

  return newUser;
}
