import { UPDATE_MESSAGES, LOGOUT } from '../actions/Types'
import Immutable from 'seamless-immutable'

// initial state

const initialState = Immutable({
  messages: {
    // chatId: [],
  },
  cursors: {
    // chatId: { ... },
  }
})

// reducers

const updateMessages = (state, action) => {
  let { messages, cursors } = action
  cursors = cursors || state.cursors
  return Immutable.merge(state, {
    messages,
    cursors
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MESSAGES:
      return updateMessages(state, action)
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default reducer;
