import React, { useState } from "react";
import { connect } from "react-redux";
// Actions
import {
  requestNonProfitExport,
  requestAllItemsExport,
  requestAllUsersExport,
  requestWaitingUsersExport,
  requestNotSubscribedProducersExport
} from "../../redux/actions/Data";
// Components
import { Image, Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";
// Constants
import i18nstring, { strings } from "../../i18n";
// Images
import dataStack from "../../images/icons/dataStack.png";
import clipBoard from "../../images/icons/clipBoard.png";
import circleArrowRight from "../../images/icons/circleArrowRight.png";
// Themes
import "./PageStyles.scss";
import { Roles } from "../../Shared/Constants/Roles";

function FilterMap({ filters, setFilters, defaultFilters, labels }) {
  const defaultControls = defaultFilters.map((filter, index) => {
    return (
      <Form.Checkbox
        key={`default_${index}`}
        label={filter}
        checked={true}
        disabled
      />
    );
  });
  const controls = Object.keys(filters).map((filter, index) => {
    const setFilter = () => setFilters(filter);
    return (
      <Form.Checkbox
        key={`${index}`}
        label={labels[index]}
        checked={filters[filter]}
        onChange={setFilter}
      />
    );
  });
  return defaultControls.concat(controls);
}

const ExportType = {
  NON_PROFIT: "NON_PROFIT",
  ALL_ITEMS: "ALL_ITEMS",
  ALL_PRODUCERS: "ALL_PRODUCERS",
  ALL_COURIERS: "ALL_COURIERS",
  WAITING_USERS: "WAITING_USERS",
  NOT_SUBSCRIBED_PRODUCERS: "NOT_SUBSCRIBED_PRODUCERS"
};

function DataExportAdmin(props) {
  const [email, setEmail] = useState(props.user.email);
  const [dataType, setDataType] = useState(ExportType.NON_PROFIT);

  // region non profit export

  const [nonProfitFilters, setNonProfitFilters] = useState({
    pendingProducers: false,
    approvedProducers: false,
    pendingCouriers: false,
    approvedCouriers: false
  });

  const setNonProfitFilter = filter => {
    const filters = { ...nonProfitFilters };
    filters[filter] = !nonProfitFilters[filter];
    setNonProfitFilters(filters);
  };

  const sendNonProfitFilters = () => {
    const filters = {};
    Object.keys(nonProfitFilters).forEach(filter => {
      if (nonProfitFilters[filter]) {
        filters[filter] = 1;
      }
    });
    props.requestNonProfitExport(email, filters);
  };

  // end region non profit export

  // region all items export

  const [itemFilters, setItemFilters] = useState({
    name: false,
    category: false,
    weight: false,
    pickUpWeight: false,
    distance: false,
    dateDelivered: false,
    nonProfit: false,
    zip: false,
    gardenerName: false,
    gardenerEmail: false,
    gardenerZip: false,
    partner: false,
    itemPhoto: false
  });
  const start = new Date();
  const [startDate, setStartDate] = useState(
    new Date(start.setMonth(start.getMonth() - 2))
  );
  const [endDate, setEndDate] = useState(new Date());
  const setItemFilter = filter => {
    const filters = { ...itemFilters };
    filters[filter] = !itemFilters[filter];
    setItemFilters(filters);
  };
  const sendAllItemsFilters = () => {
    const filters = {};
    Object.keys(itemFilters).forEach(filter => {
      if (itemFilters[filter]) {
        filters[filter] = 1;
      }
    });
    props.requestAllItemsExport(
      email,
      startDate.getTime(),
      endDate.getTime(),
      filters
    );
  };

  // end region all items export

  // region all users export

  const [userFilters, setUserFilters] = useState({
    name: false,
    subRole: false,
    approved: false,
    active: false,
    zip: false
  });
  const setUserFilter = filter => {
    const filters = { ...userFilters };
    filters[filter] = !userFilters[filter];
    setUserFilters(filters);
  };
  const sendAllUsersFilters = () => {
    const filters = {};
    Object.keys(userFilters).forEach(filter => {
      if (userFilters[filter]) {
        filters[filter] = 1;
      }
    });
    const role =
      dataType === ExportType.ALL_PRODUCERS ? Roles.PRODUCER : Roles.COURIER;
    props.requestAllUsersExport(email, role, filters);
  };

  const [notSubscibedProducersFilters, setNotSubscibedProducersFilters] =
    useState({
      name: false,
      subRole: false,
      zip: false
    });

  const setNotSubscibedProducersFilter = filter => {
    const filters = { ...notSubscibedProducersFilters };
    filters[filter] = !notSubscibedProducersFilters[filter];
    setNotSubscibedProducersFilters(filters);
  };

  const sendNotSubscibedProducersFilters = () => {
    const filters = {};
    Object.keys(notSubscibedProducersFilters).forEach(filter => {
      if (notSubscibedProducersFilters[filter]) {
        filters[filter] = 1;
      }
    });
    props.requestNotSubscribedProducersExport(email, filters);
  };

  // end region all users export

  const requestExport = () => {
    switch (dataType) {
      case ExportType.NON_PROFIT:
        sendNonProfitFilters();
        break;
      case ExportType.ALL_ITEMS:
        sendAllItemsFilters();
        break;
      case ExportType.ALL_PRODUCERS:
      case ExportType.ALL_COURIERS:
        sendAllUsersFilters();
        break;
      case ExportType.NOT_SUBSCRIBED_PRODUCERS:
        sendNotSubscibedProducersFilters();
        break;
      default:
        props.requestWaitingUsersExport(email);
        break;
    }
  };

  let currentFilters = [],
    currentSetFilters = () => {},
    currentLabels = [],
    defaultFilters = [];
  switch (dataType) {
    case ExportType.NON_PROFIT:
      currentFilters = nonProfitFilters;
      currentSetFilters = setNonProfitFilter;
      currentLabels = i18nstring(strings.NON_PROFIT_FILTER_LABELS);
      defaultFilters = [];
      break;
    case ExportType.ALL_ITEMS:
      currentFilters = itemFilters;
      currentSetFilters = setItemFilter;
      currentLabels = i18nstring(strings.ALL_ITEMS_FILTER_LABELS);
      defaultFilters = [];
      break;
    case ExportType.ALL_PRODUCERS:
    case ExportType.ALL_COURIERS:
      currentFilters = userFilters;
      currentSetFilters = setUserFilter;
      currentLabels = i18nstring(strings.ALL_USERS_FILTER_LABELS);
      defaultFilters = i18nstring(strings.DEFAULT_ALL_USERS_FILTER_LABELS);
      break;
    case ExportType.NOT_SUBSCRIBED_PRODUCERS:
      currentFilters = notSubscibedProducersFilters;
      currentSetFilters = setNotSubscibedProducersFilter;
      currentLabels = i18nstring(strings.ALL_NOT_SUBSCRIBED_PRODUCERS_LABELS);
      defaultFilters = i18nstring(
        strings.DEFAULT_NOT_SUBSCRIBED_PRODUCERS_LABELS
      );
      break;
    default:
      defaultFilters = i18nstring(strings.DEFAULT_WAITING_USERS_LABELS);
      break;
  }

  return (
    <div className="main_container_section">
      <div
        className="instructions_button_container"
        style={{ marginBottom: "1.6rem" }}
      >
        <div>
          <p>{i18nstring(strings.NON_PROFIT_EXPORT_DESCRIPTION)}</p>
          <p>{i18nstring(strings.NON_PROFIT_EXPORT_WARNING)}</p>
        </div>
      </div>
      <Form onSubmit={requestExport}>
        <div className="step_container">
          <div className="step_row">
            <div className="step_label">1</div>
            <div className="step_row_container">
              <Image className="icon_image" src={dataStack} />
              <div className="step_description_container">
                <h3>{i18nstring(strings.DATA_TYPE)}</h3>
                <Form.Group grouped>
                  <Form.Radio
                    label="Nonprofits"
                    checked={dataType === ExportType.NON_PROFIT}
                    onChange={() => setDataType(ExportType.NON_PROFIT)}
                  />
                  <Form.Radio
                    label="Gardeners"
                    checked={dataType === ExportType.ALL_PRODUCERS}
                    onChange={() => setDataType(ExportType.ALL_PRODUCERS)}
                  />
                  <Form.Radio
                    label="Couriers"
                    checked={dataType === ExportType.ALL_COURIERS}
                    onChange={() => setDataType(ExportType.ALL_COURIERS)}
                  />
                  <Form.Radio
                    label="Waiting Users"
                    checked={dataType === ExportType.WAITING_USERS}
                    onChange={() => setDataType(ExportType.WAITING_USERS)}
                  />
                  <Form.Radio
                    label="Not Subscribed Gardeners"
                    checked={dataType === ExportType.NOT_SUBSCRIBED_PRODUCERS}
                    onChange={() =>
                      setDataType(ExportType.NOT_SUBSCRIBED_PRODUCERS)
                    }
                  />
                  <Form.Radio
                    label="Items"
                    checked={dataType === ExportType.ALL_ITEMS}
                    onChange={() => setDataType(ExportType.ALL_ITEMS)}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="step_container">
          <div className="step_row">
            <div className="step_label">2</div>
            <div className="step_row_container">
              <Image className="icon_image" src={clipBoard} />
              <div className="step_description_container">
                <h3>Columns to Export</h3>
                <Form.Group grouped>
                  <FilterMap
                    filters={currentFilters}
                    setFilters={currentSetFilters}
                    defaultFilters={defaultFilters}
                    labels={currentLabels}
                  />
                </Form.Group>
                {dataType === ExportType.ALL_ITEMS ? (
                  <div className="export_date_range_container">
                    Start Date
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                    />
                    End Date
                    <DatePicker
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="step_container">
          <div className="step_row">
            <div className="step_label">3</div>
            <div className="step_row_container">
              <Image className="icon_image" src={circleArrowRight} />
              <div className="step_description_container">
                <h3>Submit</h3>
                <Form.Group grouped>
                  <Form.Input
                    label={i18nstring(strings.WHICH_EMAIL_EXPORT)}
                    placeholder="your@email.com"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    error={email === ""}
                  />
                  <Form.Button
                    content="Submit"
                    className="export_form_submit_button"
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.account.user
});

const mapDispatchToProps = dispatch => {
  return {
    requestNonProfitExport: (email, filters) =>
      dispatch(requestNonProfitExport(email, filters)),
    requestAllItemsExport: (email, start, end, filters) =>
      dispatch(requestAllItemsExport(email, start, end, filters)),
    requestAllUsersExport: (email, role, filters) =>
      dispatch(requestAllUsersExport(email, role, filters)),
    requestWaitingUsersExport: email =>
      dispatch(requestWaitingUsersExport(email)),
    requestNotSubscribedProducersExport: (email, filters) =>
      dispatch(requestNotSubscribedProducersExport(email, filters))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataExportAdmin);
