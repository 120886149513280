import React, { useState } from "react";
import { connect } from "react-redux";
// Actions
import {
  getLocales,
  saveZipCodesOnboarding
} from "../../../redux/actions/Onboarding";
// Components
import { ZipAccountFormContent } from "../../molecules/ZipFormContent";
import { Icon } from "semantic-ui-react";
// Constants
import i18nstring, { strings } from "../../../i18n";
// Theme
import ".././OrganismStyles.scss";

function ZipAccountForm(props) {
  const [inputs] = useState({ ...props.inputs.contact, zip2: "" });

  const handleNext = zipCodes => {
    props.attemptSetZipCodes(zipCodes);
    props.handleNext();
  };

  const zipResults = props.zipCodes ? props.zipCodes : [];
  return (
    <div className="modal_main">
      <div className="modal_header">{i18nstring(strings.ACCOUNT_CREATION)}</div>
      <div className="modal_content">
        <ZipAccountFormContent
          checkZipCode={props.attemptGetLocaleInfo}
          zipResults={zipResults}
          handleNext={handleNext}
          inputs={inputs}
        />
      </div>
      <ModalMessage showLoading={props.isLoading} zipResults={zipResults} />
    </div>
  );
}

const mapStateToProps = state => ({
  zipCodes: state.onboarding.locales,
  isLoading: state.onboarding.isLoading
});

const mapDispatchToProps = dispatch => {
  return {
    attemptGetLocaleInfo: zips => dispatch(getLocales(zips)),
    attemptSetZipCodes: zipCodes => dispatch(saveZipCodesOnboarding(zipCodes))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZipAccountForm);

function ModalMessage({ zipResults, showLoading }) {
  const icon = showLoading ? <Icon loading name="spinner" /> : null;
  if (showLoading && zipResults.length === 0) {
    return (
      <div className="modal_message">
        {icon}
        {i18nstring(strings.WARNING_ZIP)}
      </div>
    );
  } else {
    // No action taken yet so don't show a message
    if (zipResults.length === 0) {
      return null;
    } else {
      const supportedZips = zipResults.filter(zip => zip.supported);
      const unsupportedZips = zipResults.filter(
        zip => zip.supported === false || zip.subscriber
      );
      if (unsupportedZips.length > 0) {
        return (
          <div className="modal_message_error">
            {i18nstring(strings.ERROR_ZIP)}
          </div>
        );
      }
      if (supportedZips.length > 0 && unsupportedZips.length < 1) {
        return (
          <div className="modal_message_success">
            {i18nstring(strings.POSITIVE_ZIP)}
          </div>
        );
      }
    }
    return (
      <div className="modal_message_error">{i18nstring(strings.ERROR_ZIP)}</div>
    );
  }
}
